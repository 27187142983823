

import CoffeeIcon from './coffee.png'
import DessertIcon from './dessert.png'
import FoodIcon from './food.png'
import WineIcon from './wine.png'
import ConnectivityImage from './connectivity.png'
import BrandingImage from './branding.png'

export const Assets = {
    coffee: CoffeeIcon,
    dessert: DessertIcon,
    food: FoodIcon,
    wine: WineIcon,
    specials: {
        connect: ConnectivityImage,
        branding: BrandingImage
    }   
}
