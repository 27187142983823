import { useState } from 'react'

import {
    Box, 
    Typography,
    Checkbox as MuiCheckbox,
    Switch as MuiSwitch
} from '@mui/material'
import * as React from 'react';
import MuiRadio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { RowTitle, ComponentRow, Sep } from './helper'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';

const Select = props => {
    const [age, setAge] = useState(27)
    const { variant } = props
    return <FormControl variant={variant} sx={{ m: 1, minWidth: 120, }}>
    <InputLabel id="demo-simple-select-standard-label" sx={{color: t => t.palette.text.main}}>Age</InputLabel>
    <MuiSelect
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={age}
      onChange={evt => setAge(evt.target.value)}
      label="Age"
      sx={{
        '&, & *': {color: t => t.palette.text.main},
        '.MuiSvgIcon-root': {
          color: t =>  t.palette.text.main, // Arrow color
        },
        
      }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </MuiSelect>
  </FormControl>
}



export const SelectRow = props => {
    
    return <>

        <ComponentRow withAccent={true}>
            <RowTitle>Selections</RowTitle>
            <Sep />
            <Select variant="standard" />
            <Sep />
            <Select variant="filled" />
            <Sep />
            <Select variant="outlined" />
        </ComponentRow>




    
    </>

}