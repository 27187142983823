import { Typography as MuiTypography, Box, alpha } from '@mui/material'


export const Typography = props => {
    const {children, sx:_sx={}, ...other} = props
    const sx = Object.assign({
        color: t => t.palette.text.main,
    }, _sx)
    return <MuiTypography sx={sx} {...other}>{children}</MuiTypography>
}


export const Header1 = props => {
    const {children, sx:_sx={}, ...other} = props
    const sx =  Object.assign({
        
    }, _sx)
    return <Typography variant="header" sx={sx} {...other}>{children}</Typography>
}
export const Subtext = props => {
    const {children, sx:_sx={}, ...other} = props
    const sx = Object.assign(t => ({
        opacity: .8,
        fontSize: '.8rem'
    }), _sx)
    return <Typography variant="body1" sx={sx} {...other}>{children}</Typography>
}

export const Body1 = props => {
    const { fromComp="nowhere", children, sx: _sx={}, ...other } = props
    const sx = Object.assign({
    }, _sx)

    return <MuiTypography sx={sx} {...other}>{children}</MuiTypography>
}




export const Text = {
    Typography,
    Header1,
    Subtext,
    Body1
}