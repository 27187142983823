import { Typography, Box, alpha } from '@mui/material'
// import { alpha } from '@mui/material/style'

export const ComponentRow = (props) => {
    return <Box sx={{
        display: 'flex', 
        alignItems: 'center', 
        gap: 2, 
        mb: 1,
        py: 1,
        mt: props.withAccent ? 10 : 0,
        position: 'relative',
        '&::before': {
            content: '""',
            height: '1px',
            width: '100%',
            background: props.withAccent ? 'rgba(0,0,0,.2)' : 'rgba(0,0,0,0)',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10
        }
    }}>
        {props.children}
    </Box>
}
export const RowTitle = props => {
    return <Typography sx={{fontWeight: 'bold', fontSize: '1.1rem', color: t => t.palette.text.main}}>{props.children}</Typography>
}

export const Sep = () => {
    return <Box sx={{
        width: 20, 
        height: '100%', 
        height: 20,
        position: 'relative',
        '&::before': {
            content: '""',
            height: '100%',
            position: 'absolute',
            width: '2px',
            borderRadius: 10,
            zIndex: 10,
            background: t => alpha(t.palette.text.main, .3),
            top: 0,
            left: 9
        }
    }}>
    </Box>
}