import BassImage from './bass.png'
import HairImage from './hair.png'
import MMImage from './megaman.png'
import ClubImage from './clubs.png'

import FrogImage from './frog.png'
import LotionImage from './lotion.png'
import MakeupImage from './makeup.png'
import MarkerImage from './marker.png'
import MedicineImage from './medicine.png'
import RazorImage from './razor.png'
import RingImage from './ring.png'
import PrizmImage from './prizm.png'
import LockImage from './lock.png'
import ToolImage from './tool.png'

export const Assets = {
    BassImage,
    HairImage,
    MMImage,
    ClubImage,
    FrogImage,
    LotionImage,
    MakeupImage,
    MarkerImage,
    MedicineImage,
    RazorImage,
    RingImage,
    PrizmImage,
    LockImage,
    ToolImage
}