import SmartToyIcon from '@mui/icons-material/SmartToy';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import InsightsIcon from '@mui/icons-material/Insights';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AddIcon from '@mui/icons-material/Add';
import SubtractIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import LandingPagesIcon from '@mui/icons-material/MenuBook';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import WebIcon from '@mui/icons-material/Web';
import OrderIcon from '@mui/icons-material/CreditCard';
export const Icons = {
    AutomationIcon: SmartToyIcon,
    IntegrationIcon: JoinInnerIcon,
    AnalysisIcon: InsightsIcon,
    VisualizationIcon: EqualizerIcon,
    CheckMarkIcon: CheckCircleIcon,

    BarChartIcon: BarChartIcon,
    ComponentsIcon: CollectionsBookmarkIcon,
    AddIcon: AddIcon,
    SubtractIcon: SubtractIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    CheckIcon,
    LandingPagesIcon,
    OrderIcon,
    WebIcon,
    AdminIcon

}