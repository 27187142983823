import { useState, useEffect, useRef } from 'react'
import { Components } from './../../../components'
import { Assets } from './assets'
// import { NumberedTitle, PriceText, Container, ServiceRow, Price } from './components'
const {
    FBox, 
    Box,
    Typography,
    Header1,
    Body1,
    Subtext,
    Icons,
    SplitContent,
    Grid,
    GhostTyping,
    Assets: BaseAssets,
    alpha
} = Components

const Title = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontSize: {xs: '1.3rem', sm: '1.8rem'},
        color: t => t.palette.text.opposite,
        fontWeight: 'bold',
        textAlign: 'center'
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}
const Text = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        color: t => t.palette.text.opposite,
        maxWidth: 600,
        fontSize: {xs: '.8rem', md: '1rem'}
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}


const ConnectivityImage = props => {
    return <FBox sx={{position: 'relative', width: '35%'}}>
        <Box component="img" src={Assets.specials.connect} alt="Integratation Service" sx={{width: '100%', height: 'auto', objectFit: 'contain'}}></Box>
    </FBox>
}
const BrandingImage = props => {
    return <FBox sx={{position: 'relative', width: '75%', p: 5}}>
        <Box component="img" src={Assets.specials.branding} alt="Branding Service" sx={{width: '100%', height: 'auto', objectFit: 'contain'}}></Box>
    </FBox>
}

const DynamicMenu = ({ speed = 100 }) => {
    const texts = [
      'Monday Special: Soup and Sandwich',
      'Tuesday Special: Wild-caught Salmon',
      'Wednesday: Blue cheese 16oz Porterhouse',
      'Thursday: Fish Tacos and Beer',
      'Friday: Hamburger Deluxe Meal'
    ];
    const [index, setIndex] = useState(0);
    
    useEffect(() => {

      const currentText = texts[index];
      const duration = currentText.length * speed + 2000; // Total time for typing + delay
  
      const timeout = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, duration);
  
      return () => clearTimeout(timeout);
    }, [index, texts, speed]);
    
    
    return (
        <FBox sx={{
            height: 100,
            minWidth: {xs: 325, md: 400},
            border: '10px solid transparent',
            borderImage: `url(${BaseAssets.images.borders.redBorder}) 30 stretch`,
        }} >
            {(index < texts.length && index >= 0) &&
                <GhostTyping text={texts[index]} speed={100} sx={{color: 'red', fontSize: {xs: '.8rem', sm: '1rem'}}}/>
            }
      </FBox>
    );
  };

const sections = [
    {   
        title: 'Consistent Branding for Your Restaurant',
        texts: [
            "Your restaurant’s website should be a seamless extension of your brand, offering customers a cohesive visual experience. We create a tailored branding strategy that reflects the unique identity of your establishment.",
            "Unlike competitors who rely on generic website builders or pre-made themes, we provide custom designs that align perfectly with your brand guidelines. Your website will be truly unique, featuring beautiful dynamic menus, engaging photo galleries, social media integration, and a fully personalized look."
        ],
        decoration: <BrandingImage />
    },
    {
        title: 'Dynamic Menu',
        texts: [
            "We bring your menu to life with an interactive, mobile-friendly design on your restaurant's website. No more static PDFs or plain text—our team creates an engaging menu with vibrant food images, SEO-friendly features, and ratings to enhance customer interest. Plus, we provide a QR code option, offering your guests a safe and contactless way to browse your menu."
        ],
        decoration: <DynamicMenu />
    },
    {
        title: 'Mobile-First Design',
        texts: [
            "A well-designed website does more than look good; it brings your restaurant directly to your online audience. We create a site that acts as a digital extension of your establishment, integrating seamlessly with social media to amplify your reach. From showcasing your menu and highlighting customer reviews to providing options for online orders and reservations, your website becomes a central hub where new and loyal customers connect with your brand.",
            "With a mobile-friendly approach, we ensure your website performs beautifully on any device, allowing guests to engage with your restaurant wherever they are. This online presence not only strengthens your connection with customers but also encourages more bookings, orders, and interactions without relying solely on social media ads or campaigns."
        ],
        decoration: <ConnectivityImage />
    }

]
const Section = props => {
    const { title, texts, decoration, sx: _sx={}, ...other } = props
    const sx = Object.assign({
        flexDirection: 'column',
        gap: 2,
        p: 3,
        borderBottom: t => `3px solid ${alpha(t.palette.text.opposite, .1)}`
    }, _sx)
    return <FBox sx={sx} {...other}>
        <FBox sx={{width: '100%', position: 'relative'}}>
            {decoration}
        </FBox>
        <Title>{title}</Title>
        <FBox sx={{
            flexDirection: 'column', 
            justifyContent: 'start', 
            alignItems: 'start',
            gap: 1,
            color: t => t.palette.text.opposite
        }}>
            { 
                texts.map(ti => <Text key={ti}>{ti}</Text>)
            }

        </FBox>
    </FBox>
}
const MenuSection = props => {
    const { title, texts, decoration, sx: _sx={}, ...other } = props
    const sx = Object.assign({
        flexDirection: 'column',
        gap: 2,
        p: 3,
        borderBottom: t => `3px solid ${alpha(t.palette.text.opposite, .1)}`
    }, _sx)
    return <FBox sx={sx} {...other}>
        <Title>{title}</Title>
        <FBox sx={{width: '100%', position: 'relative'}}>
            {decoration}
        </FBox>
        <FBox sx={{
            flexDirection: 'column', 
            justifyContent: 'start', 
            alignItems: 'start',
            gap: 1,
            color: t => t.palette.text.opposite
        }}>
            { 
                texts.map(ti => <Text key={ti}>{ti}</Text>)
            }

        </FBox>
    </FBox>
}


export const WebsitePackageDetails = props => {
    
    return <Grid container sx={{width: '100%', background: t => t.palette.background.specials, pt: 8, p: 1}}>
        <Grid item size={{xs: 12, md: 6}} sx={{mt: {xs: 10, sm: 20, md: 40}}}>
            <Section {...sections[0]}></Section>
        </Grid>
        <Grid item size={{xs: 12, md: 6}} sx={{mt: {xs: 10}}}>
            <MenuSection {...sections[1]}></MenuSection>
        </Grid>
        <Grid item size={{xs: 12, md: 6}} sx={{ml: 'auto', mt: {xs: 10, md: -20}}}>
            <Section {...sections[2]}></Section>
        </Grid>
    </Grid>



}