
const sitem = (name, price, descriptors, description) => ({name,price,descriptors,description})

const starters = [
    // sitem(name, price, descriptor, description) -- descriptor, description can be null
    sitem('SPRING ROLLS (20)', 35, ['veg']),
    sitem('CRAB RAGOONS (20)', 35),
    sitem('WINGS (20)', 30),
    sitem('PORK GYOZA (20)', 35, [], "Steamed or Fried"),
    sitem('SHRIMP SHUMAI (20)', 35, [], 'Steamed or Fried'),
    sitem('CHICKEN SATAYS (20)', 35, [], 'Grilled chicken skewers served with Thai peanut sauce.'),
    sitem('FRIED TOFU (30)', 30, ['veg', 'gf']),
    sitem('FRIED RICE (30)', 30, ['veg', 'gf']),
    sitem('FRIED EGGROLLS (10)', 30),

]

const platters = [
    sitem('SMALL FOR 8-10', 120, ['fish'], 'Spicy tuna (12), Spicy salmon (12), California roll (12), Idaho roll (10), Philadelphia roll (12), Spicy yellowtail (12), Bruins maki (10), Fluffy Maki (8)'),
    sitem('MEDIUM FOR 15-20', 230, ['fish'], 'Avocado Maki (12), Salmon avocado (18), Spicy tuna (18), Spicy Salmon (18), California roll (18), Spicy Crab (12), Idaho roll (15), Philadelphi roll (18), Spicy yellowtail (18), Bruins maki (15), Fluffy maki (8), Hungry dragon (8)'),
    sitem('LARGE FOR 25-30', 360, ['fish'], 'Avocado Maki (12), Eel avocado (12), Salmon avocado (25), Spicy tuna (25), Spicy Salmon (25), California roll (18), Spicy Crab (18), Idaho roll (25), Philadelphi roll (18), Spicy yellowtail (25), Bruins maki (15), Fluffy maki (8), Hungry dragon (8)'),
    sitem('VEGGIE POMBO FOR 8-10', 95, ['veg'], 'Idaho roll (15), Bruins maki (15), Avacado maki (15), Cucumber maki (15), Mango avocado (18)')
]

const dishes = [
    sitem('PADTHAI', 100, ['veg', 'spicy'], 'Stir-fried rice noodle with scrambled egg, bean sprouts, and chives seasoned with palm sugar, fish sauce, tamarind juice topped with crushed peanuts and choice of meat.'),
    sitem('PAD SEE EW', 100, ['veg', 'gf'], 'Stir-fried wide rice noodles and Chinese broccoli with black soy sauce and your choices of meat'), 
    sitem('DRUNKEN NOODLE', 100, ['veg', 'gf', 'spicy'], 'Spicy stir-fried flat noodle with scrambled egg, onion, bell pepper, basil, and bamboo shoots.'),
    sitem('PINEAPPLE FRIED RICE', 100, ['veg', 'gf'], 'Fried jasmine rice with vegatables, eggs, and pineapple seasoned with curry topped with cashew nuts.'),
    sitem('THAITAKI FRIED RICE', 100, ['spicy'], 'Fried jasmine rice with vegetables and eggs with your choice of protein or vegatables'),
    sitem('SPICY BASIL FRIED RICE', 100, ['gf', 'spicy'], 'Fried jasmine rice with garlic, chili, basil, vegatables, and eggs with your choice of protein or vegatables'),
    sitem('MASSAMAN CURRY WITH RICE', 100, ['gf', 'spicy'], 'Fusion of Indian-Thai style coconut based curry with sweet potato, carrot, onion, and peanuts'), 
    sitem('RED CURRY WITH RICE', 100, ['gf', 'spicy'], 'Thai red coconut curry with bamboo shoots, Thai eggplants, bell pepper, and sweet basil leaves with your choic of protein or veggies'),
    sitem('GA PROWS PORK OR CHICKEN', 120, ['gf', 'spicy'], 'Stir fried ground pork or chicken with hot basil, garlic, bell pepper, onion, jalapeno')
]

export const items = {
    starters,
    platters,
    dishes
}