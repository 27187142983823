
import webdemosReducer, { 
    updateState,
    updateCurrentPage
} from './slice'


export const WEBDEMOS_TYPES = {
    UPDATE_STATE: 'UPDATE_STATE',
    UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE'
}

export const WEBDEMOS_SLICE = {
    UPDATE_STATE: updateState,
    UPDATE_CURRENT_PAGE: updateCurrentPage
}

export { webdemosReducer }
export default webdemosReducer