import { Components } from './../../../../components'
import { Assets } from './../../assets'

const {
    FBox,
    Box,
    Typography,
    alpha
} = Components

const createItem = (title, price, description) => ({title, price, description})
const items = [
    createItem('POLPETTE', 14, 'house veal meat balls, tomato sauce and mozarella cheese'),
    createItem('CAPRESE', 13, 'fresh mozzarella, tomatoes, roasted red peppers, and pesto spread'),
    createItem('PORCHETTA', 14, 'trimmings from our porchetta entree, anula, provolone cheese and chipotle aioli'),
    createItem('POLLO', 14, 'grilled chicken breast, mozzarella cheese, red onion, fresh basil and red chili mayo'), 
    createItem('PROSCUITO MOZZARELLA', 14, 'parma posciutto ham, fresh mozzarella, tomatoes, arugula and chipotle aioli')
]

const Wrapper = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        p: 2,
        width: '100%',
        mt: 5,
        borderRadius: 4,
        position: 'relative',
        flexDirection: 'column',
        '&::before, &::after': {
            content: '""',
            width: '100%', 
            height: '100%',
            position: 'absolute',
            top: 0, 
            left: 0,
            zIndex: 1,
            borderRadius: 4
        },
        border: '10px solid blue', // Defines border width and makes it transparent
        borderImageSource: `url(${Assets.borderImages.greenBorder})`, // Border image URL
        borderImageSlice: 30, // Controls slicing of the border image
        borderImageRepeat: 'stretch', 
    }, _sx)
    return <FBox sx={sx} {...other}>
        {children}
    </FBox>
}
const Title = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontSize: '1.4rem', 
        color: t => t.palette.text.main,
        fontWeight: 'bold'
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}
const SubTitle = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontWeight: 'bold',
        fontFamily: t => t.typography.menu.title,
        fontSize: '.9rem'
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}
const Text = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontSize: '.9rem', 
        color: t => alpha(t.palette.text.main, '.8'),
        fontStyle: 'italic'
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}
const Subtext = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontSize: '.7rem', 
        color: t => alpha(t.palette.text.main, .6),
        textAlign: 'center',
        fontStyle: 'italic'
    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}

const Item = ({title, price, description}) => {
    return <FBox sx={{width: '100%', flexDirection: 'column', gap: 0}}>
        <SubTitle>{title} - {price} -</SubTitle>
        <Text>{description}</Text>
    </FBox>
}
export const Pastas = props => {
    return <Wrapper>
        <Title>Oven Baked Paninis</Title>

        <FBox sx={{flexDirection: 'column', gap: 2, mt: 2}}>
            {
                items.map(item => (<Item key={item.title} {...item} />))
            }
        </FBox>
        <FBox sx={{mt: 3}}>
            <Subtext>
                *LUNCH ONLY served with side of tossed green salad, cherry tomatoes, red onion in a hous vinaigrette substitute with ceaser salad, french fries, or soup of the day.
            </Subtext>

        </FBox>
    </Wrapper>
}