import { BaseComponents } from './../../../../components'
import { RowHeader } from './../../base'
import { menu_items } from './../menuitems'

const {
    FBox,
    Box,
    Typography
} = BaseComponents



const MenuItem = props => {
    const { name, label, description, price } = props
    return <FBox sx={{ width: '100%', display: 'flex', gap: 2}}>
        <FBox><Typography sx={t => ({fontWeight: 'bold', fontSize: '1.3rem', whiteSpace: 'nowrap', ...t.typography.menu.title })}>{label}</Typography></FBox>
        <FBox><Typography sx={t => ({ fontSize: '1.2rem', overflowWrap: 'break-word', ...t.typography.menu.description })}>{description}</Typography></FBox>
        <FBox><Typography sx={{ width: '50px', fontWeight: 'bold' }}>{price}</Typography></FBox>
    </FBox>
}




export const Specialties = props => {
    
    return <FBox sx={{flexDirection: 'column', alignItems: 'start', width: '100%'}}>
        <RowHeader title="Specialties" />
        <FBox sx={{flexDirection: 'column', mt: 2}}>
            {
                menu_items.map(mi => {
                            return <MenuItem key={mi.name} {...mi}/>
                        })
            }
        </FBox>
    </FBox>
}