

import { Box } from '@mui/material'

export const FBox = props => {

    const {children, sx={}, ...other } = props
    const _sx = Object.assign({
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    }, sx)
    return <Box sx={_sx} {...other}>{children}</Box>
}


