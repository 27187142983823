import { Components } from './../../../../components'


const {
    FBox,
    Box,
    Typography
} = Components

const Text = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({

    }, _sx)
    return <Typography sx={sx} {...other}>{children}</Typography>
}



export const PizzaConfirmation = props => {
    const { pizza } = props


    return <FBox sx={{
        width: '100%', 
        flexDirection: 'column', 
        alignItems: 'start',
        minHeight: 250,
        justifyContent: 'start'
    }}>
        <Text>1 - {pizza.crust} pizza</Text>
        <Box sx={{height: 10}}></Box>
        <Text sx={{ml: 2}}>+ {pizza.meat}</Text>
        <Box sx={{height: 10}}></Box>
        {
            pizza.toppings.map(ti => {
                return <Text key={ti} sx={{ml: 2, fontStyle: 'italic', fontSize: '.9rem'}}>+ {ti}</Text>
            })
        }

    </FBox>

}