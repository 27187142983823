import dashboardReducer, { 
    updateRerender,
    updateSidebarOpen
} from './slice'


export const DASHBOARD_TYPES = {
    UPDATE_RERENDER: 'UPDATE_RERENDER',
    UPDATE_SIDEBAR_OPEN: 'UPDATE_SIDEBAR_OPEN'
}

export const DASHBOARD_SLICE = {
    UPDATE_RERENDER: updateRerender,
    UPDATE_SIDEBAR_OPEN: updateSidebarOpen
}

export { dashboardReducer }
export default dashboardReducer