import { useState } from 'react'
import { BaseComponents } from './../../../../components'
import { RowHeader } from './../../base'
import { pizza as pizza_data, initialState } from './pizza'
import { PizzaSelect, PizzaMultiSelect } from './PizzaSelect'
import { Assets } from './../../assets'
import { PizzaConfirmation } from './PizzaConfirmation'
const {
    Box, 
    FBox,
    Typography,
    alpha
} = BaseComponents

const SelectionHeader = props => {
    const {n, text} = props
    const Number = ({n}) => {
        return <FBox sx={{
            color: t => t.palette.text.opposite, 
            fontWeight: 'bold', 

            width: 30, 
            height: 30, 
            borderRadius: '50%', background: 'rgba(0,0,0,.2)'
    }}>{n}</FBox>
    }
    return <FBox sx={{gap: 1, mb: 2}}>
        <Number n={n} />
        <Typography sx={{fontWeight: 'bold'}}>{text}</Typography>
    </FBox>
}
const Section = props => {
    const {children, sx: _sx={}, ...other} = props

    return <FBox sx={{
        mt: 4, 
        width: '100%', 
        flexDirection: 'column', 
        alignItems: 'start',
        background: t => t.palette.background.card,
        p: 2,

    }}
    {...other}
    >
        {children}
    </FBox>
}
export const PizzaBuilder = props => {

    const [pizza, setPizza] = useState(initialState)
    const onChange = (evt, name, value) => {
        // ensure the attribute is 
        setPizza({
            ...pizza,
            [name]: value
        })
    }

    const onToppingChange = (evt, name, value) => {
        // update the values - remove if it is in the list, otherwise add
        let toppings = pizza.toppings.map(ti => ti)
        const vals = toppings.filter(ti => ti === value)
        if(vals.length === 0) {
            // add the value
            // account for if they selected none
            if(value === 'none') {
                toppings = []
            } else {
                toppings = toppings.filter(ti => ti !== 'none')
            }
            toppings.push(value)
            setPizza({
                ...pizza,
                toppings
            })
        } else {
            let trimmed_toppings = toppings.filter(ti => ti !== value)

            // if they selected none - remove all other toppings. else remove no toppings
            if(value === 'none') {
                trimmed_toppings = ['none']
            } else {
                trimmed_toppings = trimmed_toppings.filter(ti => ti !== 'none')
            }
            setPizza({
                ...pizza,
                toppings: trimmed_toppings
            })
        }
    }

    return <FBox sx={{
        flexDirection: 'column', 
        alignItems: 'start', 
        mt: 5, 
        border: '1px solid blue',
        p: 2,
        border: '10px solid blue', // Defines border width and makes it transparent
        borderImageSource: `url(${Assets.borderImages.redBorder})`, // Border image URL
        borderImageSlice: 30, // Controls slicing of the border image
        borderImageRepeat: 'repeat', 
        background: '#fff'
    }}>
        <RowHeader title="Build your Own" />

        <Section>
            <SelectionHeader n={1} text={'Select your crust'} />
            <PizzaSelect value={pizza.crust} options={pizza_data.crust} onChange={onChange} datakey="crust"/>
        </Section>
        <Section>
            <SelectionHeader n={2} text={'Select your protein'} />
            <PizzaSelect value={pizza.meat} options={pizza_data.meat} onChange={onChange} datakey="meat"/>
        </Section>
        <Section>
            <SelectionHeader n={3} text={'Select your toppings'} />
            <PizzaMultiSelect values={pizza.toppings} options={pizza_data.toppings} onChange={onToppingChange} datakey="toppings"/>
        </Section>


    </FBox>
}