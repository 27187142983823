import FishIcon from './fish.png'
import GFIcon from './gf.png'
import SpicyIcon from './spicy.png'
import VegIcon from './veg.png'
import SushiImage from './sushi.jpg'
import PlatterImage from './platter.png'
import MainImage from './main.png'
import NoodleBowl from './noodlebowl.png'

export const Assets = {
    fish: FishIcon,
    gf: GFIcon,
    spicy: SpicyIcon,
    veg: VegIcon,
    sushi: SushiImage,
    platter: PlatterImage,
    main: MainImage,
    noodles: NoodleBowl
}