import { BaseComponents } from './../../../components'
import { CollapsibleMenu } from './CollapsibleMenu'

const {
    Box,
    FBox,

} = BaseComponents
// so I'm thinking 3 menus - How it works - 1 landing page - about page - checkout page - psychology page
export const SubHeader = props => {
    const { options, activePage, setActivePage } = props


    const onClick = name => {
        setActivePage(name)
    }
    return <CollapsibleMenu menu_items={options} activePage={activePage} setActivePage={setActivePage} onClick={onClick}/>
    // return <FBox sx={{p: 1, px: 3, width: '100%', justifyContent: 'space-between'}}>
    //     {
    //         options.map(oi => {
    //             return <FBox 
    //                 key={oi.name}
    //                 onClick={() => setActivePage(oi.name)}
    //                 sx={{
    //                     cursor: 'pointer',
    //                     color: t => activePage === oi.name ? t.palette.primary.main : t.palette.text.main
    //                 }}
    //             >
    //                 {oi.label}
    //             </FBox>
    //         })
    //     }
    // </FBox>
}
