
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    rerender: 0,
    sidebarOpen: true
};

// Create the slice
const dashboardSlice = createSlice({
  name: 'dashboard', // Name of the slice
  initialState,
  reducers: {
    updateRerender: (state) => {
        return {
            ...state, 
            rerender: state.rerender + 1
        }
    },
    updateSidebarOpen: (state, action) => {
      const { sidebarOpen } = action.payload
      return {
        ...state, 
        sidebarOpen,
        rerender: state.rerender + 1
      }
    }
  },
});

// Export actions
export const { 
    updateRerender,
    updateSidebarOpen
} = dashboardSlice.actions

// Export reducer
export default dashboardSlice.reducer;