
import { BaseComponents } from './../../../components/base'


const {
    Box,
    FBox
} = BaseComponents

export const CaseStudies = props => {
    return <FBox sx={{flexDirection: 'vertical', background: t => t.palette.background.variant2}}>
 
    </FBox>
}