import { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import useTheme from './theme'
import { routes } from './routes';
import { useSelector } from './store/dispatcher'
import {Elements} from '@stripe/react-stripe-js';
import { getStripe } from './tools/'
import './App.css'

const stripePromise = getStripe()

export const App = () => {
  const webapp = useRoutes(routes)
  const mode = useSelector(state => state.session.theme)
  const theme = useTheme(mode)

  return <>
  <CssBaseline />
  <Elements stripe={stripePromise} >
    <ThemeProvider theme={theme}>
      {webapp}
    </ThemeProvider>
    </Elements>
  </>
}


export default App