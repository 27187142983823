import merge from 'deepmerge'
import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material/';
import { alpha } from '@mui/material/styles'

const theme = createTheme()

const base_theme = merge(theme.palette, {
    background: {
        main: colors.grey[50],
        header: '#222',
        hero: colors.green[800],
        hero2: colors.orange[100],
        card: colors.grey[100],
        variant1: colors.grey[600],
        variant2: colors.green[50],
        sidebar: '#222',
        // specials: '#f6f8fb',
        specials: '#222',
        services: colors.orange[50]
    },
    primary: {
        main: colors.blue[300],
        text: '#fff'
    }, 
    secondary: {
        main: colors.brown[300],
        text: '#fff'
    },
    text: {
        main: '#222',
        opposite: '#eee',
        sidebar: '#eee'
    },
    dashboard: {
        
    },

})
const light = merge(base_theme, {
    mode: 'light',
})
const dark = merge(base_theme, {
    mode: 'dark',

})

export default {
    light,
    dark
}