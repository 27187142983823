import { BaseComponents } from './../../../../components'

const {
    Box,
    FBox,
    Typography,
    Grid
} = BaseComponents


const Option = props => {
    const { name, label, Icon, active, onChange, datakey } = props

    return <FBox 
        onClick={evt => onChange(evt, datakey, name)}
        sx={{
            flexDirection: 'column', 
            gap: 1,
            cursor: 'pointer',
            opacity: active ? .9 : .6

    }}>
        <Box sx={{'&, & *': {
            opacity: active ? .9 : .6
        }}}>
            {Icon}

        </Box>
        {label}
    </FBox>
}


export const PizzaSelect = props => {
    const {value, options, onChange, datakey} = props

    return <FBox sx={{gap: 3, width: '100%'}}>
        {
            options.map(oi => (<Option key={oi.name} active={oi.name === value} {...oi} onChange={onChange} datakey={datakey}></Option>))
        }
    </FBox>
}

export const PizzaMultiSelect = props => {
    const { values, options, onChange, datakey } = props
    
    const tosee = ['garlic']
    const _options = options.filter(oi => tosee.includes(oi.name)) 

    return <Grid container>



        {
            options.map(oi => {
                return <Grid item size={{xs: 12, sm: 6, md: 4}}>

                    <Option key={oi.name} active={values.includes(oi.name)} {...oi} onChange={onChange} datakey={datakey}>

                    </Option>
                </Grid>
            })
        }
        

    </Grid>
}