import sessionReducer, { 
    updateScreenDimensions,
    updateTheme
} from './slice'


export const SESSION_TYPES = {
    UPDATE_SCREEN_DIMENSIONS: 'UPDATE_SCREEN_DIMENSIONS',
    UPDATE_THEME: 'UPDATE_THEME'
}

export const SESSION_SLICE = {
    UPDATE_SCREEN_DIMENSIONS: updateScreenDimensions,
    UPDATE_THEME: updateTheme
}

export { sessionReducer }
export default sessionReducer