/*
Return a 'TYPE -> dispatch function' map to create a single interace to dispatch actions.

src/dispatcher/ <- all components submit dispatches through here 

METHOD CALL:
dispatcher(dispatch, type, payload)

REQUIREMENTS: the string values need to be prepended with slice name 
examples
TYPES.TREE.LOAD_TREE -> "TREE_LOAD_TREE' (prepended with TREE to avoid name conflicts

const TREE_TYPES = {
    LOAD_TREE: TREE_LOAD_TREE
}
*/
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { sessionReducer, SESSION_SLICE, SESSION_TYPES } from './../../components/session'
import { dashboardReducer, DASHBOARD_SLICE, DASHBOARD_TYPES } from './../../components/dashboard'
import { webdemosReducer, WEBDEMOS_SLICE, WEBDEMOS_TYPES } from './../../pages/webdemos/slice'

const SLICE_MAP = {
    ...SESSION_SLICE,
    ...DASHBOARD_SLICE,
    ...WEBDEMOS_SLICE


}

const dispatcher = (dispatch, type, payload) => {
    dispatch(SLICE_MAP[type](payload))
}

export const useDispatcher = () => {
    const dispatch = useDispatch()
    return (type, payload) => dispatcher(dispatch, type, payload)
}

export const TYPES = {
    SESSION: SESSION_TYPES,
    DASHBOARD: DASHBOARD_TYPES,
    WEBDEMOS: WEBDEMOS_TYPES

}


export { useSelector } // cuts an extra import if needed
export default {
    session: sessionReducer,
    dashboard: dashboardReducer,
    webdemos: webdemosReducer


}