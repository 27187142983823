

import { BaseComponents } from './../../../components/base' 
import { ParticleGrid } from './particlegrid'
import { useSelector } from './../../../store/dispatcher'
const {
    Box, 
    Typography,
    useTheme,
    FBox,
    Grid
} = BaseComponents
const { 
    AutomationIcon,
    IntegrationIcon,
    AnalysisIcon,
    VisualizationIcon

 } = BaseComponents.Icons

const Title = props => {
    return <Typography sx={{
        fontSize: '2.5rem',
        color: t => t.palette.text.opposite
    }}>{props.children}</Typography>
}
const SubTitle = props => {
    return <Typography sx={{
        fontSize: '1.2rem',
        color: t => t.palette.text.opposite
    }}>{props.children}</Typography>
}
const ListItem = ({text, Icon}) => {

    return <FBox sx={{
        width: '100%', 
        justifyContent: 'start',
        alignItems: 'center',
        jusitifyContent: 'center',
        height: '100%',
        background: '#aaa',
        borderRadius: 4,
        p: 1,
        gap: 1,
        '&, & *': {
            color: t => t.palette.text.opposite
        }
    }}>
        {Icon}
        <Box sx={{width: '100%'}}>{text}</Box>
    </FBox>
}
const HeroTextPane = () => {
    return <FBox sx={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{width: 200}}></Box>
        <Box sx={{display: 'flex', flexDirection: 'column', ml: 10}}>
            <Title>Tools for Small Business Restaurants</Title>
            <FBox sx={{gap: 2}}>
                <ListItem Icon={<AutomationIcon />} text="Automation" />
                <ListItem Icon={<IntegrationIcon />} text="Integration" />
                <ListItem Icon={<AnalysisIcon />} text="Analysis" />
                <ListItem Icon={<VisualizationIcon />} text="Visualization" />
            </FBox>
            <SubTitle>When you have many moving parts.</SubTitle>
        </Box>
    </FBox>
}
const HeroImagePane = () => {
    return <FBox sx={{width: '100%', height: '100%'}}>
        <Box sx={{width: 400, height: 400, background: 'rgba(0,0,0,.05)'}}></Box>
    </FBox>
}



export const Hero = () => { 
    const theme = useTheme()
    const { width } = useSelector(state => state.session.screen)
    const height = 400
    return <Box sx={{position: 'relative', width: '100%', height: '100%'}}>
        <ParticleGrid width={width} height={height} />
        <Grid container sx={{

            background: t => t.palette.background.hero
        }}>
            <Grid item size={{xs: 12, lg: 12}} sx={{height: 400}}><HeroTextPane /></Grid>

        </Grid>
    
    </Box>
}

export { Hero2 } from './Hero2'