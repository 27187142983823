
import { useRef, useEffect, useState } from 'react'
import { BaseComponents } from './../../base'
import { useSelector } from './../../../store/dispatcher'

const {
    Box,
    FBox
} = BaseComponents


export const ChartWrapper = props => {

    const {children, render, sx: _sx={}, ...other} = props
    const [width, setWidth] = useState(0)
    const ref = useRef(null)
    const { rerender } = useSelector(state => state.session)
    const sx = Object.assign({
        width: '100%', 
    }, _sx)
    
    useEffect(() => {
        if(!ref || !ref.current) { return }

        const {width: _width} = ref.current.getBoundingClientRect()
        // alert('setting _width')
        setWidth(_width)
    }, [ref.current, rerender])

    return <Box ref={ref} sx={sx}>
        {
            render({width, ...other})
        }
    </Box>
}