import redBorder from './images/borders/red_border.png'
import brownBorder from './images/borders/brown_border.png'
import brownBorderHalf from './images/borders/brown_border_half.png'
import heroBanner from './images/banners/hero.png'
import servingTraySeparator from './images/decorations/serving_tray_separator.png'
export const Assets = {
    images: {
        borders: {
            redBorder,
            brownBorder,
            brownBorderHalf
        },
        banners: {
            hero: heroBanner
        },
        decorations: {
            servingTraySeparator
        }
    }
}