export const items = {
    "appetizers & starters": [
      {
        "name": "SKILLET CORNBREAD",
        "description": "Whipped Butter",
        "price": "8"
      },
      {
        "name": "QUESO CON PUERCO",
        "description": "Green Chili Pork / Red Pepper Relish / Corn Chips",
        "price": "11"
      },
      {
        "name": "KETTLE OF GREEN CHILI PORK STEW",
        "description": "Flour Tortillas",
        "price": "12"
      },
      {
        "name": "GUACAMOLE",
        "description": "Cilantro / Corn Chips",
        "price": "12"
      },
      {
        "name": "SHAVED BEEF TENDERLOIN CARPACCIO*",
        "description": "Arugula / Horseradish / Onion / Capers / Parmesan / Truffle Oil",
        "price": "16"
      },
      {
        "name": "MUSSELS AND CHORIZO",
        "description": "House Made Chorizo / Cherry Tomatoes / Garlic / White Wine / Sourdough Toast",
        "price": "18"
      },
      {
        "name": "BEEF FONDUE*",
        "description": "Beef Tenderloin / Roasted Butternut Squash / Sourdough Croutons / White Wine Pepperjack Fondue",
        "price": "20"
      },
      {
        "name": "“BIG ASS” SPICY MEATBALLS*",
        "description": "Smoked Tomato Marinara / Queso Oaxaca / Toast Points",
        "price": "16"
      }
    ],
    "soups & salads": [
      {
        "name": "SPINACH CHICKEN WALDORF SALAD",
        "description": "Spit-Roasted Chicken / Spinach / Avocado / Grapes / Eggs / White Cheddar / Dates / Candied Walnuts / Apples / Sweet Onion Dressing",
        "price": "16"
      },
      {
        "name": "ICEBERG BLT",
        "description": "Cherry Tomatoes / Blue Cheese / Bacon / Red Onion / Green Onion / Ranch Dressing",
        "price": "10"
      },
      {
        "name": "CAESAR SALAD",
        "description": "Parmesan Cheese / Chile Croutons",
        "price": "10"
      },
      {
        "name": "SALAD ADD-ONS",
        "description": "steak 18 / salmon 15 / shrimp 15 / chicken 8"
      },
      {
        "name": "FRENCH ONION SOUP",
        "description": "Sourdough Croutons / Gruyere Cheese / Chives",
        "price": "11"
      },
      {
        "name": "TORTILLA SOUP",
        "description": "Rotisserie Chicken / Avocado / Cotija / Cilantro / Tortilla Strips",
        "price": "11"
      }
    ],
    "premium steaks from our grill":  [
        {
          "name": "8 oz / 12 oz FILET*",
          "price": "46 / 65"
        },
        {
          "name": "20 oz RIBEYE*",
          "price": "56"
        },
        {
          "name": "18 oz NEW YORK STRIP*",
          "price": "53"
        },
      ],
    "other steaks": [

            {
              "name": "50 DAY DRY AGED BONE IN RIBEYE*",
              "price": "4.5 / oz"
            },
            {
              "name": "50 DAY DRY AGED PORTERHOUSE*",
              "price": "4.5 / oz"
            },
            {
              "name": "50 DAY DRY AGED BONE IN NY STRIP*",
              "price": "4.5 / oz"
            }

        
      ],

    "steak accompaniments": [
      {
        "name": "GRILLED SHRIMP WITH GARLIC BUTTER SAUCE",
        "price": "20"
      },
      {
        "name": "SIDE OF BEARNAISE, AU POIVRE SAUCE, OR CREAMY HORSERADISH",
        "price": "5"
      },
      {
        "name": "MUSHROOMS AND ONIONS",
        "price": "5"
      },
      {
        "name": "BLUE CHEESE",
        "price": "5"
      }
    ],
    "specialties of the house": [
      {
        "name": "BIG ASS BURGER*",
        "description": "Applewood Bacon / Poblano Pepper / Cheddar / Chipotle Mayo Lettuce / Tomato / Onions / Pickles / House Cut Fries",
        "price": "18",
        "note": "Gluten free bun available upon request $2.50"
      },
      {
        "name": "BACON WRAPPED PORK TENDERLOIN*",
        "description": "Poblano Grits / Charred Corn / Cotija / Cilantro / Chipotle Balsamic Reduction",
        "price": "26"
      },
      {
        "name": "RAINBOW TROUT",
        "description": "Sauteed Green Beans / Brown Butter / Fried Capers / Toasted Almonds / Grilled Lemon",
        "price": "26"
      },
      {
        "name": "SHRIMP AND GRITS",
        "description": "Poblano Grits / Chopped Tomato / Butter Garlic Sauce",
        "price": "30"
      },
      {
        "name": "BLACKENED REDFISH",
        "description": "Quinoa and Lentils / Smoked Tomatoes Scallions / Chipotle Tabasco Butter",
        "price": "36"
      },
      {
        "name": "CEDAR PLANK SALMON*",
        "description": "Apricot Teriyaki Glaze / Chilled Green Bean Salad / Soy Butter",
        "price": "29"
      },
      {
        "name": "ROTISSERIE CHICKEN",
        "description": "Elgin Sausage Cornbread / Green Beans / Lemon Garlic Jus",
        "price": "24"
      }
    ],
    "sides": [
      { "name": "TRUFFLE FRIES", "price": "11" },
      { "name": "SEASONAL VEGGIE", "price": "12" },
      { "name": "MEXICAN STREET CORN", "price": "9" },
      { "name": "BACON & ONION BRUSSELS SPROUTS", "price": "11" },
      { "name": "STEAK MUSHROOMS", "price": "9" },
      { "name": "GREEN CHILI MAC & CHEESE", "price": "10" },
      { "name": "WILTED SPINACH & ONIONS", "price": "9" },
      { "name": "MASHED POTATOES", "price": "9" },
      { "name": "POBLANO & PEPPER JACK CHEESE GRITS", "price": "9" },
      { "name": "ELGIN SAUSAGE STUFFING", "price": "9" }
    ],
    "cocktails": [
      { "name": "BIG LARRY", "description": "Frozen Margarita / Grand Marnier", "price": "12", "note": "Add Blood Orange, Huckleberry, or Mango Swirl" },
      { "name": "FOUNDERS MARGARITA", "description": "Socorro Blanco / Cointreau / Lime / Agave", "price": "13" },
      { "name": "PEACH OLD FASHIONED", "description": "Wyoming Whiskey / Peach Cinnamon Syrup / Angostura Bitters", "price": "16" },
      { "name": "ZACAPA OLD FASHIONED", "description": "Ron Zacapa 23 Rum / Simple Syrup Angostura and Chocolate Bitters", "price": "16" },
      { "name": "BUSINESS AS YUZUAL", "description": "Haiken Yuzu Vodka / Aperol / Lemon / Orange / Mint", "price": "15" },
      { "name": "VIEUX CARRE", "description": "Pierre Ferrand Cognac / Knob Creek Rye Carpano Antica / Benedictine / Peychauds Bitters", "price": "16" },
      { "name": "LIVIN’ THE HYE LIFE", "description": "Garrison Brothers Honeydew / Chamomile Syrup Lemon / Lady Bird Van Zandt Club Soda", "price": "22" },
      { "name": "BLUE CRUSH", "description": "Zephyr Gin / Chareau Aloe Liqueur Lime / Simple Syrup / Blue Majik", "price": "15" },
      { "name": "ROARING RUM RICKEY", "description": "Cruzan Strawberry Rum / Lime / Agave Sparkling Water / Fresh Strawberries", "price": "14" },
      { "name": "CARA DE PINA", "description": "Carabuena Blanco / Ancho Reyes / Cointreau / Lime / Pineapple", "price": "16" },
      { "name": "HUCKLEBERRY HOMBRE", "description": "Dos Hombres Mezcal / Pear / Lime / Simple Syrup / Huckleberry", "price": "16" },
      { "name": "RUSTY FORK", "description": "Highland Park 12 / Drambuie / Lemon and Lavender Bitters", "price": "16" }
    ],
    "beer": [
      "ABW PEARLSNAP 7 / AUSTIN EASTCIDER 7 / AUSTIN AMBER 7 / DOS XX LAGER 7 / LONE BONESHELL 7 / GUINNESS DRAUGHT 8 / STELLA ARTOIS 7 / FIREMAN’S #4 7 / MCGOONAHAZE IPA 8 / LIVE OAK HEFEWEIZEN 7 / YUENGLING 7 / LONE STAR 5 / FRESH HOPS HAZY IPA 7 / COORS LIGHT 5 / FREE EAGLE IPA 7 / MILLER LIGHT 5 / BUD LIGHT 5 / MICHELOB ULTRA 6 / MODELO ESPECIAL 7 / SHINER BOCK 6 / STASH IPA 7 / HEINEKEN 0.0 6"
    ],
    "wine": {
      "SPARKLING, CHAMPAGNE, & ROSÉ": [
        { "name": "BENVOLIO, Prosecco, Italy", "price": "12 / 40" },
        { "name": "RAVENTOS I BLANC, Blanc de Blanc, Penedes, Spain", "price": "14 / 54" },
        { "name": "ROEDERER ESTATE BRUT, Sparkling, Anderson Valley", "price": "90" },
        { "name": "RIVAROSE, Brut Prestige Rosé, Provence, France", "price": "14 / 54" },
        { "name": "DAOU, Rosé, Paso Robles", "price": "15 / 58" },
        { "name": "VEUVE CLICQUOT, Brut, Yellow Label, Champagne, France", "price": "180" }
      ],
      "SAUVIGNON BLANC": [
        { "name": "SANDY COVE, Marlborough, New Zealand", "price": "12 / 45" },
        { "name": "HONIG, Napa Valley", "price": "14 / 54" },
        { "name": "DUCKHORN, Napa Valley", "price": "76" }
      ],
      "CHARDONNAY": [
        { "name": "FAMILLE PAQUET, Bourgogne, France", "price": "15 / 58" },
        { "name": "ALEXANA TERROIR SERIES, Willamette Valley", "price": "17 / 66" },
        { "name": "ROMBAUER, Carneros, Napa Valley", "price": "100" },
        { "name": "SHAFER RED SHOULDER RANCH, Carneros, Napa Valley", "price": "130" },
        { "name": "PAUL HOBBS, Russian River Valley", "price": "120" },
        { "name": "DIABLO by ARISTA, Chardonnay, Russian River Valley", "price": "76" }
      ],
      "WHITE VARIETALS": [
        { "name": "FIDORA, Pinot Grigio, Veneto, Italy", "price": "10 / 38" },
        { "name": "KARL KASPAR, Riesling, Mosel, Germany", "price": "12 / 46" },
        { "name": "MARCHESI INCISA, Moscato d’Asti, Piedmont", "price": "14 / 54" },
        { "name": "HAJSZAN NEUMANN, Gruner, Austria", "price": "14 / 54" },
        { "name": "VERMENTINO BY ANTINORI, Bolgheri, Italy", "price": "16" },
        { "name": "LEFT COAST, Pinot Gris, Willamette Valley", "price": "15 / 58" },
        { "name": "JEAN-MAX ROGER, Sancerre, Loire Valley, France", "price": "115" }
      ],
      "PINOT NOIR AND BURGUNDY": [
        { "name": "HAHN, Monterey, California", "price": "11 / 42" },
        { "name": "LEFT COAST, Willamette Valley", "price": "15 / 58" },
        { "name": "BELLE GLOS CLARK & TELEPHONE, Central Coast", "price": "72" },
        { "name": "FLOWERS, Sonoma", "price": "28 / 110" },
        { "name": "BERGSTROM CUMBERLAND RESERVE, Willamette Valley", "price": "85" },
        { "name": "DOMAINE BACHELET-MONNOT, Burgundy, Bourgogne", "price": "100" },
        { "name": "TWOYME, Dundee Hills", "price": "120" },
        { "name": "DOMAINE SERENE EVENSTAD RESERVE, Pinot Noir, Willamette Valley", "price": "110" }
      ],
      "MERLOT": [
        { "name": "FREI BROTHERS RESERVE, Dry Creek, Sonoma", "price": "11 / 42" },
        { "name": "LOST CHAPTERS, Napa Valley", "price": "17 / 66" },
        { "name": "EMMOLO, Napa Valley", "price": "76" },
        { "name": "DUCKHORN, Napa Valley", "price": "120" },
        { "name": "PEDESTAL, Columbia Valley", "price": "140" }
      ],
      "CABERNET": [
        { "name": "DRUMHELLER, Columbia Valley", "price": "12 / 46" },
        { "name": "SERIAL, Paso Robles", "price": "15 / 58" },
        { "name": "BERINGER, Knights Valley", "price": "18 / 70" },
        { "name": "FAR NIENTE POST & BEAM, Napa Valley", "price": "25 / 98" },
        { "name": "FAUST, Napa Valley", "price": "30 / 118" },
        { "name": "AUSTIN HOPE, Paso Robles, 1L", "price": "120" },
        { "name": "FRANK FAMILY, Napa Valley", "price": "150" },
        { "name": "JORDAN, Alexander Valley", "price": "150" },
        { "name": "MOUNT VEEDER WINERY, Mount Veeder", "price": "180" },
        { "name": "SILVER OAK, Alexander Valley", "price": "180" },
        { "name": "TURNBULL RESERVE, Oak Knoll, Napa Valley", "price": "225" },
        { "name": "NICKEL & NICKEL ‘VACA VISTA’, Oak Knoll, Napa Valley", "price": "250" },
        { "name": "CAYMUS VINEYARDS, Napa Valley", "price": "275" },
        { "name": "QUINTESSA, Cabernet, Rutherford, Napa Valley", "price": "425" }
      ],
      "MALBEC": [
        { "name": "INKARRI, Mendoza, Argentina", "price": "13 / 50" },
        { "name": "VOYAGE II BY RED SCHOONER, Argentina", "price": "100" }
      ],
      "ZINFANDEL": [
        { "name": "SALDO, California", "price": "18 / 70" },
        { "name": "BLACK CHICKEN BY ROBERT BIALE, Napa Valley", "price": "158" }
      ],
      "BIG BLENDS AND RED VARIETALS": [
        { "name": "DAOU ‘PESSIMIST’, Red Blend, Paso Robles", "price": "56" },
        { "name": "WILLIAM CHRIS, Mourvedre, Texas High Plains", "price": "65" },
        { "name": "LAND OF SAINTS, GSM, Santa Barbara", "price": "68" },
        { "name": "LEVIATHAN, Red Blend, California", "price": "75" },
        { "name": "STOLPMAN VINEYARDS, Syrah, Ballard Canyon", "price": "78" },
        { "name": "STAGS’ LEAP, Petite Sirah, Napa Valley", "price": "80" },
        { "name": "THE PRISONER, Red Blend, California", "price": "26 / 100" },
        { "name": "DRAGONS TOOTH TREFETHEN, Red Blend, Oak Knoll, Napa Valley", "price": "120" },
        { "name": "CROWN POINT ESTATE SELECTION, Red blend, Santa Barbara", "price": "335" },
        { "name": "OPUS ONE, Red blend, Oakville, Napa Valley", "price": "550" }
      ],
      "OLD WORLD": [
        { "name": "MEDICI ERMETE, Dolce Lambrusco, Italy", "price": "12 / 40" },
        { "name": "CASA EMMA, Chianti Classico, Tuscany", "price": "14 / 54" },
        { "name": "ANTINORI IL BRUCIATO, Super Tuscan, Tuscany, Italy", "price": "20 / 80" },
        { "name": "LAN GRAN RESERVA, Tempranillo, Rioja, Spain", "price": "21 / 82" },
        { "name": "CIABOT DELLA LUNA, Nebbiolo, Piedmont", "price": "100" },
        { "name": "FRANCO SERRA, Barolo, Piedmont, Italy", "price": "120" },
        { "name": "CASTELLO DI VOLPAIA, Chianti Classico Riserva, Italy", "price": "120" },
        { "name": "DOMAINE DE LA SOLITUDE, Chateauneuf-du-Pape, France", "price": "130" },
        { "name": "ALTESINO, Brunello di Montalcino, Tuscany, Italy", "price": "140" },
        { "name": "ANNONCE DE BEAUCASTEL MONANGE, Merlot, Saint-Emilion, France", "price": "150" },
        { "name": "PIO CESARE, Barolo, Italy", "price": "200" },
        { "name": "TIGNANELLO by ANTINORI, Super Tuscan, Tuscany, Italy", "price": "300" }
      ]

    }
  }



  