import { useTheme } from '@mui/material';


export const typographies = {
    light: {
        logo: {fontSize: '5rem', color: 'blue'}
    },
    lorenzos: {
        logo: {
            fontSize: '4.3rem',
            fontFamily: '"Italianno", cursive',
            fontWeight: 1000
        },
        menu: {
            title: {
                fontSize: '1.2rem', 
                fontFamily: '"Italionno", cursive',
            },
            description: {
                fontFamily: 'ubuntu',
                fontStyle: 'italic',
                fontSize: '.8rem'
            }
        }
    }
}

export default typographies;