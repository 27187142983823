import { Components } from './../../../../components'



const {
    FBox,
    Box,
    Typography,
    SplitContent
} = Components

export const DashboardLandingPages = props => {
    return <FBox sx={{width: '100%'}}>
        <SplitContent></SplitContent>
    </FBox>
}