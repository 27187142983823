import merge from 'deepmerge'
import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material/';
import { alpha } from '@mui/material/styles'

const theme = createTheme()

const base_theme = merge(theme.palette, {
    background: {
        main: colors.grey[50],
        header: colors.green[800],
        // card: colors.grey[100],
        card: '#fff',
        variant1: colors.grey[600],
        variant2: colors.green[50],

    },
    text: {
        main: '#222',
        opposite: '#eee',
        sidebar: '#eee'
    },
    dashboard: {
        
    }
})
const light = merge(base_theme, {mode: 'light'})

const lorenzos = merge(base_theme, {
    mode: 'light'
})
const bistro = merge(base_theme, {
    mode: 'dark',
    background: {
        main: '#222',
        menu: 'rgba(255,255,255,.25)'
    },
    secondary: {
        main: '#e84734',
        light: '#ee9948'
    },
    text: {
        main: '#fff',
        mute: 'rgba(255,255,255, .4)'
    }
})
const emptyplate = merge(base_theme, {
    mode: 'light',
    background: {
        main: '#fff',
        menu: '#fffff7'
    },
    primary: {
        main: '#8d5c29'
    }
})
// const light = merge(base_theme, {
//     mode: 'light',
//     background: {
//         main: colors.blue[50]
//     },
//     primary: {
//         main: colors.blue[500],
//         text: 'rgba(255,255,255,1)'
//     },
//     text: {
//         main: 'rgb(0,0,0)'
//     }
// })
// const dark = merge(base_theme, {
//     mode: 'dark',
//     background: {
//         main: colors.grey[800]
//     },
//     primary: {
//         main: colors.blue[500],
//         text: 'rgba(255,255,255,1)'
//     },
//     text: {
//         main: 'rgb(255,255,255)'
//     }
// })
// const green = merge(base_theme, {
//     mode: 'dark',
    
// })


const checkout = merge(base_theme, {})
const pricing = merge(base_theme, {})
const blog = merge(base_theme, {})
const admin = merge(base_theme, {})
export default {
    light,
    lorenzos,
    bistro,
    emptyplate,
    checkout,
    pricing,
    blog,
    admin
}