import { BaseComponents } from './base'
import { Header } from './header'
import { Charts } from './charts'
import { StateSelect, CountrySelect } from './forms'
import { PageComponents } from './page'
import { Assets } from './assets'
// something to be careful of - on the form there is a TextField,
// if I were to pass {...Form} it would override the MUI which lives 
// in base components - for now I just passed the State and Country Select
export const Components = {
    ...BaseComponents,
    Header,
    Charts,
    StateSelect,
    CountrySelect,
    Assets,
    ...PageComponents,



}

export {
    BaseComponents,
    Header,
    Charts,
    Assets
}