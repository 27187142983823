import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useWhenBreakpointIs = (breakpoints) => {
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  if (xl) {
    return breakpoints.xl;
  } else if (lg) {
    return breakpoints.lg;
  } else if (md) {
    return breakpoints.md;
  } else if (sm) {
    return breakpoints.sm;
  } else if (xs) {
    return breakpoints.xs;
  }

  return null; // Default value if none of the breakpoints match
}

