import { useState, useEffect } from 'react'
import { Components } from './../../../components'
import BannerImage from './banner.png'
import { WebServices } from './WebServices'
import { OrderServices } from './OrderServices'
import { AdminServices } from './AdminServices'
const {
    FBox,
    Box,
    Typography,
    SplitContent,
    Header1,
    Subtext,
    Body1,
    Icons,
    Button,
    Assets,
    alpha
} = Components

const MyIcon = props => {
    const { name, isActive } = props
    const Icon = Icons[name]
    return <Icon sx={{
        color: t =>  isActive ? t.palette.primary.main : t.palette.text.main,
        fontSize: {xs: 24, sm: 36, md: 48, lg: 64}
    }}></Icon>
}

const WebIcon = props =>  {
    return <MyIcon name="WebIcon" {...props}/>
}
const OrderIcon = props =>  {
    return <MyIcon name="OrderIcon" {...props}/>
}
const AdminIcon = props =>  {
    return <MyIcon name="AdminIcon" {...props}/>
}

const ServiceBox = props => {
    const { title, isActive, onClick, Icon } = props
    return <FBox onClick={onClick} sx={{
        p: {xs: 1, lg: 3}, 
        borderRadius: 4, 
        gap: 1, 
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        cursor: 'pointer', 
        '&, & *': {
            color: t => isActive ? t.palette.primary.main : t.palette.text.main
        }



    }}>
        <Typography sx={{
            fontWeight: 'bold', 
            fontSize: {xs: '.7rem', sm: '1rem', lg: '1.15rem'}
        }}>{title}</Typography>
        <Box sx={{mt: 'auto'}}>
            {Icon}

        </Box>
    </FBox>
}



export const Services = props => {
    const [service, setService] = useState('web')
    const webActive = service === 'web'
    const ordersActive = service === 'orders'
    const adminActive = service === 'admin' 

    return <FBox sx={{width: '100%', background: t => t.palette.background.services, pt: 5, gap: 5, flexDirection: 'column'}}>
        <FBox sx={{width: '100%'}}>
            <Box sx={{mt: 3}}>
                <Header1 sx={{fontSize: {lg: '3rem'} }}>Our Services</Header1>
            </Box>
        </FBox>

        <FBox sx={{width: '100%', gap: 5, height: '100%'}}>
            <ServiceBox title="Website/ Menu Design." isActive={webActive} onClick={() => setService('web')} Icon={<WebIcon isActive={webActive}/>}/>
            <ServiceBox title="Online Orders." isActive={ordersActive} onClick={() => setService('orders')} Icon={<OrderIcon isActive={ordersActive}/>}/>
            <ServiceBox title="Business Admin Tools" isActive={adminActive} onClick={() => setService('admin')} Icon={<AdminIcon isActive={adminActive}/>} />
        </FBox>
        {/* <FBox sx={{width: '100%', flexDirection: 'column', gap: 1}}>
            <FBox sx={{width: '100%', position: 'relative'}}>
                <Box component="img" src={Assets.images.decorations.servingTraySeparator} alt="Serving tray ornament" sx={{
                    width: '100%', 
                    maxWidth: 400, 
                    height: 'auto', 
                    opacity: .3,
                    objectFit: 'contain'
                }}/>
            </FBox>
            <Box sx={{width: '100%', maxWidth: 400, height: 2, opacity: .3,  borderRadius: 0, background: t => t.palette.text.main}}></Box>
            <Box sx={{width: '100%', maxWidth: 400, height: 2, opacity: .3,  borderRadius: 0, background: t => t.palette.text.main}}></Box>


        </FBox> */}
        <FBox sx={{width: '100%', flexDirection: 'column'}}>

            {
                
                    service === 'web' ? <WebServices />
                                      : service === 'orders'
                                      ? <OrderServices />
                                      : <AdminServices />
                
            }
        </FBox>
        <Box sx={{height: 30}}></Box>
    </FBox>
}
