
import {select, selectAll} from 'd3-selection';
import  {transition}  from 'd3-transition'
import { 
    forceSimulation,
    forceLink,
    forceManyBody,
    forceCenter
} from 'd3-force'
import { drag } from 'd3-drag'
import * as _d3 from 'd3'

// fixing known incompatabilities with react, d3, d3 object, and submodules.
export const d3 =  {
    ..._d3,
    // ...d3Select,
    // ...d3Transition,
    // ...d3Force
    select, 
    selectAll,
    transition,
    forceSimulation,
    forceManyBody,
    forceCenter,
    drag
}