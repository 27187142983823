import { useState } from 'react'

import {
    Box, 
    Typography,
    Checkbox as MuiCheckbox,
    Switch as MuiSwitch
} from '@mui/material'
import { BaseComponents } from './../../../../components'
import { RowTitle, ComponentRow, Sep } from './helper'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';



const {
    DatePicker,
    ColorPicker
} = BaseComponents


export const PickerRow = props => {
    
    return <>

        <ComponentRow withAccent={true}>
            <RowTitle>Pickers</RowTitle>
            <Sep />
            <ColorPicker />
            <Sep />
            <DatePicker />
        </ComponentRow>




    
    </>

}