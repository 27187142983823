import { BaseComponents } from './../../components'

import { Navbar } from './components/navbar'
const {
    FBox
} = BaseComponents


export const FoodPage = () => {
    return <FBox>
        <Navbar />
    </FBox>
}