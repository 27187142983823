import { useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Components } from './../../../components'
import { API } from './../../../api'
import { useDispatcher, useSelector, TYPES } from './../../../store/dispatcher'
// import { ConfirmationModal } from './ConfirmationModal'
const {
    Box, 
    FBox,
    Button,
    Typography,
    Checkbox: MuiCheckbox,
    Grid,
    alpha,
    CountrySelect,
    StateSelect,
    BaseSelect,
    Validators,
    Spinners,
    Alert,
    Icons,
    TextField: MuiTextField
} = Components


const { CheckIcon } = Icons
const initialFormData = getInitialState()

const TextField = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        width: '100%'
    }, _sx)

    return <MuiTextField sx={sx} {...other}>
        {children}
    </MuiTextField>
}
const GridRow = props => {
    return <Grid container item sx={{
        width: '100%', 
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'relative',
        px: 2
    }}>
        {props.children}
    </Grid>
}


const Error = ({msg, sx={}}) => {
    if(!msg) { return <></> }
    return <Box 
        component="span" 
        sx={{
            color: theme => theme.palette.error.main,
            position: 'absolute',
            left: theme => theme.spacing(1), 
            bottom: '-.9rem',
            fontSize: '.8rem',
            ...sx
        }}
    >
            {msg}
    </Box> 
}


const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        backgroundColor: 'white',
        padding: '10px 10px'
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

const Checkbox = MuiCheckbox

const rowSX = {
    display: 'flex',
    gap: 1,
    width: '100%',
    alignItems: 'center', 
    justifyContent: 'space-between',
    position: 'relative'
}


export const PaymentForm = () => {
    const [formData, setFormData] = useState(initialFormData)
    // const cart = useSelector(state => state.store.cart)
    const dispatcher = useDispatcher()
    const stripe = useStripe()
    const elements = useElements()
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [errors, setErrors] = useState({})
    const [open, setOpen] = useState(false)
    const handleChange = (evt) => {
        const {name, value} = evt.target 
        
        setFormData({
            ...formData, 
            [name]: value
        })
    }
    const handleToggle = evt => {
        const {name} = evt.target
        setFormData({
            ...formData,
            [name]: evt.target.checked
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length != 0) {
            return
        }
        setOpen(true)
        return
    }

    const submitAfterConfirmation = async () => {
        return "Uncomment below if this extends beyond demo"
            // setProcessing(true);
            
            // // step 1 process the data
            // // .  set the billing/shipping if necessary 
            // // .  set the name if necessary
            // // save data to server so we can send emails, and have shipping address. 

            // const v = Object.assign({}, formData)
            // v.state = v.state 
        
   
            // v.country = v.country.value
            // v.name = formData.name ? formData.name : `${formData.firstName} ${formData.middleInitial} ${formData.lastName}`
            // v.address1_billing = v.billingSameAsShipping ? v.address1 : v.address1_billing
            // v.address2_billing = v.billingSameAsShipping ? v.address2 : v.address2_billing
            // v.city_billing = v.billingSameAsShipping ? v.city : v.city_billing
            // v.zip_billing = v.billingSameAsShipping ? v.zip : v.zip_billing
            // v.state_billing = v.billingSameAsShipping ? v.state : v.state_billing.value
            // v.country_billing = v.billingSameAsShipping ? v.country: v.country_billing.value

            // const customer = {
            //     name: v.name,
            //     email: v.email,
            //     address: {
            //         line1: v.address1,
            //         line2: v.address2,
            //         city: v.city, 
            //         state: v.state, 
            //         postal_code: v.zip, 
            //         country: v.country,
            //         zip: v.zip
            //     }
            // }
            
            // const payload = await stripe.confirmCardPayment(clientSecret, {
            //     payment_method: {
            //         card: elements.getElement(CardElement),
            //         billing_details: {
            //         name: v.name,
            //         email: v.email,
            //         address: {
            //             line1: v.address1_billing,
            //             line2: v.address2_billing,
            //             city: v.city_billing, 
            //             state: v.state_billing, 
            //             postal_code: v.zip_billing, 
            //             country: v.country_billing
            //         }
            //         },
            //     },
            //     shipping: {
            //         name: v.name,
            //         address: {
            //             line1: v.address1,
            //             line2: v.address2,
            //             city: v.city, 
            //             state: v.state, 
            //             postal_code: v.zip, 
            //             country: v.country
            //         }
            //     }
            // });
        
            // if (payload.error) {
            //     setError(`Payment failed ${payload.error.message}`);
            //     setProcessing(false);
            // } else {

            //     // update data - send to server to create the order and update quantities
            //     const data = await API.cart.close_purchased_cart({cartnumber: cart.cartnumber, customer})

            //     setError(null);
            //     setProcessing(false);
            //     setSucceeded(true);

                

            //     dispatcher(TYPES.STORE.UPDATE_CART, {cart: data.cart})

            //     localStorage.setItem('cartnumber', data.cart.cartnumber)
                
                

            // }
            // END UNCOMMENT HERE
    
    }


    const validate = () => {
        let errors = {};

        // required fields
        errors.firstName = Validators.required(formData.firstName);
        errors.lastName = Validators.required(formData.lastName)
        errors.address1 = Validators.required(formData.address1)
        errors.city = Validators.required(formData.city)
        errors.state = Validators.required(formData.state)
        errors.zip = Validators.required(formData.zip)
        if(!formData.billingSameAsShipping) {
            errors.address1_billing = Validators.required(formData.address1_billing)
            errors.city_billing = Validators.required(formData.city_billing)
            errors.state_billing = Validators.required(formData.state_billing)
            errors.zip_billing = Validators.required(formData.zip_billing)
        }

        errors.email = Validators.required(formData.email)
        errors.email = !errors.email ? Validators.email(formData.email) : errors.email
        // errors.password = Validators.minLength(values.password, 6);

        // Remove any empty error messages
        Object.keys(errors).forEach(
            (key) => !errors[key] && delete errors[key]
        );
        return errors;
    };

    useEffect(() => {
        const anon = async () => {
            const data = await API.cart.create_payment_intent()
            
            setClientSecret(data.clientSecret)
        }
        // maybe it will render fine without this
        // anon()
    }, []);

    return <>
        <Grid container sx={{width: '100%', alignItems: 'center', justifyContent: 'space-between', px: 1, mb: 5}} spacing={2}>

            <Grid item size={{xs: 12}}>
                <Typography sx={{fontWeight: 'bold'}}>
                    Customer Information
                </Typography>
            </Grid>
            <Grid item size={{xs: 12, md: 4}}>
                <TextField
                     name={"firstName"}  
                     label="First Name" 
                     value={formData.firstName} 
                     onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.firstName}/>
            </Grid>
            <Grid item size={{xs: 12, md: 4}}>
                <TextField 
                    name={"middleInitial"}  
                    label="MI" 
                    value={formData.middleInitial} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
            </Grid>
            <Grid item size={{xs: 12, md: 4}}>
                <TextField 
                    name={"lastName"} 
                    label="Last Name" 
                    value={formData.lastName} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.lastName}/>
            </Grid>
            <Grid item size={{xs: 12}}>
                    <TextField 
                         name={"name"}  
                         label="Name as-is on card." 
                         value={formData.name} 
                         onChange={evt => handleChange(evt)} 
                         placeholder={`${formData.firstName } ${formData.middleInitial} ${formData.lastName}`}
                    ></TextField>
                    <Error msg={errors.name}/>
            </Grid>
            <Grid item size={{xs: 12}}>
                <TextField  
                    name={'address1'} 
                    label={'Address Line 1'} 
                    placeholder="Street name and number" 
                    value={formData.address1} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.address1}/>
            </Grid>
            <Grid item size={{xs: 12}}>
                <TextField 
                    notRequired={true} 
                    name={'address2'} 
                    label={'Address Line 2'} 
                    placeholder="Apt, Suite, Unit, etc. (Optional)" 
                    value={formData.address2} 
                    onChange={handleChange}
                ></TextField>
            </Grid>

            <Grid item size={{xs: 12, md: 6}}>
                <TextField 
                    name={"city"}  
                    label="City" 
                    value={formData.city} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.city}/>
            </Grid>
            <Grid item size={{xs: 12, md: 6}}>
                <StateSelect 
                    name="state" 
                    label="State" 
                    value={formData.state} 
                    onChange={handleChange}
                ></StateSelect>
                <Error msg={errors.state} sx={{bottom: '-1.3rem'}}/>
            </Grid>
            <Grid item size={{xs: 12, md: 6}}>
                <TextField 
                    name={"zip"}  
                    label="Zip/Postal Code" 
                    value={formData.zip} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.zip}/>
            </Grid>
            <Grid item size={{xs: 12, md: 6}}>
                <CountrySelect 
                    name="country" 
                    label="Country" 
                    onChange={handleChange} 
                    value={formData.country.value}
                />
            </Grid>
            <Grid item size={{xs: 12}}>
                <TextField 
                    name={"email"} 
                    label="Email" 
                    value={formData.email} 
                    onChange={evt => handleChange(evt)}
                ></TextField>
                <Error msg={errors.email}></Error>
            </Grid>
            <Grid item size={{xs: 12}}>
                <Typography sx={{
                    width: '100%', 
                    display: 'block', 
                    textAlign: 'left', 
                    mt: 3
            }}>
                Billing Information
            </Typography>
            </Grid>
            <Grid item size={{xs: 12}}>
                <FBox sx={{justifyContent: 'start'}}>
                    <Checkbox 
                        checked={formData.billingSameAsShipping} 
                        name="billingSameAsShipping" 
                        onChange={evt => handleToggle(evt)}
                    ></Checkbox>
                    <Typography sx={{fontSize: '.8rem'}}>Is the delivery address the same as the shipping address?</Typography>
                </FBox>
            </Grid>
            { !formData.billingSameAsShipping && <>
                <Grid item size={{xs: 12}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                        Delivery Information
                    </Typography>
                </Grid>
                <Grid item size={{xs: 12, md: 4}}>
                    <TextField
                        name={"firstName_billing"}  
                        label="First Name" 
                        value={formData.firstName} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                    <Error msg={errors.firstName}/>
                </Grid>
                <Grid item size={{xs: 12, md: 4}}>
                    <TextField 
                        name={"middleInitial_billing"}  
                        label="MI" 
                        value={formData.middleInitial} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                </Grid>
                <Grid item size={{xs: 12, md: 4}}>
                    <TextField 
                        name={"lastName_billing"} 
                        label="Last Name" 
                        value={formData.lastName} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                    <Error msg={errors.lastName}/>
                </Grid>
                <Grid item size={{xs: 12}}>
                    <TextField  
                        name={'address1_billing'} 
                        label={'Address Line 1'} 
                        placeholder="Street name and number" 
                        value={formData.address1} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                    <Error msg={errors.address1}/>
                </Grid>
                <Grid item size={{xs: 12}}>
                    <TextField 
                        notRequired={true} 
                        name={'address2_billing'} 
                        label={'Address Line 2'} 
                        placeholder="Apt, Suite, Unit, etc. (Optional)" 
                        value={formData.address2} 
                        onChange={handleChange}
                    ></TextField>
                </Grid>

                <Grid item size={{xs: 12, md: 6}}>
                    <TextField 
                        name={"city_billing"}  
                        label="City" 
                        value={formData.city} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                    <Error msg={errors.city}/>
                </Grid>
                <Grid item size={{xs: 12, md: 6}}>
                    <StateSelect 
                        name="state_billing" 
                        label="State" 
                        value={formData.state} 
                        onChange={handleChange}
                    ></StateSelect>
                    <Error msg={errors.state} sx={{bottom: '-1.3rem'}}/>
                </Grid>
                <Grid item size={{xs: 12, md: 6}}>
                    <TextField 
                        name={"zip_billing"}  
                        label="Zip/Postal Code" 
                        value={formData.zip} 
                        onChange={evt => handleChange(evt)}
                    ></TextField>
                    <Error msg={errors.zip}/>
                </Grid>
                <Grid item size={{xs: 12, md: 6}}>
                    <CountrySelect 
                        name="country_billing" 
                        label="Country" 
                        onChange={handleChange} 
                        value={formData.country.value}
                    />
                </Grid>
                <Grid item size={{xs: 12}}>
                    <Typography sx={{
                        width: '100%', 
                        display: 'block', 
                        textAlign: 'left', 
                        mt: 3
                }}>
                    Billing Information
                </Typography>
                </Grid>
                <Grid item size={{xs: 12}}>
                    <FBox sx={{}}>
                        <Checkbox 
                            checked={formData.billingSameAsShipping} 
                            name="billingSameAsShipping" 
                            onChange={evt => handleToggle(evt)}
                        ></Checkbox>
                        <Typography sx={{fontSize: '.8rem'}}>Is the billing address the same as the shipping address?</Typography>
                    </FBox>
                </Grid>
                <Grid item size={{xs: 12}}>
                    <Button variant="contained" sx={{width: '100%'}} >Pay Now!</Button>
                </Grid>

            </>
            }
            


        </Grid>
    </>
}



function getInitialState() {

    return {
        cardNumber: '',
        email: '',
        cvv: '',
        name: '',
        expiration: '',
        firstName: '',
        middleInitial: '',
        lastName: '', 
        address1: '',
        address2: '',
        city: '', 
        state: '',
        zip: '',
        country: { "text": "United States", "value": "US" },
        address1_billing: '',
        address2_billing: '',
        city_billing: '', 
        state_billing: '',
        zip_billing: '',
        country_billing: { "text": "United States", "value": "US" },
        billingSameAsShipping: true, 
        saveInformation: false
    }
}
