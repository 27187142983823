

import { BaseComponents } from './../../../../components'
import { Assets } from './../../assets'
const {
    Box,
    FBox,
    Typography,
    alpha,
    Grid
} = BaseComponents

const ChefImage = props => {
    const sizes = {
        xs: 175,
        sm: 175,
        md: 200,
        lg: 250,
        xl: 250
    }
    return <Box sx={{
        position: 'relative', 
        width: {...sizes}, 
        height: {...sizes},
        minWidth: 100,
        minHeight: 100
    }}>
        <Box component="img" src={Assets.logos.ChefLogoBlack} alt="chef-logo"
            sx={{
                objectFit: 'contain',
                width: '100%',
                height: '100%'
            }}
    ></Box>
</Box>
}


export const Hero = props => {
    return <FBox sx={{gap: 2, width: '100%', flexDirection: {xs: 'column', sm: 'row'}}}>
        <ChefImage />
        <FBox sx={{flexDirection: 'column', justifyContent: 'start', alignItems: {xs: 'center', sm: 'end'}}} >
            <Typography sx={t => ({
                ...t.typography.logo,
                whiteSpace: 'nowrap',
                fontSize: {
                    xs: 48,
                    sm: 48,
                    md: 52,
                    lg: 68,
                    xl: 68
                }
            })}>Lorenzo's Pizzeria</Typography>
            <FBox 
                className="contactDetails"
                sx={{
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    alignItems: {xs: 'center', sm: 'end'},
                    fontStyle: 'italic',
                    fontSize: '.9rem',
                    color: t => alpha(t.palette.text.main, .6),
                    whiteSpace: 'nowrap'
                }}
            >
                <Typography sx={{fontSize: {xs: '.7rem'}}}> 742 Evergreen Terrace, Springfield</Typography>
                <Typography> 555-555-5555</Typography>
            </FBox>
        </FBox>
    </FBox>
}