import { useTheme } from '@mui/material';
const body = 'Merriweather'
const header = 'Poppins'
const accent = 'Indie Flower'
const typography = {


  header: {
    fontFamily: header,
    fontSize: '2rem',
    fontWeight: 'bold'
  },


  logo: {
      fontFamily: '"Jim Nightshade", serif',
      fontWeight: 'bold',
      fontSize: '1.3rem'
  },
  body1: {
    fontSize: '1rem',
    fontFamily: body,
  },
  error: {
    fontSize: '.8rem',
    fontStyle: 'italic'
  },
  small: {
    fontSize: '.9rem',
    fontFamily: body
  },
  button: {
    textTransform: "none",
    fontFamily: body
  },
  navbar: {
    fontFamily: header
  },
  accent: {
    fontFamily: accent
  },
  // my variants
  productTitle: {
    fontWeight: 'bold',
    fontFamily: header,
    fontSize: '1.0rem'
  },
  productTitleSmall: {
    fontWeight: 'bold',
    fontFamily: header,
    fontSize: '1.0rem'
  },
  productDescription: {
    fontFamily: 'Noto Sans',
    display: 'block',
    fontSize: '.8rem',
    color: '#555',
    lineHeight: 1.3,
    margin: 0,
    padding: 0

  },
  smallTitle: {
    fontWeight: 'bold', 
    fontSize: '1.1rem',
    fontStyle: 'italic'
  },
  seemore: {
    fontSize: '.8rem', 
    fontFamily: 'Cabin'
  },
  sold: {
    fontSize: '1.1rem', 
    fontWeight: 'bold', 
    fontFamily: header
  },
  productPrice: {},
  loadMessages: {
    fontSize: '.9rem', 
    fontFamily: 'body', 
    opacity: .7
  },




};

export default typography;