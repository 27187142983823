
import { BaseComponents } from './../../../../components'
import { nav_items } from './navitems'


const {
    FBox,
    Box,
    Typography
} = BaseComponents



const NavItem = props => {
    const {
        name, 
        label
    } = props
    
    return <FBox>
        {label}
    </FBox>
}

export const Navbar = props => {
    return <FBox sx={{width: '100%', gap: 2}}>
        {
            nav_items.map(ni => <NavItem key={ni.name} {...ni}></NavItem>)
        }
    </FBox>
}