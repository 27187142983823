
export const menu_items = [
        {
            name: 'monalisa',
            label: 'Mona Lisa',
            description: 'Pepperoni, Sausage, Green Peppers, Mushroom, Red Onion, Black Olive',
            price: 29.00
        },
        {
            name: 'michelangelo', 
            label: 'Michelangelo', 
            description: 'Spinach, Tomato, Mushroom, Green Peppers, Black Olive',
            price: 27.00
        },
        {
            name: 'capone', 
            label: 'Capone', 
            description: 'Pepperoni, Sausage, Meatball', 
            price: 26.00
        },
        {
            name: 'leonardo', 
            label: 'Leonardo', 
            description: 'Mozzarella, Parmesan, Pesto (No Sauce)', 
            price: 24.00
        },
        {
            name: 'margherita',
            label: 'Margherita',
            description: 'Tomato, Basil, Garlic', 
            price: 24.00
        },
        {
            name: 'frenchie',
            label: 'Frenchie',
            description: 'Ham, Pear, Caramelized Onion, Blue Cheese', 
            price: 28.00
        }
    ]



