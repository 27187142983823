
import { Components } from './../../../../components'
import { items } from './items'
import { Assets } from './../assets'
import {
    MenuContainer,
    MenuSection,
    HeaderText,
    SubheaderText,
    Text,
    AccentText,
    TinyFont,
    BBox,
    Logo,
    MenuCategorySection
} from './components'
import { MenuHeader } from './MenuHeader'
const {
    Box,
    FBox,
    Grid,
    Typography
} = Components



const Filler = props => {
    return <Box sx={{width: 100, height: 500, background: props.fill ? props.fill : 'black'}}></Box>
}




export const Menu = props => {
    return <MenuContainer>

        {/* mainheader */}
        <MenuSection borders={[0,0,0,0]} order={{xs: -2, md: -1}} >
            <MenuHeader />
        </MenuSection>

        {/* appetizers */}
        <MenuSection borders={[0,1,0,0]} order={{xs: -1, md:-2}}>
            <MenuCategorySection title={"appetizers and starters" } items={items['appetizers & starters']} />
            <MenuCategorySection title={"soups & salads" } items={items['soups & salads']} />
        </MenuSection>

        {/* main course */}
        <MenuSection borders={[0,0,0,1]} >
            <MenuCategorySection title={"specialties of the house" } items={items['specialties of the house']} />
            <MenuCategorySection title={"sides" } items={items['sides']} />

        </MenuSection>
    </MenuContainer>
}

// const AddressBox = props => {
//     return <FBox sx={{flexDirection: 'column', '&, & *': {color: t => t.palette.text.main, fontStyle: 'italic', fontSize: '.9rem'}}}>
//         <Typography sx={{}}>742 Evergreen Terrace, Springfield. USA</Typography>
//         <Typography sx={{}}>Hours of Operation: 12-11pm</Typography>
//         <Typography sx={{}}>(555) 555 5555</Typography>
//     </FBox>
// }
// const Title = props => {

//     return <FBox sx={{flexDirection: 'column', alignItems: 'start', py: 2}}>
//         <FBox sx={{gap: 2, justifyContent: 'start'}}>
//             <Typography sx={{
//                 color: t => t.palette.secondary.main,
//                 fontSize: '3rem',
//                 fontStyle: 'italic',
//                 fontWeight: 'bold'
//             }}>{props.title} </Typography>
//             <Typography sx={{color: t => t.palette.text.mute}}>{props.subtitle}</Typography>

//         </FBox>
//         {props.description && <Typography sx={{color: t => t.palette.text.mute}}>{props.description}</Typography>}
//     </FBox>

// }
// const StartersColumn = props => {

//     return <FBox sx={{width: '100%', flexDirection: 'column'}}>
//         {
//             items.starters.map(si => {
//                 const descriptors = si.descriptors ? si.descriptors : []
//                 const description = si.description ? si.description : ''
//                 return <FBox sx={{justifyContent: 'start', width: '100%', gap: 2, mt: 2}}>
//                     <Box>
//                         <Box sx={{
//                             width: 30, 
//                             height: 30, 
//                             background: t => t.palette.secondary.light,
//                             borderRadius: '50%'
//                         }}></Box>

//                     </Box>
//                     <FBox sx={{flexDirection: 'column', alignItems: 'start', width: '100%', }}>
//                         <FBox sx={{
//                             justifyContent: 'start',
//                             width: '100%',
  
//                             gap: 1
//                         }}>
//                             <Typography sx={{
//                                 fontStyle: 'italic', 
//                                 color: t => t.palette.text.main,
//                                 fontSize: '1.2rem',
//                                 fontWeight: 'bold',
//                                 whitespace: 'nowrap'
//                             }}>{si.name}</Typography>
//                             <FBox sx={{gap: 1}}>
//                                 {
//                                     descriptors.map(di => {
                                        
//                                         return <img src={Assets[di]} alt={di}></img>
//                                     })
//                                 }   
//                             </FBox>
//                             <Typography sx={{
//                                 fontWeight: 'bold', 
//                                 fontSize: '1.1rem', 
//                                  ml: 'auto',
//                                 color: t => t.palette.text.main
//                             }}>{si.price}</Typography>
//                         </FBox>
//                         <Typography sx={{color: t => t.palette.text.mute, fontSize: '.85rem'}}>{si.description}</Typography>
//                     </FBox>
//                 </FBox>
//             })
//         }

//         {/* <Box sx={{width: '100%', position: 'relative', mt: 4}}>
//             <Box sx={{width: '100%', height: 'auto', objectFit: 'contain'}} component="img" src={Assets.sushi}>

//             </Box>

//         </Box> */}

//         </FBox>
// }
// const PlattersColumn = props => {

//     return <FBox sx={{width: '100%', flexDirection: 'column'}}>
//         {
//             items.platters.map(si => {
//                 const descriptors = si.descriptors ? si.descriptors : []
//                 const description = si.description ? si.description : ''
//                 return <FBox sx={{justifyContent: 'start', width: '100%', gap: 2, mt: 2}}>

//                     <FBox sx={{flexDirection: 'column', alignItems: 'start', width: '100%', }}>
//                         <FBox sx={{
//                             justifyContent: 'start',
//                             width: '100%',
  
//                             gap: 1
//                         }}>
//                             <Typography sx={{
//                                 fontStyle: 'italic', 
//                                 color: t => t.palette.text.main,
//                                 fontSize: '1.2rem',
//                                 fontWeight: 'bold',
//                                 whitespace: 'nowrap'
//                             }}>{si.name}</Typography>
//                             <FBox sx={{gap: 1}}>
//                                 {
//                                     descriptors.map(di => {
                                        
//                                         return <img src={Assets[di]} alt={di}></img>
//                                     })
//                                 }   
//                             </FBox>
//                             <Typography sx={{
//                                 fontWeight: 'bold', 
//                                 fontSize: '1.1rem', 
//                                 color: t => t.palette.text.main,
//                                 ml: 'auto'
//                             }}>{si.price}</Typography>
//                         </FBox>
//                         <Typography sx={{color: t => t.palette.text.mute, fontSize: '.85rem'}}>{si.description}</Typography>
//                     </FBox>

//                 </FBox>
//             })
//         }
//                 <Box component="img" src={Assets.platter} alt="platter" sx={{
//                     my: 3,
//                     objectFit: 'contain'
//                 }}></Box>
//             <Box sx={{mt: 'auto', width: '100%'}}>
//                 <Legend />

//             </Box>
//     </FBox>
// }
// const DishesColumn = props => {

//     return <FBox sx={{width: '100%', flexDirection: 'column'}}>
//         {
//             items.dishes.map(si => {
//                 const descriptors = si.descriptors ? si.descriptors : []
//                 const description = si.description ? si.description : ''
//                 return <FBox sx={{justifyContent: 'start', width: '100%', gap: 2, mt: 2}}>

//                     <FBox sx={{flexDirection: 'column', alignItems: 'start', width: '100%', }}>
//                         <FBox sx={{
//                             justifyContent: 'start',
//                             width: '100%',
  
//                             gap: 1
//                         }}>
//                             <Typography sx={{
//                                 fontStyle: 'italic', 
//                                 color: t => t.palette.text.main,
//                                 fontSize: '1.2rem',
//                                 fontWeight: 'bold',
//                                 whitespace: 'nowrap'
//                             }}>{si.name}</Typography>
//                             <FBox sx={{gap: 1}}>
//                                 {
//                                     descriptors.map(di => {
                                        
//                                         return <img src={Assets[di]} alt={di}></img>
//                                     })
//                                 }   
//                             </FBox>
//                             <Typography sx={{
//                                 fontWeight: 'bold', 
//                                 fontSize: '1.1rem', 
//                                 color: t => t.palette.text.main,
//                                 ml: 'auto'
//                             }}>{si.price}</Typography>
//                         </FBox>
//                         <Typography sx={{color: t => t.palette.text.mute, fontSize: '.85rem'}}>{si.description}</Typography>
//                     </FBox>
//                 </FBox>
//             })
//         }
//     </FBox>
// }

// const Legend = props => {
//     const labels = {'veg': 'Vegetarian', 'gf': 'Gluten-Free', 'spicy': 'Spicy', 'fish': 'Raw fish'}
    
//     const Label = props => {
        
//         return <FBox sx={{
//             width: '50%',
//             gap: 1,
//             justifyContent: props.justifyContent ? props.justifyContent : 'start'
//         }}>
//             <Typography sx={{
//                 color: t => t.palette.text.main, 
//                 whitespace: 'nowrap'
//             }}>{labels[props.label]}</Typography>
//             <Box component="img" src={Assets[props.label]} alt={props.label} sx={{
//                 width: 30,
//                 height: 'auto'
//             }} /> 
//         </FBox>
//     }
//     return <FBox sx={{
//         width: '100%',
//         gap: 2,
//         position: 'relative',
//         flexDirection: 'column',
//         my: 3
//     }}> 
//         <FBox sx={{gap: 2, width: '100%'}}>
//             <Label label="veg" justifyContent="end"/>
//             <Label label="gf" justifyContent="start"/>
//         </FBox>
//         <FBox sx={{gap: 2, width: '100%'}}>
//             <Label label="spicy" justifyContent="end"/>
//             <Label label="fish" justifyContent="start"/>
//         </FBox>



//     </FBox>
    


// }



// export const Menu = props => {
    
//     return <Grid container spacing={3} sx={{
//         width: '100%', 
//         background: t => t.palette.background.menu,
//         p: 3,
//         borderRadius: 4,
//         alignItems: 'stretch',
//         justifyContent: 'space-between',
//         height: '100%'
//     }}>

//         <Grid item size={{xs: 12, md: 4}} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
//             <Box sx={{width: '100%', position: 'relative'}}>
//                 <Box component="img" src={Assets.main} alt="bistro logo" sx={{width: '100%', height: 'auto', objectFit: 'contain'}}/>
//             </Box>
//             <Box sx={{width: '100%', height: 80, background: '#222'}}></Box>
//             <Title title="Starters" subtitle="(Feeds 10)" />
//             <StartersColumn />
//         </Grid>
//         <Grid item size={{xs: 12, md: 4}}  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
//             <Title title="Sushi Platters" />
//             <PlattersColumn />
//             <FBox sx={{}}>

//             </FBox>
//             <AddressBox />
//         </Grid>
//         <Grid item size={{xs: 12, md: 4}} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
//             <Title title="Thai Dishes" subtitle="(Feeds 10)" description="CHOICES OF MEAT - Fried Tofu +20 / Chicken or Pork +20 / Beef, seafood, shrimp, or crispy chicken + 50" />
//             <DishesColumn />
//         </Grid>
//     </Grid>

// }