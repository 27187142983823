import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export const AdminPage = props => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/dashboard/')
    }, [])
    
    return <></>
}