import { BaseComponents } from './../../components/base'
import { Hero, Hero2 } from './hero'
import { Services } from './services'
import { CaseStudies } from './casestudies'
import { WebsitePackageDetails } from './specials'
const {
    Box,
    FBox
} = BaseComponents


export const HomePage = props => {
    return <FBox sx={{
        flexDirection: 'column', 
        p: 0, 
        position: 'relative', 
        overflowX: 'hidden'
    }}>
        <Hero2 /> 
        <Services />
        <WebsitePackageDetails />
    </FBox>
    
}

