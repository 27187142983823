import { useState, useEffect } from 'react'
import { createTheme as createMuiTheme } from "@mui/material/styles";
import { responsiveFontSizes } from '@mui/material';
import palettes from './palettes';
// import typography from './typography';
// import components from './components';

const theme = createMuiTheme()
              
export const useTheme = name => {

    const [palette, setPalette] = useState(palettes[name] ? palettes[name] : theme.palette)
    //const variant = variants.find(vi => vi.name === name)

    
    useEffect(() => {
        setPalette(palettes[name] ? palettes[name] : theme.palette)
    }, [name])
    
     
    return responsiveFontSizes(createMuiTheme({
        palette
    }))
}

export default useTheme;