import { Components } from './../../../components/'
import { PaymentForm } from './PaymentForm'
import { MyCopy } from './MyCopy'
const {
    Box,
    FBox,
    Grid,
    BaseSelect, 
    CountrySelect,
    TextField: MuiTextField,
    Typography,
    alpha
} = Components


const rowSX = {
    display: 'flex',
    gap: 1,
    width: '100%',
    alignItems: 'center', 
    justifyContent: 'space-between',
    position: 'relative'
}

const Title = props => {
    return <Typography sx={{
        fontSize: '2rem',
        color: t => t.palette.primary.main
    }}>{props.children}</Typography>
}


export const CheckoutPage = props => {
    return <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
        <FBox sx={{maxWidth: 650, flexDirection: 'column'}}>
            <Typography sx={{
                fontSize: '2rem',
                fontWeight: 'bold',
                p: 1,
                mt: 5,
                mb: 1,
                color: t => t.palette.primary.main
            }}>Add this form to your website and take online orders today!</Typography>
            <Box sx={{
                width: '100%', 
                height: 2,
                borderRadius: 4,
                mb: 5,
                background: t => alpha(t.palette.primary.main, .6)
            }}></Box>
        </FBox>
        <FBox sx={{width: '100%', maxWidth: 650}}>
            <PaymentForm />
        </FBox>
        <Box sx={{
                width: '100%',
                maxWidth: 650, 
                height: 2,
                borderRadius: 4,
                mb: 5,
                background: t => alpha(t.palette.primary.main, .6)
            }}></Box> 
        <FBox sx={{
            width: '100%',
            maxWidth: 650,
            flexDirection: 'column',
            alignItems: 'start',
            p: 1

        }}>
            <MyCopy />
        </FBox>

    </Box>
}