import { useEffect, useState, useRef } from 'react'
import { d3 } from './../../d3'
import { BaseComponents } from './../../../../components'

const {
    FBox,
    Box,
    Typography
} = BaseComponents


const Label = ({label, value, color}) => {
    return <FBox sx={{gap: 1, justifyContent: 'start'}}>
        <Box sx={{width: 30, height: 30, borderRadius: '50%', background: color}}></Box>
        <Typography sx={{}}>{label}</Typography>
        <Typography sx={{}}>-</Typography>
        <Typography sx={{fontStyle: 'italic', fontWeight: 'bold'}}>{value}</Typography>
    </FBox>
}
export const usePieChart = props => {
    const svgRef = useRef(null)
    const labelRef = useRef(null)
    const [labels, setLabels] = useState([])
    const {
        width,
        height,
        data,
        rerender,
        margins,
        title,
        backgroundColor="rgba(255,255,255,1)",
        innerBackgroundColor="rgba(255,255,255,.1)",
        color='rgba(0,0,0,1)',
        svgSX={}
    } = props

    const svg_style = Object.assign({}, svgSX)
    let diameter = Math.min(width - margins.left - margins.right, height - margins.top - margins.bottom)

    useEffect(() => {
        if(!data || !data.length) {
            return null
        }

        const svg = d3.select(svgRef.current)
        const g = svg.select('.main')

        const radius = diameter / 2
        const innerRadius = radius - 30
        const outerRadius = radius

        const pie = d3.pie().value(d => d.value).sort(null)
        // Create an arc generator
        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)

        // Create a color scale
        const color = d3.scaleOrdinal(d3.schemeCategory10)
        const _labels = data.map((di, i) => ({color: color(i), label: di.label, value: di.value}))
        setLabels(_labels)
        // set the labels for rendering
        

        // Generate the pie chart
        const arcs = pie(data)
        
        const p = svg.select('.pie-group')
        // const arcGroup = p
        //     .data([data])
        //     .enter()
        //     .append('g')
        //         .attr('label-hook', (di, i) => {
        //             _labels.push({text: di[0], color: color(i)})
        //             console.log('here')
        //             return null
        //         })




        // Bind data to paths (arcs)
        const path = p
            .selectAll('path')
            .data(arcs)

        // Handle existing elements (update)
        path
            .transition()
            .duration(750)
            .attrTween('d', function(d) {
                const interpolate = d3.interpolate(this._current, d)
                this._current = interpolate(0)
                return function(t) {
                    return arc(interpolate(t))
                }
            })
            .attr('fill', (d, i) => color(i))

        // Handle new elements (enter)
        path
            .enter()
            .append('path')
            .each(function(d) { this._current = d }) // store current angles for smooth transition
            .attr('d', arc)
            .attr('fill', (d, i) => color(i))
            .transition()
            .duration(750)
            .attrTween('d', function(d) {
                const interpolate = d3.interpolate(this._current, d)
                this._current = interpolate(0)
                return function(t) {
                    return arc(interpolate(t))
                }
            })

        // Handle removed elements (exit)
        path
            .exit()
            .transition()
            .duration(750)
            .attrTween('d', function(d) {
                const interpolate = d3.interpolate(d, { startAngle: d.endAngle, endAngle: d.endAngle })
                return function(t) {
                    return arc(interpolate(t))
                }
            })
            .remove()

        // append the title
        const _title = svg.select('.title')
        _title.select('*').remove()
        _title.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-weight', 'bold')
            .text(title)

    }, [width, height, data, rerender])

    const Chart =  <Box component="svg"  ref={svgRef} sx={svg_style} width={width} height={height}>
        <rect width={width} height={height} fill={backgroundColor}></rect>
        <g className="main" transform={`translate(${margins.left}, ${margins.top})`}>
            <rect width={diameter} height={diameter} fill={innerBackgroundColor}></rect>
            <g className="title" transform={`translate(${diameter/2}, ${diameter/2})`}></g>
            <g className="pie-group" transform={`translate(${diameter/2}, ${diameter/2})`}></g>
            {/* <g className="x-axis"></g>
            <g className="y-axis"></g> */}
        </g>
    </Box>
    
    const Labels = <FBox ref={labelRef} sx={{flexDirection: 'column', gap: 1, alignItems: 'start'}}>
        <Typography sx={{}}>Legend</Typography>
        {
            labels.map((li, i) => {
                return <Label key={li.label} {...li}></Label>
            })
        }
    </FBox>
    
    return {
        Chart,
        Labels
    }
}