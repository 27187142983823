import { BaseComponents } from './../base'



const {
    FBox,
    Box,
    Typography,
    Grid
} = BaseComponents


export const SplitContent = props => {
    const {renderLeft, renderRight, sx: _sx={}, ...other} = props
    const sx = Object.assign({}, _sx)
    return <Grid container sx={{width: '100%', height: '100%', alignItems: 'stretch'}} alignItems="stretch">
        <Grid item size={{xs: 12, md: 6}}>
            {renderLeft()}
        </Grid>
        <Grid item size={{xs: 12, md: 6}}>
            {renderRight()}
        </Grid>
    </Grid>
}