import { useState } from 'react'

import {
    Box, 
    Typography,
    Checkbox as MuiCheckbox,
    Switch as MuiSwitch
} from '@mui/material'

import { RowTitle, ComponentRow, Sep } from './helper'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import MuiSlider from '@mui/material/Slider';

const Slider = props => {
    
    return <Box sx={{ width: 300 }}> 
        <MuiSlider {...props}/>
    </Box>
}





export const SliderRow = props => {
    
    return <>

        <ComponentRow withAccent={true}>
            <RowTitle>Sliders</RowTitle>
            <Sep />
            <Slider
                size="small"
                defaultValue={70}
                aria-label="Small"
                valueLabelDisplay="auto"
            />
            <Sep />
            <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
            <Sep />
            <Slider
                aria-label="Temperature"
                defaultValue={30}
                // getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                shiftStep={30}
                step={10}
                marks
                min={10}
                max={110}
                />
        </ComponentRow>




    
    </>

}