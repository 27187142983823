/* Render prop to pass the scaled data, axis, etc, leaving the styling on the caller */
import { BaseComponents } from './../../base'

const {
    useWhenBreakpointIs
} = BaseComponents






export const useChartBreakpoints = (width, height) => {
    /* Converts height and width, if they are not already {xs,sm,md,lg,xl} into screen height and width 
        Ensures behavior of screen sizes is handled how mui handles it
    */
   let w = width 
   let h = height
   if(typeof width === 'number') {
        w = {
            xs: width,
            sm: width,
            md: width,
            lg: width,
            xl: width
        }
   }
   // ensure if not all keys are set, it is priortized small to large (so medium will be sm, not lg)
   // 1: find the lowest set height - default to 400
   // 2: set each value
   let wlowest = w.xs ? w.xs : w.sm ? w.sm : w.md ? w.md : w.lg ? w.lg : w.xl ? w.xl : 400
   w.xs = wlowest
   wlowest = w.sm ? w.sm : wlowest
   w.sm = wlowest
   wlowest = w.md ? w.md : wlowest
   w.md = wlowest
   wlowest = w.lg ? w.lg : wlowest
   w.lg = wlowest 
   wlowest = w.xl ? w.xl : wlowest
   w.xl = wlowest
   
   // ensure if not all keys are set, it is priortized small to large (so medium will be sm, not lg)
   // 1: find the lowest set height - default to 400
   // 2: set each value
   let hlowest = h.xs ? h.xs : h.sm ? h.sm : h.md ? h.md : h.lg ? h.lg : h.xl ? h.xl : 400
   h.xs = hlowest
   hlowest = h.sm ? h.sm : hlowest
   h.sm = hlowest
   hlowest = h.md ? h.md : hlowest
   h.md = hlowest
   hlowest = h.lg ? h.lg : hlowest
   h.lg = hlowest 
   hlowest = h.xl ? h.xl : hlowest
   h.xl = hlowest

   const final_width = useWhenBreakpointIs(w)
   const final_height = useWhenBreakpointIs(h)

   return {
        width: final_width,
        height: final_height
    }

}


export { ChartWrapper } from './ChartWrapper'