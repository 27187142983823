
import { contact } from './contact'

const prepend_base_to_url = (url) => {
    return `${process.env.REACT_APP_BASE_URL}${url}`
}

export const API = {
    contact
}

export default API