import { useState } from 'react';

export function useSlideDeck(items, initialItem = null) {
  const [currentIndex, setCurrentIndex] = useState(() => 
    items.indexOf(initialItem) !== -1 ? items.indexOf(initialItem) : 0
  );

  // Get the current item based on the current index
  const currentItem = items[currentIndex];

  // Function to update the current item by index
  const setCurrentItem = (newItem) => {
    const newIndex = items.indexOf(newItem);
    if (newIndex !== -1) {
      setCurrentIndex(newIndex);
    }
  };

  // Optional: Function to go to the next item in the list
  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  // Optional: Function to go to the previous item in the list
  const prevItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return {
    currentItem,
    setCurrentItem,
    nextItem,
    prevItem
  };
}
