

import { Components } from './../../../components'

const {
    FBox, 
    Box,
    Typography,
    Header1,
    Body1,
    Subtext,
    Icons,
    Assets
} = Components

export const NumberedTitle = props => {
    const { n, text } = props
    return <FBox sx={{gap: 1}}>
        <FBox sx={{width: 30, height: 30, borderRadius: '50%', background: t => t.palette.primary.main, color: t => t.palette.primary.text}}>{n}</FBox>
        <Header1 sx={{whiteSpace: 'nowrap', fontSize: {xs: '1.2rem', sm: '1.5rem', md: '1.8rem'}}}>{text}</Header1>
    </FBox>
}


export const PriceText = props => {
    return <Typography  component="span" sx={{
            color: t => t.palette.primary.main,
            fontStyle: 'italic',
            fontWeight: 'bold'
        }}>{props.children}
    </Typography>
}

export const Price = props => {
    const { price } = props
    return <PriceText>{price}</PriceText>
}
export const Container = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        width: '100%', 
        flexDirection: 'column', 
        maxWidth: 850,
        gap: 1,
        border: '10px solid transparent', 
        p: 2,
        borderImage: `url(${Assets.images.borders.brownBorderHalf}) 30 stretch`,
        width: '100%',
        position: 'relative'
    }, _sx)
    return <FBox sx={sx}>
        {children}
    </FBox>

}

export const ServiceRow = props => {
    const {title, n, description, price, listItems=[], promotionprice=null} = props 

    return <FBox sx={{  
        width: '100%',
        border: '10px solid transparent', 
        p: {xs: 0, md: 2},
        width: '100%',
        flexDirection: {xs: 'column', md: 'row'},
        alignItems: {xs: 'start',  md: 'center'}
    }}>
            <FBox sx={{
                width: {xs: '100%', md: '50%'},
                flexDirection: 'column',
                alignItems: 'start',
                

            }}>
                <NumberedTitle n={n} text={title} />
                <Box sx={{ml: 5, '& *': {
                    fontSize: {xs: '.8rem', sm: '1rem'}
                }}}>
                    <Body1>
                        {description}
                    </Body1>
                    <FBox sx={{flexDirection: 'column', alignItems: 'start', gap: 1}}>
                        {
                            listItems.map(li => {
                                return <FBox key={li} sx={{gap: 1, justifyContent: 'start', '&, & *': {fontSize: '.8rem'}}}>
                                    <Icons.CheckIcon></Icons.CheckIcon>
                                    <Typography sx={{
                                        fontSize: 'bold'
                                    }}>{li}</Typography>
                                </FBox>
                            })

                            
                        }
                    </FBox>
                    </Box>

            </FBox>
            <FBox sx={{width: {xs: '100%', md: '50%'}, justifyContent: {xs: 'start', md: 'center'}, mt: {xs: 3, md: 0}}}>
                <Body1>
                    {price}
                </Body1>

            </FBox>
    </FBox>
}
