import { useState } from 'react'

import {
    Box, 
    Typography,
    Checkbox as MuiCheckbox,
    Switch as MuiSwitch
} from '@mui/material'
import * as React from 'react';
import MuiRadio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RowTitle, ComponentRow, Sep } from './helper'





const Radio = props => {
    const {sx: _sx, ...other} = props
    const sx = Object.assign({
        color: t => t.palette.text.main,
        '&.Mui-checked': {
            color: t => t.palette.primary.main
        }   

    }, _sx)
    return <MuiRadio sx={sx} {...other}></MuiRadio>
}






export default function RadioButtonsGroup() {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        sx={{
            '& .MuiFormControlLabel-root': {
                color: t => t.palette.text.main
            }
        }}

      >
        <FormControlLabel value="female" control={<Radio/>} label="Small" />
        <FormControlLabel value="male" control={<Radio />} label="Medium" />
        <FormControlLabel value="other" control={<Radio />} label="Large" />
      </RadioGroup>
    </FormControl>
  );
}



const Checkbox = props => {
    const {label, ...other} = props
    return <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <MuiCheckbox {...props} />
        <Typography sx={{color: t => t.palette.text.main}}>{label}</Typography>
    
    </Box>
}

const Switch = props => {
    const [checked, setChecked] = useState(true)


    return <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <MuiSwitch checked={checked} onChange={() => setChecked(!checked)}></MuiSwitch>
        <Typography sx={{color: t => t.palette.text.main}}>{checked ? 'Extra-extra cheese' : 'Extra-cheese'}</Typography>
    </Box>
}

export const CheckboxRow = props => {
    
    return <>

        <ComponentRow withAccent={true}>
            <RowTitle>RadioBoxes</RowTitle>
            <Sep />
            <RadioButtonsGroup />
        </ComponentRow>
        <ComponentRow>
            <RowTitle>Checkboxes</RowTitle>
            <Sep />
            <Checkbox defaultChecked={true} label="Pepperoni"/>
            <Checkbox defaultChecked={false} label="Pineapple" />
            <Checkbox disabled={true} label="Pesto (Out of stock)"/>
        </ComponentRow>

        <ComponentRow>
            <RowTitle>Switches</RowTitle>
            <Sep />
            <Switch />
        </ComponentRow>



    
    </>

}