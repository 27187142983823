import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const CollapsibleMenu = props => {
  const { menu_items, activePage, onClick } = props
  const [anchorEl, setAnchorEl] = useState(null);
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (evt, name) => {
    onClick(name)
    setAnchorEl(null);
  };

 
  return (
    <>
      <AppBar position="static" sx={{
        background: t => t.palette.background.main, 
        border: 'none', 
        boxShadow: 'none',
        display: 'flex', 
        alignItems: {
          xs: 'end',
          md: 'center'
        }, 
        justifyContent: 'space-between',
        '&, & *': {
          color: t => t.palette.text.main
        }, 
      }}>
        <Toolbar>
          {isMdScreen ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box display="flex" flexGrow={1}>
              {menu_items.map((item) => (
                <Typography
                  key={item.name}
                  sx={{ 
                    margin: '0 16px', 
                    cursor: 'pointer',
                    color: t => activePage === item.name ? t.palette.primary.main : t.palette.text.main,
                    whiteSpace: 'nowrap',
                    fontSize: {
                        xs: 40,
                        sm: 16,
                        md: 16,
                        lg: 18,
                        xl: 18
                    } 
                }}
                  onClick={evt => handleClose(evt, item.name)}
                >
                  {item.label}
                </Typography>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menu_items.map((item) => (
          <MenuItem key={item.name} onClick={evt => handleClose(evt, item.name)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CollapsibleMenu;