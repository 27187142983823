

import { Select as MuiSelect } from '@mui/material'

export const Select = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
      color: t => t.palette.text.main,
      '& .MuiInputLabel-root': {
        color: 'green', // Default label color
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: t => t.palette.text.main,// Change border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: t => t.palette.secondary.main, // Change border color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: t => t.palette.primary.main, // Change border color when focused
      },
      '&.Mui-focused': {
        color: t => t.palette.primary.main
      }
    }, _sx)
    return <MuiSelect sx={sx} {...other} >{children}</MuiSelect>
}
