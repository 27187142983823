import { useSelector, useDispatcher, TYPES } from './../../../store/dispatcher'
import { BaseComponents } from './../../../components/'

const {
    FBox,
    Box
} = BaseComponents

export const ScrollContent = props => {

    const { width, height, header, footer } = useSelector(state => state.session.screen)
    const { SubHeader, children, Background=null, subheader_height=50 } = props
    return <Box className="product-window" sx={{
        height: height - header - footer - subheader_height, 
        position: 'relative',
        overflow: 'hidden',
        display: 'flex', 
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',

    }}>
        {Background && <>
            {Background}
        </>
        }
        <FBox sx={{ height: subheader_height, width: '100%'}}>
            {SubHeader}
        </FBox>
        <Box sx={{
            width: '100%',
            height: '100%', 
            overflowY: 'auto',
            pt: 1,
            // pl: {
            //     xs: 1,
            //     sm: 2,
            //     md: 3,
            //     lg: 6
            // },
            // pr: {
            //     xs: 1,
            //     sm: 2,
            //     md: 3,
            //     lg: 6
            // },
        }}>
        
            { children }
        </Box>
    </Box>
}
