import * as React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'


const data = [
    { id: 1, name: 'Product A', number_sold: 1500, revenue: 5000, sku: '212342098', sellThrough30: 75.5, sellThrough60: 75.5, sellThrough90: 75.5},
    { id: 2, name: 'Product B', number_sold: 1200, revenue: 4200, sku: '324212342', sellThrough30: 125.5, sellThrough60: 75.5, sellThrough90: 75.5},
    { id: 3, name: 'Product C', number_sold: 1100, revenue: 3900, sku: '654212342', sellThrough30: 110.5, sellThrough60: 75.5, sellThrough90: 75.5},
    { id: 4, name: 'Product D', number_sold: 950, revenue: 3700, sku: '123324212', sellThrough30: 275.5, sellThrough60: 75.5, sellThrough90: 75.5},
    // { id: 5, name: 'Product E', number_sold: 900, revenue: 3200, sku: '245212342'},
    // { id: 6, name: 'Product F', number_sold: 850, revenue: 2900, sku: '634212342'},
    // { id: 7, name: 'Product G', number_sold: 800, revenue: 2700, sku: '657212342'},
    // { id: 8, name: 'Product H', number_sold: 750, revenue: 2600, sku: '123324212'},
    // { id: 9, name: 'Product I', number_sold: 700, revenue: 2400, sku: '634324212'},
    // { id: 10, name: 'Product J', number_sold: 650, revenue: 2300, sku: '444324212'},
]

export default function TopProductsTable() {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Product SKU</TableCell>
                        <TableCell><strong>Product Name</strong></TableCell>
                        <TableCell align="right"><strong># Sold</strong></TableCell>
                        <TableCell align="right"><strong>Revenue</strong></TableCell>
                        <TableCell align="right"><strong>Sell Through - 30 day</strong></TableCell>
                        <TableCell align="right"><strong>Sell Through - 60 day</strong></TableCell>
                        <TableCell align="right"><strong>Sell Through - 90 day</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.sku}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="right">{row.number_sold}</TableCell>
                            <TableCell align="right">${row.revenue.toLocaleString()}</TableCell>
                            <TableCell align="right">${row.sellThrough30.toLocaleString()}</TableCell>
                            <TableCell align="right">${(row.sellThrough60 / 2).toLocaleString()}</TableCell>
                            <TableCell align="right">${(row.sellThrough90 / 3).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export { TopProductsTable }