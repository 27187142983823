import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    screen: {
        width: 0,
        height: 0, 
        header: 0, 
        footer: 0
    },
    theme: 'light',
    rerender: 0
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateScreenDimensions: (state, action) => {

            return {
                ...state,
                rerender: state.rerender + 1,
                screen: {
                    ...action.payload
                }
            }
        },
        updateTheme: (state, action) => {
            return {
                ...state,
                theme: action.payload.theme
            }
        }
    }

})



const { 
    updateScreenDimensions,
    updateTheme
} = sessionSlice.actions

export {
    updateScreenDimensions,
    updateTheme
}
export default sessionSlice.reducer