


import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    current_page: 'lorenzos'
}

// Create the slice
const webdemosSlice = createSlice({
    name: 'webdemos',
    initialState, 
    reducers: {
        updateState: (state, action) => {
            return {
                ...state, 
                ...action.payload
            }
        },
        updateCurrentPage: (state, action) => {
            const {current_page} = action.payload
            return {
                ...state,
                current_page
            }
        }
    }
})




// Export actions
export const { 
    updateState,
    updateCurrentPage
} = webdemosSlice.actions

// Export reducer
export default webdemosSlice.reducer;









