import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { items } from './items'
import { BaseComponents } from './../../../../components/base'
import { useSelector, useDispatcher, TYPES } from './../../../../store/dispatcher'
const {
    Box,
    FBox,
    Typography,
    alpha,
    Icons
} = BaseComponents


const ToggleOpenButton = props => {

    const { sidebarOpen, setSidebarOpen } = props

    const sx = {
        color: t => t.palette.secondary.main,
        cursor: 'pointer'
    }


    const Icon = sidebarOpen ? <Icons.ChevronLeftIcon  /> 
                               : <Icons.ChevronRightIcon  /> 
    return <FBox 
        sx={{
            alignItems: 'center', 
            jusfifyContent: 'start',
            padding: 1,
            gap: 1,
            fontWeight: 'bold',
            color: t => t.palette.secondary.main,
            cursor: 'pointer',
            '&:hover': {
                color: t => alpha(t.palette.secondary.main, .3)
            }

        }}
        onClick={() => setSidebarOpen(!sidebarOpen)}
    >
        {Icon}
    </FBox>
}


const MenuItem = props => {
    const { name, label, Icon, active, link, onClick } = props 



    return <FBox 
        sx={{
            alignItems: 'center', 
            jusfifyContent: 'start',
            padding: 1,
            gap: 1,
            fontWeight: 'bold',
            color: t => active ? t.palette.primary.main : t.palette.text.opposite,
            cursor: 'pointer',
            '&:hover': {
                color: t => alpha(t.palette.text.sidebar, .3)
            }

        }}
        onClick={() => onClick(name, link)}
    >
        <Icon />
        <Typography variant="sidebar" sx={{color: t => active ? t.palette.primary.main : t.palette.text.opposite}}>{label}</Typography>
    </FBox>
}

export const MenuItems = props => {
    const dispatcher = useDispatcher()
    const { sidebarOpen } = useSelector(state => {
        return state.dashboard

    })
    const setSidebarOpen = () => {
        dispatcher(TYPES.DASHBOARD.UPDATE_SIDEBAR_OPEN, {sidebarOpen: !sidebarOpen})
    }
    const [active, setActive] = useState(items.length ? items[0].name : null)
    const navigate = useNavigate()
    
    const onClick = (name, next_link) => {
        setActive(name)
        navigate(next_link)
    }
    const ref = useRef(null)
    useEffect(() => {

        if(!ref || !ref.current) { return }

        ref.current.style.width = sidebarOpen ? '100%' : '35px'

    }, [ref?.current, sidebarOpen])


    return <Box 
        ref={ref} 
        sx={{
            display: 'flex', 
            justifyContent: 'start', 
            flexDirection: 'column', 
            alignItems: 'start', 
            overflow: 'hidden',
            background: t => t.palette.background.sidebar,
            transition: 'width 1s ease',
            height: '100%'
    }}>
        <FBox sx={{flexDirection: 'column', alignItems: 'start', position: 'relative'}}>
            <Box sx={{height: 30}}></Box>
            <ToggleOpenButton sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {
                items.map(item => <MenuItem key={item.name} active={active === item.name} {...item} onClick={onClick} />)
            }

        </FBox>

    </Box>
}