
import PlaceHolder from '@mui/icons-material/HelpCenter';
import BaconIcon from './pizza/bacon.png'
import GarlicIcon from './pizza/garlic.png'
import MushroomIcon from './pizza/mushroom.png'
import OliveIcon from './pizza/olive.png'
import OnionIcon from './pizza/onion.png'
import PepperIcon from './pizza/pepper.png'
import PepperoniIcon from './pizza/pepperoni.png'
import PineappleIcon from './pizza/pineapple.png'
import RedpepperIcon from './pizza/redpepper.png'
import SausageIcon from './pizza/sausage.png'
import CheeseIcon from './pizza/cheese.png'
import CrustIcon from './pizza/crust.png'
import JalapenoIcon from './pizza/jalapeno.png'
import TomatoIcon from './pizza/tomato.png'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { BaseComponents } from './../../../../../components'

const {
    Box,
    FBox
} = BaseComponents

const Icon = ({src}) => {
    return <FBox sx={{width: 60, height: 60}}>
        <Box sx={{width: 50}}>
            <Box component="img" src={src} sx={{position: 'relative', width: 'auto', height: 'auto', objectFit: 'contain'}}></Box>

        </Box>
    </FBox>
}
const MuiIcon = ({Icon}) => {
    return <FBox sx={{width: 60, height: 60}}>
        <Icon sx={{fontSize: 30}} ></Icon>
    </FBox>
}

// make the 3 sizes of crust 
const CrustSmIcon = props => {
    return <FBox sx={{width: 70, height: 70}}>
        <Box sx={{width: 50}}>
            <Box component="img" src={CrustIcon} sx={{position: 'relative', width: '100%', height: 'auto', objectFit: 'contain'}}></Box>
        </Box>
    </FBox>
}
// make the 3 sizes of crust 
const CrustMdIcon = props => {
    return <FBox sx={{width: 70, height: 70}}>
        <Box sx={{width: 60}}>
            <Box component="img" src={CrustIcon} sx={{position: 'relative', width: '100%', height: 'auto', objectFit: 'contain'}}></Box>
        </Box>
    </FBox>
}
// make the 3 sizes of crust 
const CrustLgIcon = props => {
    return <FBox sx={{width: 70, height: 70}}>
        <Box sx={{width: 70, height: 70, position: 'relative'}}>
            <Box component="img" src={CrustIcon} sx={{width: '100%', height: 'auto', objectFit: 'contain'}}></Box>
        </Box>

    </FBox>
}

export const Assets = {
    crust: {
        small:  <CrustSmIcon />,
        medium:  <CrustMdIcon />,
        large:  <CrustLgIcon />
    },
    meat: {
        pepperoni: <Icon src={PepperoniIcon} />,
        sausage: <Icon src={SausageIcon} />,
        bacon: <Icon src={BaconIcon} />,
        cheese: <Icon src={CheeseIcon} />
    }, 
    toppings: {
        garlic: <Icon src={GarlicIcon} />,
        mushroom: <Icon src={MushroomIcon} />,
        olive: <Icon src={OliveIcon} />,
        onion: <Icon src={OnionIcon} />,
        pepper: <Icon src={PepperIcon} />,
        pineapple: <Icon src={PineappleIcon} />,
        redpepper: <Icon src={RedpepperIcon} />,
        jalapeno: <Icon src={JalapenoIcon} />,
        tomato: <Icon src={TomatoIcon} />,
        none: <MuiIcon Icon={CancelPresentationIcon} />

    }
}

// export const Assets = {
//     crust: {
//         small: PlaceHolder,
//         medium: PlaceHolder,
//         large: PlaceHolder
//     },
//     meat: {
//         pepperoni: PlaceHolder,
//         sausage: PlaceHolder,
//         bacon: PlaceHolder,
//         cheese: PlaceHolder
//     },
//     toppings: {
//         olive: PlaceHolder,
//         sausage: PlaceHolder,

//         onion: PlaceHolder,
//         pepper: PlaceHolder,
//         pineapple: PlaceHolder,
//         tomato: PlaceHolder,
//         garlic: PlaceHolder,
//         redpepper: PlaceHolder

//     }
// }