import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Typography } from '@mui/material';

export const ColorPicker = () => {
    const [selectedColor, setSelectedColor] = useState('#000000'); // Default color

    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
    };

    return (
        <Box sx={{ p: 2, display: 'flex', gap: 1 }}>
            <Box>
                <Typography variant="h6" sx={{color: t => t.palette.text.main}}>Select a Color </Typography>
                <SketchPicker color={selectedColor} onChangeComplete={handleColorChange} />

            </Box>
            <Box>
                <Typography variant="h6" sx={{opacity: 0}}>Select a Color {selectedColor}</Typography>
                <Box
                    sx={{
                        mt: 2,
                        width: '100px',
                        height: '100px',
                        backgroundColor: selectedColor,
                        border: t => `2px solid ${t.palette.text.main}`
                    }}
                />
                <Typography sx={{color: selectedColor}}>{selectedColor}</Typography>
            </Box>

        </Box>
    );
};

export default ColorPicker;