import { Components } from './../../../components'
import { NumberedTitle, PriceText, Container, ServiceRow, Price } from './components'
const {
    FBox, 
    Box,
    Typography,
    Header1,
    Body1
} = Components




export const AdminServices = props => {

    return <Container>

        <ServiceRow 
            n={1} 
            title="Centralized Business Management" 
            description="Streamline your daily operations by integrating essential business tasks into a single, 
            cohesive workspace. Our tailored solutions consolidate all your key processes, helping 
            you focus on what matters most—serving your customers."
        />
        <ServiceRow 
            n={2} 
            title="Custom Dashboard Solutions" 
            description="Transform your data into actionable insights with a dashboard custom-designed for your restaurant’s needs. 
            Our dashboard includes five essential components that provide real-time updates and easy access to critical 
            information at a glance. Perfect for managing orders, tracking inventory, or monitoring customer feedback, 
            each dashboard is tailored to match your unique requirements."
        />
        <ServiceRow 
            n={3} 
            title="Dashboard Setup" 
            description="Five-Component Dashboard: $250 setup fee. For additional customization and specialized data processing, reach out for a free quote."
        />


    </Container>


}

