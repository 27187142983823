import { useState, useEffect } from 'react'
import { TextField } from './TextField'
import { Typography, Button, Box, Alert } from '@mui/material'
import { API } from './../../api'

export const ContactPage = props => {
    const [values, setValues] = useState({email: '', feedback: ''})
    const [errors, setErrors] = useState({email: '', feedback: ''})
    const [success, setSuccess] = useState(false)
    const [unsuccess, setUnsuccess] = useState(false)

    const onChange = evt => {
        const {name, value} = evt.target 
        setValues({
            ...values,
            [name]: value
        })
    }
    const onSubmit = async evt => {
        
        const emailError =  /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email) ? null : 'Email is not valid.';

        if(emailError) {
            setErrors({email: emailError})
        } else {
            // submit feedback
            setErrors({email: ''})
            const data = await API.contact(values)

            if(data.success) {
                setSuccess(true)
                setUnsuccess(false)
            } else {
                setUnsuccess(true)
                setSuccess(false)
            }
        }

        
    }
    return <Box sx={{
        width: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        mt: 3
    }}>
        <Box sx={{
            width: {
                sm: 350,
                md: 500,
                lg: 600
            }, 
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'start', 
            gap: 3
        }}>
            
            <Typography variant="h2">Contact</Typography>
            <Typography>Send us an email at <Box component="span" sx={{color: t => t.palette.primary, fontStyle: 'italic'}}>autoscriptac@gmail.com</Box>, or </Typography>
            <TextField name={"email"} value={values.email} label="Your email" onChange={onChange} error={errors.email} fullWidth variant="outlined"></TextField>
            <TextField 
                name={"feedback"} 
                value={values.feedback} 
                label="" 
                placeholder="We value your feedback!"  
                onChange={onChange} 
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                error={errors.feedback}
            ></TextField>
            <Button fullWidth variant="contained" onClick={onSubmit}>Submit</Button>
                { unsuccess &&
                    <Alert size="small" severity="error">Unable to submit the form for feedback. Please try again or send a direct email.</Alert>
                }
                { success &&
                    <Alert size="small" severity="success">Thank you! Your feedback has been submitted. We will review your response and address your concerns as appropriate.</Alert>
                }
        </Box>
    </Box>

}
export default ContactPage;