

import {
    Box, 
    Button as MuiButton,
    Typography,
    IconButton as MuiIconButton
} from '@mui/material'
import { RowTitle, ComponentRow, Sep } from './helper'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ForwardIcon from '@mui/icons-material/FastForward';
import ReverseIcon from '@mui/icons-material/FastRewind';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import CartIcon from '@mui/icons-material/AddShoppingCart';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import BluetoothIcon from '@mui/icons-material/BluetoothConnected';
import WifiIcon from '@mui/icons-material/NetworkWifi3Bar';

const ButtonWithLabel = ({Button, label}) => {
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', justifyContent: 'center'}}>
        {Button}
        <Typography>{label}</Typography>
    </Box>
}

const Button = props => {
    const {children, sx: _sx, ...other} = props
    const sx = Object.assign({
        background: t => t.palette.primary.main,
        color: t => t.palette.primary.text
    }, _sx)

    return <MuiButton sx={sx} {...other}>{children}</MuiButton>
}

const IconButton = props => {
    
    return <MuiIconButton aria-label="delete" sx={{'&, & *': {color: t => t.palette.primary.main}}}>
        {props.Icon}
    </MuiIconButton>
}

export const ButtonRow = props => {
    
    return <>
        <ComponentRow withAccent={true}>
            <RowTitle>Buttons</RowTitle>
            <Sep />
            <ButtonWithLabel 
                Button={<Button variant="text" size="small">Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="outlined" size="small">Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="contained" size="small">Click Me!</Button>}
            />

            <Sep />

            <ButtonWithLabel 
                Button={<Button variant="text" size="medium">Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="outlined" size="medium">Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="contained" size="medium">Click Me!</Button>}
            />
            
            
            <Sep />
            
            
            <ButtonWithLabel 

                Button={<Button variant="text" size="large">Click Me!</Button>}
            />
            <ButtonWithLabel 

                Button={<Button variant="outlined" size="large">Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="contained" size="large">Click Me!</Button>}
            />
        </ComponentRow>
    

        <ComponentRow>
            <RowTitle>Buttons with Icon</RowTitle>
            <Sep />
            <ButtonWithLabel 
                Button={<Button variant="text" size="medium" startIcon={<ReverseIcon />}>Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="outlined" size="medium" startIcon={<ReverseIcon />}>Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="contained" size="medium" startIcon={<ReverseIcon />}>Click Me!</Button>}
            />
            <Sep />
            <ButtonWithLabel 
                Button={<Button variant="text" size="medium" endIcon={<ForwardIcon />}>Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="outlined" size="medium" endIcon={<ForwardIcon />}>Click Me!</Button>}
            />
            <ButtonWithLabel 
                Button={<Button variant="contained" size="medium" endIcon={<ForwardIcon />}>Click Me!</Button>}
            />
        </ComponentRow>


        <ComponentRow>
            <RowTitle>Icon Buttons</RowTitle>
            <Sep />
            <IconButton Icon={<AlarmIcon />} />
            <IconButton Icon={<DeleteIcon />} />
            <IconButton Icon={<CartIcon />} />
            <IconButton Icon={<PlayIcon />} />
            <IconButton Icon={<PauseIcon />} />
            <IconButton Icon={<DownloadIcon />} />
            <IconButton Icon={<CloudDownloadIcon />} />
            <IconButton Icon={<InfoIcon />} />
            <IconButton Icon={<BluetoothIcon />} />
            <IconButton Icon={<WifiIcon />} />




        </ComponentRow>
    </>

}