import { useEffect, useState, useRef } from 'react'
import { d3 } from './../../d3'
import { BaseComponents } from './../../../../components'

const {
    FBox,
    Box
} = BaseComponents


export const useBarChart = props => {
    const svgRef = useRef(null)
    const [initialized, setInitalized] = useState(false)
    const {
        width,
        height,
        data,
        rerender,
        margins,
        charttitle,
        title,
        backgroundColor="rgba(255,255,255,1)",
        innerBackgroundColor="rgba(255,255,255,.1)",
        color='rgba(0,0,0,1)',
        svgSX={}
    } = props
    

    const svg_style = Object.assign({

    }, svgSX)


    useEffect(() => {
        if(!data || !data.length) {
            return null
        }

        const svg = d3.select(svgRef.current)
        const g = svg.select('.main')
        // svg.attr('width', width)
        // svg.attr('height', height)
        // svg.select('*').remove()
        // // append the background
        // svg.enter().append('rect')
        //     .attr('width', width)
        //     .attr('height', height)
        //     .attr('fill', backgroundColor)

        // // add the margins
        // const g = svg.append('g').attr('transform', `translate(${margins.left}, ${margins.top})`)
        // g.append('rect')
        //     .attr('width', width - margins.left - margins.right)
        //     .attr('height', height - margins.top - margins.bottom)
        //     .attr('fill', innerBackgroundColor)

        const xscale = d3.scaleBand()
            .domain(data.map((d) => d.x))
            .range([0, width - margins.left - margins.right])
            .padding(.2)
        
        const yscale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.y) + d3.min(data, d => d.y) / 2])
            .range([height - margins.top - margins.bottom, 0])
    
        // Add the X-axis
        const xaxis = svg
            .select('.x-axis')
            .attr('transform', `translate(0, ${height - margins.top - margins.bottom})`) // Move to bottom of chart
            .call(d3.axisBottom(xscale)) // Call the X axis
            .selectAll('text')
                .attr('transform', 'rotate(-40)') // Rotate the text labels if necessary
                .attr('text-anchor', 'end')
                .attr('color', color)

        // Add the Y-axis
        const yaxis = svg.select('.y-axis').call(d3.axisLeft(yscale)); // Call the Y axis
        
        // add the bars 
        const bars = g.selectAll('.bar').data(data)
        bars
            .transition()
            .duration(750)
            .attr('x', di => xscale(di.x))
            .attr('y', di => yscale(di.y))
            .attr('height', di => yscale(0) - yscale(di.y))
            .attr('width', xscale.bandwidth())
        bars.enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', di => xscale(di.x))
            .attr('y', di => yscale(0))
            .attr('height', 0)
            .attr('width', xscale.bandwidth())
            .transition()
            .duration(750)
            .attr('y', di => yscale(di.y))
            .attr('height', di => yscale(0) - yscale(di.y))

        bars.exit()
            .transition()
            .duration(750)
            .attr('y', yscale(0))
            .attr('height', 0)
            .remove()

        const _title = svg.select('.title')
        _title.select('*').remove()
        _title.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-weight', 'bold')
            .attr('x', (width - margins.left - margins.right) / 2)
            .attr('y', 10)
            .text(title)

    }, [width, height, data, rerender])
    

    
    const Chart = <Box component="svg"  ref={svgRef} sx={svg_style} width={width} height={height}>
        <rect width={width} height={height} fill={backgroundColor}></rect>
        <g className="main" transform={`translate(${margins.left}, ${margins.top})`}>
            <rect width={width-margins.left-margins.right} height={height-margins.top-margins.bottom} fill={innerBackgroundColor}></rect>
            <g className="title"></g>
            <g className="x-axis"></g>
            <g className="y-axis"></g>

        </g>
    </Box>
    
    
    return {
        Chart
    }
}