
import { Components } from './../../../../components'
import { items as allItems } from './items'
const {
    FBox,
    Box,
    useSlideDeck,
    typographyClasses,
    Icons,
    Button,
    Typography
} = Components



const CardHeaderText = ({children, ...other}) => {
    return <Typography sx={{
        fontWeight: 'bold', 
        fontSize: '1.0rem',
        color: t => t.palette.secondary.main


    }} {...other}>{children}</Typography>
}

const CardSubheaderText = ({children, ...other}) => {
    return <Typography sx={{
        fontWeight: 'bold',
        fontSize: '.8rem',
        fontStyle: 'italic',
        maxWidth: 250
    }} {...other}>{children}</Typography>
}

const PriceText = ({children, ...other}) => {
    // I don't like th price format - small hack for this example

    return <Typography sx={{
        fontSize: '.9rem', 
        fontWeight: 'bold',
        width: 80,
        textAlign: 'right'
    }} {...other}>{children}</Typography>
}

const items = (() => {
    return Object.keys(allItems.wine).map((ni, index) => ({
        name: ni,
        value: allItems.wine[ni],
        index: index
    }))

})()

export const WineSlideDeck = props => {
    const { height=200 } = props
    const { 
        currentItem,
        setCurrentItem,
        nextItem,
        prevItem
    } = useSlideDeck(items)

    return <FBox sx={{
        flexDirection: 'column',
        overflow: 'hidden'

    }}>
        <CardHeaderText>{currentItem.name}</CardHeaderText>
        <Box sx={{position: 'relative', overflow: 'hidden', height: height}}>
            <Box sx={{width: '100%', height: '100%', overflowY: 'auto'}}>
                {
                    currentItem.value.map((item) => {
                        let p = item.price 
                        p = p.split('/')
                        p = p[p.length -1]
                        return <FBox sx={{justifyContent: 'space-between', width: '100%', gap: 1}}>
                            <CardSubheaderText>{item.name}</CardSubheaderText>
                            <PriceText>{p}</PriceText>
                        </FBox>
            })

                }

            </Box>

        </Box>
        <FBox sx={{}}>
            <Button onClick={prevItem}>prev</Button>
            <Typography sx={{width: 50}}>{currentItem.index + 1} / {items.length}</Typography>
            <Button onClick={nextItem}>next</Button>

        </FBox>
    </FBox>
}