import { Components } from './../../../components'
import { NumberedTitle, PriceText, Container, ServiceRow, Price } from './components'
const {
    FBox, 
    Box,
    Typography,
    Header1,
    Body1,
    Subtext,
    Icons
} = Components


const P = () => (<></>)
const SpecialPrice = () => {
    return <FBox sx={{flexDirection: 'column'}}>
        <Typography>Starting at <span style={{textDecoration: 'line-through'}}>$349.99</span></Typography>
        <Typography>Special $149.99</Typography>
    </FBox>
}
const AdditionalPagesPrice = () => {
    return <FBox sx={{flexDirection: 'column'}}>
        <Typography>Additional (read only) page - $50.00</Typography>
        <Typography>Additional interactive page (form, user inputs, etc). Starting at $50.00</Typography>
    </FBox>
}

export const WebServices = props => {
    return <Container>


        <ServiceRow 
            n={1}
            title="Custom Menu Design"
            description={`Give your restaurant’s menu the attention it deserves.`
            }
            listItems={[
                "Ensure a cohesive brand experience from table to screen",
                "Effortlessly update prices in real time",
                "Add seasonal specials or introduce limited-time offers with ease",
                "Stay agile and responsive to market changes"
            ]}
            // price="$199.99"
            price={<Price price="Starting at $199.99" />}

        /> 
        <ServiceRow 
            n={2}
            title="Complete Website Package"
            description={`Launch your restaurant’s digital presence with our comprehensive website package.`}
            listItems={[
                "Landing page",
                "Custom Menu",
                "An About Us section",
            ]}
            // price="$349.99"
            price={<SpecialPrice />}

        /> 
        <ServiceRow 
            n={3}
            title="Additional Pages"
            description={`Need more than just the basics?`}
            listItems={[
                'Add additional pages to your site to ensure all of your content is covered.'
            ]}
            // price="$50.00"
            price={<Price price={"starting at $50.00"} />}

        /> 





    </Container>
}


/*
      1) Custom Menu Design
Give your restaurant’s menu the attention it deserves. Our custom-designed digital menus match the unique ambiance of your establishment, ensuring a cohesive brand experience from table to screen. Effortlessly update prices, add seasonal specials, or introduce limited-time offers in real-time, helping you stay agile and responsive to market changes.

Starting at $199.00
2) Complete Website Package
Launch your restaurant’s digital presence with our comprehensive website package. Includes a stunning landing page, an engaging "About Us" section, a captivating photo gallery, and your custom menu. Each feature is designed to highlight your culinary offerings and draw customers in before they even step through the door.

Starting at $349.99
3) Additional Pages
Need more than just the basics? Add informative, read-only pages to showcase more about your restaurant, such as your team, sourcing practices, or community involvement.

Each additional page: $75.00
4) Need Something Extra?
Want to create interactive experiences, like customer surveys or fun contests? If you have a unique vision, we’re here to make it happen! No request is too big or too small—reach out for a free estimate, and let’s bring your ideas to life.

  
*/