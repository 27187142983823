import { Components } from './../../../components'
import { NumberedTitle, PriceText, Container, ServiceRow, Price } from './components'
const {
    FBox, 
    Box,
    Typography,
    Header1,
    Body1
} = Components




export const OrderServices = props => {

    return <Container>
        <ServiceRow 
            n={1} 
            title="Seamless Online Checkout" 
            description="Ready to capture the online market? We’ll integrate a simple, effective checkout form directly into 
            your website so customers can easily place orders. No additional app or complex systems needed—just a 
            streamlined experience that brings your menu to their fingertips."
        />

        <ServiceRow 
            n={2} 
            title="No POS? No Problem" 
            description="If your business doesn’t require the cost and upkeep of a full POS system, our solution lets you accept orders online without the hefty overhead. And unlike many other services, we don’t take a cut of each sale, so you keep more of your revenue.*
            * Standard processing fees by Stripe, PayPal, etc., may still apply."
        />

        <ServiceRow 
            n={3} 
            title="Seamless online checkout" 
            description="Ready to capture the online market? We’ll integrate a simple, effective checkout form directly into your website so customers can easily place orders. No additional app or complex systems needed—just a streamlined experience that brings your menu to their fingertips."
        />

        <ServiceRow 
            n={4} 
            title="Maximize Your Marketing Potential" 
            description="With your online ordering set up and menu ready to go, you can quickly run specials on social media, attract new customers, and capture those scrolling through your latest offers. Take advantage of every promotional opportunity with ease and agility, expanding your reach and revenue."
        />
    </Container>


}

/*
Seamless Online Checkout
Ready to capture the online market? We’ll integrate a simple, effective checkout form directly into your website so customers can easily place orders. No additional app or complex systems needed—just a streamlined experience that brings your menu to their fingertips.




Maximize Your Marketing Potential
*/