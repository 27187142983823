import merge from 'deepmerge'
import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material/';
import { alpha } from '@mui/material/styles'

const theme = createTheme()

const base_theme = merge(theme.palette, {
    background: {
        main: colors.grey[50],
        header: colors.green[800],
        card: colors.grey[100],
        variant1: colors.grey[600],
        variant2: colors.green[50],
        sidebar: '#222'
    },
    text: {
        main: '#222',
        opposite: '#eee',
        sidebar: '#eee'
    },
    dashboard: {
        
    }
})
const light = merge(base_theme, {
    mode: 'light',
    background: {
        main: colors.blue[50]
    },
    primary: {
        main: colors.blue[500],
        text: 'rgba(255,255,255,1)'
    },
    text: {
        main: 'rgb(0,0,0)'
    }
})
const dark = merge(base_theme, {
    mode: 'dark',
    background: {
        main: colors.grey[800]
    },
    primary: {
        main: colors.blue[500],
        text: 'rgba(255,255,255,1)'
    },
    text: {
        main: 'rgb(255,255,255)'
    }
})
const green = merge(base_theme, {
    mode: 'dark',
    
})



export default {
    light,
    dark,
    green
}