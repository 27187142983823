

import { Components } from './../../../components'
import { blog as _blog } from './blog'
import Infographic from './infographic.jpg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const {
    FBox,
    Box,
    Typography: MuiTypography
} = Components


const Typography = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        color: t => t.palette.text.main
    }, _sx)
    return <MuiTypography sx={sx} {...other}>{children}</MuiTypography>
}
const Bold = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: '.9rem'
    }, _sx)
    return <Typography component="span" sx={sx} {...other}>{children}</Typography>
}




const blog = _blog


const AaronLink = props => {
    return <Box>
        Published by 
        <Box sx={{
            color: 'blue', 
            fontStyle: 'italic',
            textDecoration: 'underline'
            
        }} component="a" href="https://aaronallen.com/" target="_blank"> Aaron Allen and Associates </Box>

    </Box>
}
const BlogTitle = props => {
    return <Typography sx={{
        fontSize: '2.4rem', 
        fontWeight: 'bold', 
        mb: 5,
        fontFamily: 'Poppins, sans-serif',
        color: t => t.palette.primary.main
        
    }}>{props.children}</Typography>
}
const Header = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        fontWeight: 'bold',
        mb: 3,
        fontSize: '1.2rem'
    }, _sx)
    return <Typography sx={sx} {...other}>{props.children}</Typography>
}
const Subheader = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        mt: 2,
        mb: 1,
        fontWeight: 'bold'
    }, _sx)
    return <Typography sx={sx} {...other}>{props.children}</Typography>
}
const Text = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        mb: 1
    }, _sx)
    return <Typography sx={sx} {...other}>{props.children}</Typography>
}

const ContentList = ({contentList}) => {
    return <FBox sx={{flexDirection: 'column', alignItems: 'start', justifyContent: 'start', mt: 1}}>
        {
            contentList.map(ci => {

                return <FBox key={ci} sx={{gap: 1, ml: 4, '&, & *': {fontSize: '.9rem'}}} >
                    <CheckCircleIcon sx={{color: t => t.palette.primary.main}}/> 
                    <Text>{ci}</Text>
                </FBox>
            })
        }
    </FBox>
}

const ContentListItems = ({contentList}) => {

    return <Box component="table" sx={{'&, & *': {fontSize: '.9rem'}}}>
        <Box component="tbody">
            {
                contentList.map(ci => {

                    return <Box key={ci.title} component="tr">
                        <Box component="td" sx={{fontWeight: 'bold'}}>{ci.type}</Box>
                        <Box component="td">{ci.description}</Box>
                    </Box>
                })
            }
        </Box>
    </Box>
}

const Substeps = ({substeps}) => {
    
    const Item = ({substep_title, content}) => <FBox sx={{gap: 1}}>
        <Text sx={{fontWeight: 'bold'}}>{substep_title}</Text>
        <Text sx={{}}>{content}</Text>
    </FBox>
    return <FBox sx={{flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
        {
            substeps.map(ci => {

                return <Item key={ci.title} {...ci} />
            })
        }
    </FBox>
}


const Subsection = props => {
    const {title, content, contentList, contentListItems, substeps} = props

    return <FBox sx={{flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
        <Subheader>{title}</Subheader>
        { content && <Text>{content}</Text> }
        { contentList && <ContentList contentList={contentList} />}
        { substeps && <Substeps substeps={substeps} />}
        { contentListItems && <ContentListItems contentList={contentListItems} />}

        <Text>{

        }</Text>
    </FBox>
    
}
const Section = props => {
    const { title, content, subsections: _subsections } = props
    const subsections = _subsections ? _subsections : []
    return <FBox sx={{width: '100%', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', mb: 3}}>
        <Header>{title}</Header>
        <Text>{content}</Text>
        { 
            subsections.map((si, i) => {
                return <Subsection key={`${si.title}-${i}`} {...si}></Subsection>

            })
        }
    </FBox>
}

const Figure = props => {



    return <FBox sx={{width: '100%', flexDirection: 'column', mt: 2, mb: 4}}>
        <Box sx={{width: '100%', position: 'relative'}}>
            <Box component="img" src={Infographic} alt="Menu Design Infographic" sx={{
                width: '100%', height: 'auto', objectFit: 'contain'
            }}/>

        </Box>
        <AaronLink />
    </FBox>
}


const Introduction = () => {
    return <FBox sx={{width: '100%', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', mb: 3}}>
        <Text>
            Creating your first restaurant menu is a big step. A menu must be designed to match a restaurants timbre, ambience, and overall atmosphere. 
            Simultaneously, a menu must be optimized to maximize a restaurants earnings. A restaurant with a poorly designed, unappealing, or cookie cutter menu will lose sales.
            <Bold> Period. </Bold> 
            The loss can be substantial, especially over the restaurants lifetime. If your menu is not following the basic principles of menu optimization your restaurant could be losing out on sales.
        </Text>
        <Figure />
        <Text>
            But do not be overwhelmed. This guide will highlight the important considerations in getting your restaurant to its full potential.
        </Text>
    </FBox>
}

export const BlogPage = props => {
    const {
        introduction,
        section1,
        section2,
        section3,
        section4
    } = blog.sections

    return <FBox sx={{
        flexDirection: 'column', 
        background: t => t.palette.background.main, 
        minHeight: '100%',
    }}>
        <FBox sx={{width: '100%', maxWidth: 650, flexDirection: 'column', alignItems: 'start', mt:5}}>
            <BlogTitle >{blog.title}</BlogTitle>
            <Introduction />
            <Section {...section1} />
            <Section {...section2} />
            <Section {...section3} />
            <Section {...section4} />

        </FBox>
        
    </FBox> 
}