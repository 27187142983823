import { BaseComponents } from './../../../../components/base'
const {
    Icons
} = BaseComponents


export const items = [
    {
        name: 'charts', 
        label: 'Charts', 
        Icon: Icons.BarChartIcon,
        link: '/dashboard/'
    },
    {
        name: 'components', 
        label: 'Components', 
        Icon: Icons.ComponentsIcon,
        link: '/dashboard/components/'
    },
    // {
    //     name: 'landingpages',
    //     label: 'Landing Pages',
    //     Icon: Icons.LandingPagesIcon,
    //     link: '/dashboard/landingpages/'   
    // }
]