/*
Sets up the nav/footer with scrollable inside content and manages window dimensions
Queries for the following initialization data:
*/

import { useState, useEffect } from 'react'
import {
    Box,
    Typography
} from '@mui/material'
import { alpha } from '@mui/material'
import { Outlet } from 'react-router-dom';
import { useDispatcher, useSelector, TYPES } from './../../store/dispatcher'
import { Header } from './../../components/header'
// import { DataLoader } from './DataLoader'


const getLengths = () => {
    // return the lengths of the screen and main components
    const win = window
    const doc = document
    const docElem = doc.documentElement
    const body = doc.getElementsByTagName('body')[0]
    const width = win.innerWidth || docElem.clientWidth || body.clientWidth
    const height = win.innerHeight|| docElem.clientHeight|| body.clientHeight

    const pad = 10
    let header = doc.getElementById('header')
    header = header ? header.getBoundingClientRect().height: 0


    //let footer = 
    let footer = 30

    return {
        width, 
        height,
        header, 
        footer
    }
}

const BrowserWatcher = props => {
    const dispatcher = useDispatcher()
    const [lastUpdate, setLastUpdate] = useState(null)
    const { triggerUpdate } = props
    const updateScreen = () => {
        const { width, height, header, footer } = getLengths()
        dispatcher(TYPES.SESSION.UPDATE_SCREEN_DIMENSIONS, {width, height, header, footer})
    }


    useEffect(() => {
        updateScreen()
        window.onresize = () => {
            updateScreen()

        }
        setTimeout(() => updateScreen(), 750)
        return () => window.resize = null
    }, [])

    useEffect(() => {
        updateScreen()
    }, [triggerUpdate])



    
    return <></>

}


export const BasePage = props => {
    const { width, height, header, footer, SubHeader } = useSelector(state => state.session.screen)
    const padding = 2
    const [triggerUpdate, setTriggerUpdate] = useState(0)
    return <>
        <BrowserWatcher triggerUpdate={triggerUpdate}/>
        {/* <DataLoader onSuccess={() => setTriggerUpdate(triggerUpdate + 1)} /> */}

        <Box className="main-page" sx={{
            width: '100%',
            padding: 0, 
            margin: 0, 
            height: height,
            background: t => t.palette.background.main,
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center'
        }}>

            <Box sx={{width: '100%'}}>
                <Box id="header" sx={{color: '#eee',  width: '100%'}}>
                    <Header/>
                </Box>
                {SubHeader}
                <Box className="product-window" sx={{
                    height: height - header - footer, 
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex', 
                    justifyContent: 'center'
                }}>
                    
                    <Box sx={{
                        width: '100%',
                        height: '100%', 
                        overflowY: 'auto',
                        pt: 1,
                        // pl: {
                        //     xs: 1,
                        //     sm: 2,
                        //     md: 3,
                        //     lg: 6
                        // },
                        // pr: {
                        //     xs: 1,
                        //     sm: 2,
                        //     md: 3,
                        //     lg: 6
                        // },
                    }}>
                        <Outlet />

                    </Box>
                </Box>
                <Box id="footer" sx={{
                    height: footer,
                    display: 'flex', 
                    background: t => t.palette.background.footer,
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    fontSize: '.8rem',
                    gap: 1,
                    fontStyle: 'italic',
                    position: 'relative'
                }}>
                    <Box sx={{position: 'absolute', content: '""', width: '100%', height: 3, background: t => t.palette.primary.main, top: 0, left: 0}}></Box>
                    AutoScript Services AC - 2024
                </Box>

            </Box>
        </Box>
    </>

}


