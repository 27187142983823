import { useState, useEffect } from 'react'
import { createTheme as createMuiTheme } from "@mui/material/styles";
import { responsiveFontSizes } from '@mui/material';
import palettes from './palettes';
import typographies from './typography';
// import components from './components';

const theme = createMuiTheme()
              
export const useTheme = name => {

    const [palette, setPalette] = useState(palettes[name] ? palettes[name] : theme.palette)
    const [typography, setTypography] = useState(typographies.lorenzos)
    //const variant = variants.find(vi => vi.name === name)


    useEffect(() => {
        setPalette(palettes[name] ? palettes[name] : theme.palette)
        // setTypography(typographies[name] ? typographies[name] : theme.typography)
    }, [name])
    
     
    return responsiveFontSizes(createMuiTheme({
        palette,
        typography
    }))
}

export default useTheme;