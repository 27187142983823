import { Components } from './../../../components'
import { Menu } from './menu'
const {
    FBox,
    Box,
    Typography
} = Components


export const Tommys = props => {

    return <FBox sx={{
        flexDirection: 'column', 
        background: t => t.palette.background.main, 
        justifyContent: 'start',
        minHeight: '100%',
        p: 5
    }}>
        <Menu />
    </FBox> 
}