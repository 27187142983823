
import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise = null
export const getStripe = () => {

  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
  }
  return stripePromise;
};
