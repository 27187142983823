// Make the menu components 
import { Components } from './../../../../components'
import { items } from './items'
import { Assets } from './../assets'



const {
    Box,
    FBox,
    Grid,
    Typography
} = Components


/* 
Make the following styles for the menu 
logo
box with border - think we can do with just css

text:
header
subheader
description
*/ 


export const Logo = () => {
    return <Box sx={{width: '100%', position: 'relative'}}>
        <Box component="img" src={Assets.logo} alt="Main Logo" sx={{width: '100%', height: 'auto', objectFit: 'contain'}} />
    </Box>
}

export const HeaderText = props => {

    return <FBox sx={{
        py: 1, 
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 1

    }}>
        <Typography sx={{
            color: t => t.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.2rem',
            whitespace: 'nowrap'
        }}>{props.children}</Typography>
        <Box sx={{width: '90%', height: 2, background: t => t.palette.primary.main}}></Box>
    </FBox>

}
export const SubheaderText = props => {
    return <Typography sx={{
        fontWeight: 'bold',
        textAlign: 'center',
        whitespace: 'nowrap',
        fontSize: '.9rem'
    }}>{props.children}</Typography>
}
export const Text = props => {
    return <Typography sx={{
        fontSize: '.8rem',
        textAlign: 'center',
        maxWidth: '90%'
    }}>{props.children}</Typography>
}
export const AccentText = props => {}
export const TinyFont = props => {}


export const BBox = props => {
    // get a box with a customized border - could use image for more dynamic but using css this tim
    const {
        children, 
        borders=[0,0,0,0],
        sx: _sx={}, 
        ...other
    } = props
    const sx = Object.assign({
        position: 'relative'
    }, _sx)
    const bleft = borders[3] ? true : false
    const btop = borders[0] ? true : false
    const bright = borders[1] ? true : false
    const bbottom = borders[2] ? true : false
    
    const VerticalStripe = props => {
        const { isLeft } = props
        return <Box sx={{
            width: 10, 
            height: '100%',
            position: 'absolute',
            display: 'flex',
            gap: 1, 
            justifyContent: 'space-between', 
            flexDirection: 'column', 
            alignItems: 'center',
            top: 0,
            left: isLeft ? 0 : null,
            right: isLeft ? null : 0
        }}>
            <Box sx={{background: t => t.palette.primary.main, height: '40%', width: '100%'}}></Box>
            <Box sx={{height: 20, width: 20, transform: 'rotate(45deg)', background: t => t.palette.primary.main}}></Box>
            <Box sx={{background: t => t.palette.primary.main, height: '40%', width: '100%'}}></Box>

        </Box>
    }
    const HorizontalStripe = props => {
        const { isTop } = props
        return <Box sx={{
            width: '100%', 
            height: 10,
            position: 'absolute',
            display: 'flex',
            gap: 1, 
            justifyContent: 'space-between', 
            alignItems: 'center',
            left: 0,
            top: isTop ? 0 : null,
            bottom: isTop ? null : 0
        }}>
            <Box sx={{background: t => t.palette.primary.main, height: '100%', width: '40%'}}></Box>
            <Box sx={{height: 20, width: 20, transform: 'rotate(45deg)', background: t => t.palette.primary.main}}></Box>
            <Box sx={{background: t => t.palette.primary.main, height: '100%', width: '40%'}}></Box>
        </Box>
    }




    return <Box 
        sx={sx}
        {...other}
    >
        { bleft && <VerticalStripe isLeft={true}/> }
        { bright && <VerticalStripe isLeft={false}/> }
        { btop && <HorizontalStripe isTop={true}/> }
        { bbottom && <HorizontalStripe isTop={false}/> }
        
        {children}
    </Box>
}


export const MenuContainer = props => {
    return <Grid container spacing={3} sx={{
        width: '100%', 
        background: t => t.palette.background.menu,
        p: 3,
        borderRadius: 4,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        height: '100%'
    }}>
        {props.children}
    </Grid>
}




export const MenuSection = props => {
    const {
        children, 
        gridSX: _gridSX={},
        sx: _sx={},
        size: _size={},
        borders=[0,0,0,0], 
        ...other
    } = props
    
    const gridSX = Object.assign({
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'space-between'
    }, _gridSX)
    
    const sx = Object.assign({
        py: 2,
        height: '100%'
    }, _sx)

    const size = Object.assign({
        xs: 12, md: 4
    }, _size)
    return <Grid item size={size} sx={gridSX} {...other}>
        <BBox borders={borders} sx={sx}>
            {children}

        </BBox>
    </Grid>
}

export const MenuCategorySection = props => {
    const { title, items } = props

    return <>
        <HeaderText>{title}</HeaderText>
        <FBox sx={{flexDirection: 'column', gap: 1}}>
            {
                items.map(item => (
                    <FBox key={item.name} sx={{flexDirection: 'column'}}>
                        <SubheaderText>{item.name}</SubheaderText>
                        <Text>{item.description} / {item.price}</Text>
                    </FBox>

                ))
            }

        </FBox>
    </>
}