import { Components } from './../../../components/'
import CheckIcon from '@mui/icons-material/CheckCircle';
const {
    Box,
    FBox,
    Grid,
    BaseSelect, 
    CountrySelect,
    TextField: MuiTextField,
    Typography,
    alpha
} = Components

const Title = props => {
    return <Typography sx={{
        fontSize: '2rem',
        fontWeight: 'bold',
        color: t => t.palette.primary.main,
    }}>{props.children}</Typography>
}
const Subtext = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        color: t => alpha(t.palette.text.main, .8),
        mt: 1
    }, _sx)
    return <Typography sx={sx} {...children}>{children}</Typography>
}
const ItemText = props => {
    return <Typography sx={{
        color: t => alpha(t.palette.text.main, .8),

    }}>{props.children}</Typography>
}
const ItemNoteText = props => {
    return <Typography component="span" sx={{
        color: t => alpha(t.palette.text.main, .8),
        fontSize: '.8rem',
        fontStyle: 'italic',
        opacity: .5
    }}>{props.children}</Typography>
}

const Advantages = () => {

    const ItemRow = props => {
        return <FBox sx={{gap: 2, mt: 1}}>
            <CheckIcon sx={{color: t => t.palette.primary.main}}></CheckIcon>
            {props.children}
        </FBox>
    }

    return <FBox sx={{flexDirection: 'column'}}>
        <ItemRow>
            <ItemText>No additional percentage based fees for payment processing. You keep your margins. <ItemNoteText>* Fees may be collected by the actual payment processor.... stripe, paypal, etc.</ItemNoteText>  </ItemText>
        </ItemRow>
        <ItemRow>
            <ItemText>Accept QR coupons or online specials that can be distributed through social media channels to increase brand awareness. </ItemText>
        </ItemRow>
        <ItemRow>
            <ItemText>Integrate your business with Uber Eats, Doordash, or other delivery services in your area.</ItemText>
        </ItemRow>
    </FBox>
}


export const MyCopy = props => {
    return <FBox sx={{
        flexDirection: 'column', 
        alignItems: 'start',
        justifyContent: 'start'
    }}>
        <Title>Are you ready to take online orders?</Title>
        <Subtext>
            Add this checkout form to your page and seamlessly integrate your online store to your current Point of Sales (POS) system.
        </Subtext>
        <Subtext sx={{fontWeight: 'bold'}}>Or</Subtext>
        <Subtext>If you don't need the overhead of a full POS system, you can still process orders online simply with no downside.</Subtext>
        <Advantages />

    </FBox> 
}