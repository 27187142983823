import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material'

export const GhostTyping = ({ text, speed, sx: _sx={} }) => {
  const [displayedText, setDisplayedText] = useState('');
  const sx = Object.assign({

  }, _sx)
  useEffect(() => {
    setDisplayedText(''); // Reset displayed text on new message
    let index = -1;
    let p = true;
    
    const interval = setInterval(() => {
      if (index < text.length) {
          setDisplayedText((prev) => {
            if(text[index] === undefined) {
              return prev
            } else {
              return prev + text[index]
            }
          })
        
          index++;
      } else {
        clearInterval(interval); // Stop the interval once text is fully displayed
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed])

  return <Box sx={sx}>{displayedText}</Box>
};