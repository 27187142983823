
import React, { useState } from 'react';
import { MenuItem, FormControl, Typography, Box } from '@mui/material';
import { InputLabel } from './../inputs'
import { Select } from './Select'
export const StateSelect = (props) => {
    const { value, onChange, label, name } = props

//   const [selectedState, setSelectedState] = useState('');
    const states = getStates()
    const handleChange = (event) => {
        const _value = event.target.value 
        onChange({target: {name, value: _value}});
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="State-select-label" shrink={!!value}>State</InputLabel>
      <Select
        labelId="State-select-label"
        id="State-select"
        value={value}
        onChange={evt => handleChange(evt)}
        label={label}
        size="small"
        name={value}
      >
        {states.map((si) => (
          <MenuItem key={si.id} value={si.id}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={si.img}
                alt={`${si.label} flag`}
                style={{ marginRight: 8 }}
              />
              <Typography>{si.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StateSelect;


 
export const getStates = () =>  ([
    {
       "label": "Alabama",
       "id": "alabama",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Alabama.svg/23px-Flag_of_Alabama.svg.png"
    },
    {
       "label": "Alaska",
       "id": "alaska",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Alaska.svg/21px-Flag_of_Alaska.svg.png"
    },
    {
       "label": "Arizona",
       "id": "arizona",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Arizona.svg/23px-Flag_of_Arizona.svg.png"
    },
    {
       "label": "Arkansas",
       "id": "arkansas",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Arkansas.svg/23px-Flag_of_Arkansas.svg.png"
    },
    {
       "label": "California",
       "id": "california",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_California.svg/23px-Flag_of_California.svg.png"
    },
    {
       "label": "Colorado",
       "id": "colorado",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Flag_of_Colorado.svg/23px-Flag_of_Colorado.svg.png"
    },
    {
       "label": "Connecticut",
       "id": "connecticut",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Flag_of_Connecticut.svg/19px-Flag_of_Connecticut.svg.png"
    },
    {
       "label": "Delaware",
       "id": "delaware",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Flag_of_Delaware.svg/23px-Flag_of_Delaware.svg.png"
    },
    {
       "label": "Florida",
       "id": "florida",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Florida.svg/23px-Flag_of_Florida.svg.png"
    },
    {
       "label": "Georgia (U.S. state)",
       "id": "georgia",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Flag_of_the_State_of_Georgia.svg/23px-Flag_of_the_State_of_Georgia.svg.png"
    },
    {
       "label": "Hawaii",
       "id": "hawaii",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Flag_of_Hawaii.svg/23px-Flag_of_Hawaii.svg.png"
    },
    {
       "label": "Idaho",
       "id": "idaho",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_Idaho.svg/19px-Flag_of_Idaho.svg.png"
    },
    {
       "label": "Illinois",
       "id": "illinois",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Illinois.svg/23px-Flag_of_Illinois.svg.png"
    },
    {
       "label": "Indiana",
       "id": "indiana",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Flag_of_Indiana.svg/23px-Flag_of_Indiana.svg.png"
    },
    {
       "label": "Iowa",
       "id": "iowa",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Flag_of_Iowa.svg/23px-Flag_of_Iowa.svg.png"
    },
    {
       "label": "Kansas",
       "id": "kansas",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_Kansas.svg/23px-Flag_of_Kansas.svg.png"
    },
    {
       "label": "Kentucky",
       "id": "kentucky",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Flag_of_Kentucky.svg/23px-Flag_of_Kentucky.svg.png"
    },
    {
       "label": "Louisiana",
       "id": "louisiana",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Flag_of_Louisiana.svg/23px-Flag_of_Louisiana.svg.png"
    },
    {
       "label": "Maine",
       "id": "maine",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Maine.svg/19px-Flag_of_Maine.svg.png"
    },
    {
       "label": "Maryland",
       "id": "maryland",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_Maryland.svg/23px-Flag_of_Maryland.svg.png"
    },
    {
       "label": "Massachusetts",
       "id": "massachusetts",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Massachusetts.svg/23px-Flag_of_Massachusetts.svg.png"
    },
    {
       "label": "Michigan",
       "id": "michigan",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Flag_of_Michigan.svg/23px-Flag_of_Michigan.svg.png"
    },
    {
       "label": "Minnesota",
       "id": "minnesota",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Minnesota.svg/23px-Flag_of_Minnesota.svg.png"
    },
    {
       "label": "Mississippi",
       "id": "mississippi",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_Mississippi.svg/23px-Flag_of_Mississippi.svg.png"
    },
    {
       "label": "Missouri",
       "id": "missouri",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Flag_of_Missouri.svg/23px-Flag_of_Missouri.svg.png"
    },
    {
       "label": "Montana",
       "id": "montana",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_Montana.svg/23px-Flag_of_Montana.svg.png"
    },
    {
       "label": "Nebraska",
       "id": "nebraska",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/Flag_of_Nebraska.svg/23px-Flag_of_Nebraska.svg.png"
    },
    {
       "label": "Nevada",
       "id": "nevada",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Flag_of_Nevada.svg/23px-Flag_of_Nevada.svg.png"
    },
    {
       "label": "New Hampshire",
       "id": "new_hampshire",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Flag_of_New_Hampshire.svg/23px-Flag_of_New_Hampshire.svg.png"
    },
    {
       "label": "New Jersey",
       "id": "new_jersey",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_New_Jersey.svg/23px-Flag_of_New_Jersey.svg.png"
    },
    {
       "label": "New Mexico",
       "id": "new_mexico",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_New_Mexico.svg/23px-Flag_of_New_Mexico.svg.png"
    },
    {
       "label": "New York (state)",
       "id": "new_york",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_New_York.svg/23px-Flag_of_New_York.svg.png"
    },
    {
       "label": "North Carolina",
       "id": "north_carolina",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Flag_of_North_Carolina.svg/23px-Flag_of_North_Carolina.svg.png"
    },
    {
       "label": "North Dakota",
       "id": "north_dakota",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Flag_of_North_Dakota.svg/20px-Flag_of_North_Dakota.svg.png"
    },
    {
       "label": "Ohio",
       "id": "ohio",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Ohio.svg/25px-Flag_of_Ohio.svg.png"
    },
    {
       "label": "Oklahoma",
       "id": "oklahoma",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Flag_of_Oklahoma.svg/23px-Flag_of_Oklahoma.svg.png"
    },
    {
       "label": "Oregon",
       "id": "oregon",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Oregon.svg/23px-Flag_of_Oregon.svg.png"
    },
    {
       "label": "Pennsylvania",
       "id": "pennsylvania",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Pennsylvania.svg/21px-Flag_of_Pennsylvania.svg.png"
    },
    {
       "label": "Rhode Island",
       "id": "rhode_island",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Rhode_Island.svg/19px-Flag_of_Rhode_Island.svg.png"
    },
    {
       "label": "South Carolina",
       "id": "south_carolina",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Flag_of_South_Carolina.svg/23px-Flag_of_South_Carolina.svg.png"
    },
    {
       "label": "South Dakota",
       "id": "south_dakota",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_South_Dakota.svg/23px-Flag_of_South_Dakota.svg.png"
    },
    {
       "label": "Tennessee",
       "id": "tennessee",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flag_of_Tennessee.svg/23px-Flag_of_Tennessee.svg.png"
    },
    {
       "label": "Texas",
       "id": "texas",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Flag_of_Texas.svg/23px-Flag_of_Texas.svg.png"
    },
    {
       "label": "Utah",
       "id": "utah",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Utah.svg/23px-Flag_of_Utah.svg.png"
    },
    {
       "label": "Vermont",
       "id": "vermont",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Vermont.svg/23px-Flag_of_Vermont.svg.png"
    },
    {
       "label": "Virginia",
       "id": "virginia",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Flag_of_Virginia.svg/23px-Flag_of_Virginia.svg.png"
    },
    {
       "label": "Washington (state)",
       "id": "washington",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Flag_of_Washington.svg/23px-Flag_of_Washington.svg.png"
    },
    {
       "label": "West Virginia",
       "id": "west_virginia",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Flag_of_West_Virginia.svg/23px-Flag_of_West_Virginia.svg.png"
    },
    {
       "label": "Wisconsin",
       "id": "wisconsin",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Flag_of_Wisconsin.svg/23px-Flag_of_Wisconsin.svg.png"
    },
    {
       "label": "Wyoming",
       "id": "wyoming",
       "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Wyoming.svg/22px-Flag_of_Wyoming.svg.png"
    }
 ])