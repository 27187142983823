import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material'
export const Spinners = props => {   
    const { n=3, sx={} } = props
    let _sx = {
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2
    }
    _sx = Object.assign(_sx, sx)
    return <Box sx={_sx}>
        {
            
            Array.from(Array(n)).map((_, i) => <CircularProgress size={30}></CircularProgress>)
        }
    </Box>
}