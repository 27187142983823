import { useNavigate } from 'react-router-dom'
import { BaseComponents } from './../base'
const {
    FBox, 
    Box,
    Typography,
    alpha
} = BaseComponents

export const Header = () => {
    const navigate = useNavigate()
    return <FBox sx={{
        background: t => t.palette.background.header,
        boxSizing: 'border-box',
        p: 2,
        justifyContent: 'space-between'
    }}>
        <Box sx={{cursor: 'pointer', color: t => t.palette.primary.main}} onClick={() => navigate('/')}>
            <Typography variant="logo" sx={{color: t => t.palette.text.opposite}}>Autoscript Services AC</Typography>
        </Box>
        <Box 
        
            onClick={() => navigate('/contact/')}
            sx={{
            fontWeight: 'bold', 
            cursor: 'pointer', 
            color: t => t.palette.text.sidebar, 
            '&:hover': {color: t => alpha(t.palette.text.sidebar, .6)}
        }}>
            Contact
        </Box>
        
    </FBox>
}