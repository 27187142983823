import { useState, useEffect } from 'react'
import { BaseComponents } from './../../base/'
import { useBarChart } from './../../../tools'
import { useSelector, useDispatcher, TYPES } from './../../../store/dispatcher'
import { useChartBreakpoints } from './../helper'
import { ChartWrapper } from './../helper'

const {
    FBox,
    useTheme,
    alpha,
    Button
} = BaseComponents


const mock_data = ((n) => {
    
    const daily = [...new Array(30).keys()].map((di, i) => ({x: `${i+1}`.padStart(2, '0'), y: Math.random() * 300 + 700}))
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const years = ['2020', '2021', '2022', '2023', '2024']
    
    const monthly = months.map(mi => ({x: mi, y: Math.random() * 3000 + 7000}))
    const yearly = years.map(mi => ({x: mi, y: Math.random() * 30000 + 70000}))
    
    
    return {
        daily,
        monthly, 
        yearly
    }
})()




const BarChartInternals = props => {
    const { dataset } = props
    const title = dataset.charAt(0).toUpperCase() + dataset.slice(1).toLowerCase() + ' Visitors'
    const data = mock_data[dataset]
    const t = useTheme()
    const {
        width, 
        height,
        //title,
        margins={left: 45, right: 10, top: 30, bottom: 45},
        ...other
    } = props

    const { rerender } = useSelector(state => state.session)
    const svgSX = {
        '& .bar': {
            cursor: 'pointer',
            fill: t => t.palette.secondary.main,
                        transition: '2s ease'
        },
        '& .bar:hover': {
            fill: t => alpha(t.palette.secondary.main, .6),
            transition: '2s ease'
        },
        '& text': {
            color: t => t.palette.text.main,
            fill: t => t.palette.text.main 
        },
        '& .tick': {
            color: t => t.palette.text.main,
            stroke: t.palette.text.main,
            color: t.palette.text.main,
            fill: t.palette.text.main
        },
        '& .tick .line': {
            stroke: t.palette.text.main,
            color: t.palette.text.main,
            fill: t.palette.text.main
        },
        '& .axis .line': {
            stroke: t.palette.text.main,
            color: t.palette.text.main,
            fill: t.palette.text.main
        },
        '& .axis .path': {
            stroke: t.palette.text.main,
            color: t.palette.text.main,
            fill: t.palette.text.main
        },
        '& .axis .path.domain': {
            stroke: t.palette.text.main,
            color: t.palette.text.main,
            fill: t.palette.text.main
        },
    }

    // const onClick = evt => {
    //     const nextKey = dataKey === 'monthly' ? 'yearly' : dataKey === 'yearly' ? 'daily' : 'monthly'
    //     setData(mock_data[nextKey])
    //     setDataKey(nextKey)
        
    // }

    const { Chart: BarChartComp } = useBarChart({
        width, 
        height,
        data,
        margins,
        rerender,
        svgSX,
        backgroundColor: t.palette.background.dark,
        color: t.palette.text.main,
        // innerBackgroundColor: t.palette.dashboard.barchart.innerBackgroundColor,

        ...other,
        title
    })

    return <FBox sx={{flexDirection: 'column'}}>

        {BarChartComp}
    </FBox>
}

export const BarChart = props => {
    return <ChartWrapper render={({width})=>(<BarChartInternals width={width} {...props}></BarChartInternals>)} />
}
