import { BarChart } from './barchart'
import { LineChart } from './linechart'
import { PieChart } from './piechart'

export const Charts = {
    BarChart,
    LineChart,
    PieChart

}


export {
    BarChart,
    LineChart,
    PieChart
}