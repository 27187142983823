
export const nav_items = [
    {
        name: 'about',
        label: 'About'
    }, 
    {
        name: 'fun',
        label: 'Fun'
    },
    {
        name: 'modern',
        label: 'Modern'
    },
    {
        name: 'classic',
        label: 'Classic'
    },

]