import { useState, useEffect } from 'react'
import { BaseComponents } from './../../base/'
import { usePieChart } from './../../../tools'
import { useSelector, useDispatcher, TYPES } from './../../../store/dispatcher'
import { useChartBreakpoints } from './../helper'
import { ChartWrapper } from './../helper'

const {
    FBox,
    useTheme,
    alpha,
    Button
} = BaseComponents


const mock_data = ((n) => {
    // Sample component to display pie chart with some dynamic data
    const l = (label, value) => ({label,value})

    const f = () => {
        const bm = parseInt(Math.random() * 7 + 44)
        const os = parseInt(Math.random() * 20 + 10)
        const op = parseInt(Math.random() * 10 + 10)
        const sc = parseInt(Math.random() * 5 + 10)
        const as = 100 - bm - os - op - sc
        return [
            l('Brick and Mortar', bm),
            l('Online Store', os),
            l('Other Online Platforms', op),
            l('Sales Calls', sc),
            l('Affiliate Sales', as)
        ]
    }
    return  {
        daily: f(),
        monthly: f(),
        yearly: f()
    }
})()


const PieChartInternals = props => {
    // const [dataKey, setDataKey] = useState('monthly')
    // const [data, setData] = useState(mock_data.monthly)


    const t = useTheme()
    const {
        width,
        height,
        title,
        dataset,
        margins={left:10, right: 10, bottom: 200, top: 10},
        ...other
    } = props
    const rerender = useSelector(state => state.session)
    const data = mock_data[dataset]
    // const onClick = evt => {
    //     const nextKey = dataKey === 'monthly' ? 'yearly' : dataKey === 'yearly' ? 'daily' : 'monthly'
    //     setData(mock_data[nextKey])
    //     setDataKey(nextKey)
    // }
    const d = Math.min(width - margins.left - margins.right, height - margins.top - margins.bottom)
    const { Chart: PieChartComp, Labels: PieChartLabels } = usePieChart({
        width: d,
        height: d,
        data,
        rerender,
        title,
        margins: {left: 0, right: 0, bottom: 0, top: 0},
        ...other
    })
    return <FBox sx={{gap: 2, background: t => t.palette.background.card, justifyContent: 'start', p: 2}}>
        {PieChartComp}
        {PieChartLabels}
    </FBox>
}

export const PieChart = props => {

    Object.keys(mock_data).forEach(ki => {
        const data = mock_data[ki]
        const sum = data.reduce((a,b) => a + b.value, 0)
    })
    return <ChartWrapper render={({width}) => (<PieChartInternals width={width} {...props}> </PieChartInternals>)} />
}