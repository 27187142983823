import { useState } from 'react'
import { ThemeProvider } from '@mui/material';
import {
    Box,
    Typography,
    Chip
} from '@mui/material'
import { ButtonRow } from './ButtonRow'
import { CheckboxRow } from './CheckboxRow'
import { TextFieldRow } from './TextFieldRow'
import { SelectRow } from './SelectRow'
import { SliderRow } from './SliderRow'
import { PickerRow } from './PickerRow'
import { useTheme } from './themes'


const theme_options = [
    {name: 'light', label: 'Light'},
    {name: 'dark', label: 'Dark'}
]
export const DashboardComponentsPage = props => {
    const [mode, setMode] = useState('light')
    const theme = useTheme(mode)
    return <ThemeProvider theme={theme}>
        <Box sx={{width: '100%', background: t => t.palette.background.main, p: {lg: 5}}}>
            
            <Box sx={{width: '100%', mb: 5, display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    color: t => t.palette.text.main
                }}>Component Gallery</Typography>
                <Box sx={{'&, & *': {color: t => t.palette.text.main}}}>
                    Select Theme
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        {
                            theme_options.map(ti => {
                                const active = ti.name === mode
                                if(active) {
                                    return <Chip key={ti.name} onClick={() => setMode(ti.name)} label={ti.label} sx={{
                                        cursor: 'pointer',
                                        background: t => t.palette.primary.main, 
                                        color: t => t.palette.primary.text
                                    }}>{ti.label}</Chip>
                                } else {
                                    return <Chip key={ti.name} label={ti.label} onClick={() => setMode(ti.name)}></Chip>
                                }
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <ButtonRow />
            <CheckboxRow />
            <TextFieldRow />
            <SelectRow />
            <SliderRow />
            <PickerRow />
        </Box>
    
    
    </ThemeProvider>
}