import { useState } from 'react'
import { Charts, BaseComponents } from './../../../../components'
import { DemoItems } from './DemoItems'
import { TopProductsTable } from './DemoTable'
const {
    Grid,
    FBox,
    Typography,
    alpha,
    useBreakpoints,
    ButtonGroup,
    Button
} = BaseComponents

const {
    BarChart,
    PieChart
} = Charts

const Title = props => {
    return <Typography sx={{
        fontSize: '1.2rem', 
        fontWeight: 'bold', 
        fontFamily: t => t.typography.header,
        color: t => t.palette.text.main
    }}>{props.children}</Typography>
}

const SubTitle = props => {
    return <Typography sx={{
        fontSize: '1.0rem',  
        fontStyle: 'italic', 
        fontWeight: '200', 
        fontFamily: t => t.typography.body,
        color: t => alpha(t.palette.text.main, .7)
}}>{props.children}</Typography>
}
const F = () => <></>
export const DashboardChartsPage = props => {
    
    const [dataset, setDataset] = useState('monthly')

    
    return <Grid container spacing={5} sx={{display: 'flex', alignItems: 'start', justifyContent: 'alignContent', p: 5, flexDirection: 'column'}}>
        <Grid item xs={12} container spacing={5} sx={{width: '100%'}}>
            <Grid item size={{xs: 12}}>
                <FBox sx={{justifyContent: 'space-between'}}>
                    <FBox sx={{flexDirection: 'column', alignItems: 'start'}}>
                        <Title>Business Integration Dashboard</Title>
                        <SubTitle>
                            Today is {(new Date().toLocaleDateString('en-US', {
                                weekday: 'long',  // "Monday"
                                year: 'numeric',  // "2024"
                                month: 'long',    // "October"
                                day: 'numeric'    // "17"
                            }))}
                        </SubTitle>
                    </FBox>
                    <FBox>
                        <ButtonGroup variant="outlined" aria-label="Demo dataset selection">
                            <Button variant={dataset === 'daily' ? 'contained' : 'outlined'} onClick={() => setDataset('daily')}>Daily</Button>
                            <Button variant={dataset === 'monthly' ? 'contained' : 'outlined'} onClick={() => setDataset('monthly')}>Monthly</Button>
                            <Button variant={dataset === 'yearly' ? 'contained' : 'outlined'} onClick={() => setDataset('yearly')} >Yearly</Button>
                        </ButtonGroup>
                    </FBox>
                </FBox>
            </Grid>
            <Grid item size={{xs: 12}}><hr /></Grid>
            <Grid item container size={{xs: 12, lg: 6}} spacing={1}>
                <DemoItems dataset={dataset}/>
            </Grid>
            <Grid item size={{xs: 12, lg: 6}}>
                <PieChart height={450} title={'Revenue Sources'} dataset={dataset}/>
            </Grid>

            <Grid item size={{xs: 12, lg: 5}}>
                <BarChart height={300} title={"Monthy Visitors"} dataset={dataset}/>
            </Grid>

            <Grid item size={{xs: 12, lg: 7}}>
                <TopProductsTable dataset={dataset}/>
            </Grid>

        </Grid>

    </Grid> 
}