import { fetcher } from './../fetcher'

export function contact({email, feedback}) {

    return fetcher('/api/contact/', {
        body: {
            email, 
            feedback
        }
    })
    .then(data => data.json())
    
}
