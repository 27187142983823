import { InputLabel as MuiInputLabel } from '@mui/material'

export const InputLabel = props => {
    const {children, sx: _sx={}, ...other} = props
    const sx = Object.assign({
        color: t => t.palette.text.main,
        '&.focused': {
            color: t => t.palette.primary.main
            

        }   
    }, _sx)
    return <MuiInputLabel shrink={true} sx={sx} {...other}>{children}</MuiInputLabel>
}