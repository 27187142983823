
/* ----------------------------------------------------------------------------
===============================================================================
Imports
===============================================================================
---------------------------------------------------------------------------- */
import {
    useTheme,
    Box,
    Typography
} from '@mui/material'
import {
    alpha
} from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import { FBox } from './containers'
import { Icons } from './mui/icons'
import { useWhenBreakpointIs } from './hooks'
import * as AllMui from '@mui/material'
import { DatePicker, ColorPicker } from './mui/pickers'
import { Spinners } from './spinners'
import { useSlideDeck } from './slidedeck'
import { Text } from './text'
import { animations } from './animations'
/* ----------------------------------------------------------------------------
===============================================================================
Export MUI as object
===============================================================================
---------------------------------------------------------------------------- */
const Mui = {
    ...AllMui,
    useTheme,
    alpha,
    useTheme,
    ...Text,
    Box,
    Grid,
    Icons,
    DatePicker,
    ColorPicker,
}

/* ----------------------------------------------------------------------------
===============================================================================
export individually
===============================================================================
---------------------------------------------------------------------------- */
export const BaseComponents = {
    ...Mui,
    FBox,
    Spinners,
    useWhenBreakpointIs,
    useSlideDeck,
    ...animations
}