
import { 
    BasePage,
    HomePage,
    DashboardBasePage, 
    DashboardComponentsPage,
    DashboardLandingPages,
    DashboardChartsPage,
    ContactPage,
    FoodPage,
    WebdemosBasePage,
    WebdemosPage,
} from './../pages'

export const routes = [
    {
        path: '/',
        exact: true,
        element: <BasePage key="base" />,
        children: [
            {
                path: '/',
                exact: true,
                element: <HomePage key="homepage"/>,

            },
            {
                path: '/contact/', 
                exact: true,
                element: <ContactPage key="contact" />,
            },
            {
                path: '/food/',
                exact: true,
                element: <FoodPage key="food" />
            }
        ]
        
    }, 
    {
        path: '/dashboard/', 
        exact: true,
        element: <DashboardBasePage />,
        children: [
            {
                path: '/dashboard/', 
                exact: true,
                element: <DashboardChartsPage />
            },

            {
                path: '/dashboard/components/', 
                exact: true,
                element: <DashboardComponentsPage />
            },
            {
                path: '/dashboard/landingpages/',
                exact: true,
                element: <DashboardLandingPages />
            }
        ]
    },
    {
        path: '/webdemos/',
        exact: true,
        element: <WebdemosBasePage key="webdemo"/>,
        children: [
            {
                path: '/webdemos/',
                exact: true,
                element: <WebdemosPage key="webdemo" />
            }
        ]
        
    }

]


export default routes;