import { useState } from 'react'
import * as React from 'react';
import {
    Box,
    TextField as MuiTextField,
    alpha
} from '@mui/material'

import { RowTitle, ComponentRow, Sep } from './helper'






const TextField = props => {
    const { children, sx: _sx={}, ...other} = props
    const sx = Object.assign({

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: t => t.palette.text.main, // Default border color
            },
            '&:hover fieldset': {
            borderColor: t => alpha(t.palette.text.main, .6), // Change border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: t => t.palette.primary.main, // Change border color when focused
            },
            '&.Mui-disabled fieldset': {
                borderColor: t => alpha(t.palette.text.main, .4), // Border color when disabled
              },
        },
        '& .MuiInputBase-input': {
            color: t => t.palette.text.main, // Change text color
        },
        '& .MuiInputLabel-root': {
            color: t => t.palette.text.main, // Change label color
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: t => t.palette.primary.main, // Change label color when focused
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: t => `${alpha(t.palette.text.main, .4)} !important`, // Text color when disabled
        },
        '& .MuiInputLabel-root.Mui-disabled': {
          color:  t => alpha(t.palette.text.main, .4), // Label color when disabled
        },
        '&.Mui-disabled': {
            color: 'rgba(255,0,0,1) !important'
        }

    }, _sx)
    return <MuiTextField sx={sx} {...other}>{children}</MuiTextField>
}




export default function FormPropsTextFields() {
  return (
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
        />
        <TextField
          disabled
          id="outlined-disabled"
          label="Disabled"
          defaultValue="Hello World"
        />
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
        />
        <TextField
          id="outlined-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
        />
        <TextField
          id="outlined-number"
          label="Number"
          type="number"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField id="outlined-search" label="Search field" type="search" />
        <TextField
          id="outlined-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
        />
      </div>
      <div>
        <TextField
          required
          id="filled-required"
          label="Required"
          defaultValue="Hello World"
          variant="filled"
        />
        <TextField
          disabled
          id="filled-disabled"
          label="Disabled"
          defaultValue="Hello World"
          variant="filled"
        />
        <TextField
          id="filled-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="filled"
        />
        <TextField
          id="filled-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          variant="filled"
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
        />
        <TextField
          id="filled-number"
          label="Number"
          type="number"
          variant="filled"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          id="filled-search"
          label="Search field"
          type="search"
          variant="filled"
        />
        <TextField
          id="filled-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
          variant="filled"
        />
      </div>
      <div>
        <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
        <TextField
          disabled
          id="standard-disabled"
          label="Disabled"
          defaultValue="Hello World"
          variant="standard"
        />
        <TextField
          id="standard-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="standard"
        />
        <TextField
          id="standard-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          variant="standard"
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
        />
        <TextField
          id="standard-number"
          label="Number"
          type="number"
          variant="standard"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          id="standard-search"
          label="Search field"
          type="search"
          variant="standard"
        />
        <TextField
          id="standard-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
          variant="standard"
        />
      </div>
    </Box>
  );
}

export const TextFieldRow = props => {
    
    return <>

        <ComponentRow withAccent={true}>
            <RowTitle>Input Fields</RowTitle>
            <Sep />
            <FormPropsTextFields />
        </ComponentRow>

    </>
}