
import { BaseComponents } from './../../../components'
import { Hero } from './hero'
import { Specialties } from './specialties'
import { PizzaBuilder } from './pizzabuilder'
import { Pastas } from './pasta'

const {
    FBox,
    Box
} = BaseComponents

export const Lorenzos = props => {

    return <FBox sx={{flexDirection: 'column', mb: 5}}>
        <Hero />
        <FBox sx={{
            flexDirection: 'column', 
            alignItems: 'start', 
            width: '100%', 
            maxWidth: 600,
            mt: 5
        }}>
            <Specialties />
            <Pastas />
            <PizzaBuilder />

        </FBox>
    </FBox>
}