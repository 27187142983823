import { BaseComponents } from './../../../components'

const {
    Box,
    FBox,
    Typography
} = BaseComponents

export const RowHeader = props => {
    const { title } = props 
    return <FBox sx={{
        justifyContent: 'start',
        fontSize: 28
    }}>
        {title}
    </FBox>

}