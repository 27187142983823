import { useNavigate } from 'react-router-dom'
import { Components } from './../../../components'


const {
    FBox,
    Box,
    Typography,
    SplitContent,
    Header1,
    Subtext,
    Body1,
    Icons,
    Button,
    Assets,
    alpha
} = Components



const Filler = ({bg='black', height=100}) => {
    return <Box sx={{background: bg, height: height, width: 100}}></Box>
}

const FeatureList = (props) => {
    return <FBox sx={{flexDirection: 'column', alignItems: 'start'}}>
        {props.children}
    </FBox>
}

const Image = props => {
    const { height=400 } = props
    return <Box sx={{height: {xs: 300, sm: 300, md: 500, lg: 500}, position: 'relative'}}>
        <Box component="img" src={Assets.images.banners.hero} sx={{height: '100%', width: 'auto', objectFit: 'contains'}}></Box>
    </Box>
}

const ListItem = props => {
    const { title, text } = props
    return <FBox sx={{gap: 2, justifyContent: 'start', alignItems: 'start'}}>
        <Icons.CheckIcon sx={{color: t => t.palette.primary.main}}/>
        <FBox sx={{flexDirection: 'column',  alignItems: 'start'}}>
            <Body1 sx={{
                fontWeight: 'bold', 
                whiteSpace: 'nowrap', 
                fontSize: '1.3rem'
            }}>{title}</Body1>
            <Subtext >{text}</Subtext>

        </FBox>

    </FBox>
}

const renderLeft = props => {
    return <FBox sx={{
        width: '100%', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        height: '100%', 
        alignItems: {xs: 'center', md: 'end'}
    }}>
            <Image />
    </FBox>
}
const renderRight = props => {
    const { onClick } = props

    return <FBox sx={{
        width: '100%', 
        flexDirection: 'column', 
        height: '100%', 
        alignItems: {xs: 'center', md: 'start'}
    }}>
        <FBox sx={{
            maxWidth: 650, 
            flexDirection: 'column', 
            alignItems: {xs: 'center', md: 'start'},
            justifyContent: 'space-between'
        }}>
            <Header1 sx={{
                textAlign: {xs: 'center', md: 'start'},
                fontSize: {xs: '1.3rem', sm: '1.7rem', md: '2rem'}
            }}>Premium websites for the food and beverage industry.</Header1>
            <Subtext sx={{
                textAlign: {xs: 'center', md: 'start'},
                fontSize: {xs: '.85rem', sm: '1rem'},
                opacity: .4,
                fontWeight: 'regular'
            }}>Showcase your restaurant’s unique flavor with a premium website designed to captivate.</Subtext>
            <FBox sx={{
                flexDirection: {xs: 'column', md: 'row'},
                width: '100%',
                mt: {xs: 3, md: 4, lg: 5}
            }}>
                <Button sx={{
                    width: '100%', 
                    p: {xs: 1, sm: 2}
                }} variant="outlined" onClick={evt => onClick('contact')}>Contact</Button>
                <FBox onClick={evt => onClick('gallery')} sx={{
                    border: '10px solid transparent', 
                    p: {xs: 1, sm: 2},
                    cursor: 'pointer',
                    borderImage: `url(${Assets.images.borders.brownBorder}) 30 stretch`,
                    width: '100%',

                    position: 'relative',
                    '&::before': {
                        content: '""',
                        width: 0,
                        height: '100%', 
                        position: 'absolute',
                        left: 0, 
                        top: 0, 
                        background: t => alpha(t.palette.secondary.main, .5),
                        transition: '.75s ease'
                    },
                    '&:hover': {
                        '&': {
                            // color: t => 'white'
                        },  
                        '&::before': {

                            width: '100%', 
                            transition: ',75s ease'
                        }
                    }

                }}>
                    Gallery
                </FBox>
            </FBox>
        
        </FBox>
        <Box sx={{height: 30}}></Box>
    </FBox>
}
export const Hero2 = props => {
    const navigate = useNavigate()
    const onClick = element => {
        if(element === 'contact') {
            navigate('/contact/')
        }
        if(element === 'gallery') {
            navigate('/webdemos/')
        }
    }
    return  <FBox sx={{width: '100%', background: t => t.palette.background.hero2, flexDirection: 'column', pt: 5}}>
        <SplitContent 
            renderLeft={() => renderLeft()}
            renderRight={() => renderRight({onClick})}
        />
    </FBox>
}
