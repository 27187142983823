

import { Components } from './../../../../components'
import { items } from './items'
import { Assets } from './../assets'
import {
    MenuContainer,
    MenuSection,
    HeaderText,
    SubheaderText,
    Text,
    AccentText,
    TinyFont,
    BBox,
    Logo
} from './components'

import { WineSlideDeck } from './SlideDeck'
const {
    Box,
    FBox,
    Grid,
    Typography
} = Components


const Ornament = props => {
    return <FBox sx={{justifyContent: 'space-between', mt: 2}}>
        <Box sx={{width: '45%', height: 5, background: t => t.palette.primary.main}}></Box>
        <Box sx={{width: 15, height: 15, transform: 'rotate(45deg)', background: t => t.palette.primary.main}}></Box>
        <Box sx={{width: '45%', height: 5, background: t => t.palette.primary.main}}></Box>
    </FBox>
}
const OrnamentText = props => {
    return <FBox sx={{position: 'relative'}}>
        <HeaderText sx={{fontSize: '1rem'}}>premium steaks from our grill.</HeaderText>
    </FBox>
}
const Divider = props => {
    return <FBox sx={{width: '100%'}}>
        <Box sx={{width: '40%', height: 2, background: t => t.palette.primary.main}}></Box>
    </FBox>
}

const SteakItem = ({title, price}) => {
    return <FBox><SubheaderText>{title} - {price} -</SubheaderText></FBox>
}

export const MenuHeader = props => {
    return <>
        <Logo />
        <Ornament />
        <OrnamentText />
        <Text>All Natural Black Angus Steaks
Specially Aged by Fred Linz in Chicago / Hand Cut in House
Served with Chipotle Demi, Chimichurri, and Shallot Butter</Text>
    <FBox sx={{mt: 2, flexDirection: 'column', gap: 1}}>
    {
        items['premium steaks from our grill'].map(item => {
            return <SteakItem key={item.name} title={item.name} price={item.price} />
        })
    }
    </FBox>


    <FBox sx={{mt: 2, flexDirection: 'column', gap: 1}}>
    {
        items['premium steaks from our grill'].map(item => {
            return <SteakItem key={item.name} title={item.name} price={item.price} />
        })
    }
    </FBox>
    <Box sx={{mt: 2}}></Box>
    <Divider />
    <FBox>
        <Typography sx={{fontSize: '.9rem', fontWeight: 'bold', color: t => t.palette.primary.main}}>For two or more</Typography>
    </FBox>
    <Divider />
    <FBox sx={{mt: 2, flexDirection: 'column', gap: 1}}>
    {
        items['other steaks'].map(item => {
            return <SteakItem key={item.name} title={item.name} price={item.price} />
        })
    }
    </FBox>
    <Box sx={{mt: 2}}></Box>
    {/* <HeaderText>steak accompaniments</HeaderText>
    <FBox sx={{mt: 2, flexDirection: 'column', gap: 1}}>
    {
        items['steak accompaniments'].map(item => {
            return <SteakItem key={item.name} title={item.name} price={item.price} />
        })
    }
    </FBox> */}
    <HeaderText>Wine</HeaderText>
    <FBox sx={{mt: 3}}>
        <WineSlideDeck height={300}/>
    </FBox>
    </>
}