import { Assets } from './assets'





const crust = [
    {
        name: 'small', 
        label: 'Small (12 inch)',
        Icon: Assets.crust.small,
        default: true

    },
    {
        name: 'medium', 
        label: 'Medium (18 inch)',
        Icon: Assets.crust.medium
    },
    {
        name: 'large',
        label: 'Large (24 inch)',
        Icon: Assets.crust.large
    }
]
const meat = [
    {
        name: 'pepperoni', 
        label: 'Pepperoni',
        Icon: Assets.meat.pepperoni,
        default: true
    },
    {
        name: 'sausage', 
        label: 'Sausage',
        Icon: Assets.meat.sausage
    },
    {
        name: 'bacon',
        label: 'Canadian Bacon',
        Icon: Assets.meat.bacon
    },
    {
        name: 'cheese',
        label: 'Extra Cheese',
        Icon: Assets.meat.cheese
    }
]

const toppings = [
    {
        name: 'olive',
        label: 'Olives',
        Icon: Assets.toppings.olive
    },
    {
        name: 'mushroom',
        label: 'Mushrooms',
        Icon: Assets.toppings.mushroom
    },
    {
        name: 'onion',
        label: 'Onion',
        Icon: Assets.toppings.onion
    },
    {
        name: 'pepper',
        label: 'Green Pepper',
        Icon: Assets.toppings.pepper
    },

    {
        name: 'pineapple',
        label: 'Pineapple',
        Icon: Assets.toppings.pineapple
    },
    {
        name: 'tomato',
        label: 'Tomatoes',
        Icon: Assets.toppings.tomato
    },
    {
        name: 'jalapeno',
        label: 'Jalapeno',
        Icon: Assets.toppings.jalapeno
    },
    // {
    //     name: 'redpepper',
    //     label: 'Red Pepper',
    //     Icon: Assets.toppings.redpepper
    // },
    {
        name: 'garlic',
        label: 'Garlic',
        Icon: Assets.toppings.garlic
    },
    {
        name: 'none', 
        label: 'None',
        Icon: Assets.toppings.none
    }

]

export const pizza = {
    crust,
    meat,
    toppings
}

export const initialState = (() => {


    return {
        crust: 'small', 
        meat: 'pepperoni',
        toppings: []
    }

})()
