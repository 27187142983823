import { BaseComponents } from './../../../../components'
import { Assets } from './assets'

const {
    FBox,
    Grid,
    Typography,
    Chip,
    Icons
} = BaseComponents


const products = {
    daily: [
        {
            name: 'makeup',
            label: 'linique Even Better Makeup Medium Coverage Foundation Broad Spectrum', 
            volume: '$568.00',
            percent_change: '%3.00',
            src: Assets.MakeupImage,
            change: 1
        },
        // {
        //     name: 'ring',
        //     label: 'Birthstone Necklace Love Gifts for Women, 925 Sterling Silver Love Knot Birthstone Jewelry', 
        //     volume: '$994.20',
        //     percent_change: '%4.50',
        //     src: Assets.RingImage,
        //     change: 1
        // },
        {
            name: 'tool',
            label: 'Apollo PEX 69PTBJ0010C 3/8-inch - 1-inch One Hand Cinch Clamp Tool',
            volume: '$978.56',
            percent_change: '%2.7',
            change: -1,
            src: Assets.ToolImage
        },
        {
            name: 'frog',
            label: 'LeapFrog Learning Friends 100 Words Book', 
            volume: '$790.45',
            percent_change: '%4.00',
            src: Assets.FrogImage,
            change: -1
        },
        {
            name: 'curling',
            label: 'Curling Iron Set with Clamp,5 Interchangeable Barrel Curling Wand', 
            volume: '$1894.00',
            percent_change: '%3.20',
            src: Assets.PrizmImage,
            change: -1
        },
    ],
    monthly: [
        {
            name: 'hair',
            label: 'GOO GOO Clip in Hair Extensions Real Human Hair', 
            volume: '$894.00',
            percent_change: '%6.00',
            src: Assets.HairImage,
            change: 1
        },
        {
            name: 'bass',
            label: 'Rotosound RS66LD Swing Bass Electric Bass 4 String Set',
            volume: '$1,236.34',
            percent_change: '%1.20',
            change: 1,
            src: Assets.BassImage
        },
        {
            name: 'clubs',
            label: 'Zeekio Pegasus Juggling Club Set',
            volume: '$1,056.23',
            percent_change: '%3.4',
            change: -1,
            src: Assets.ClubImage
        },
        {
            name: 'megaman',
            label: 'Mega Man X: 30th Anniversary Classic Cartridge',
            volume: '$8,054.23',
            percent_change: '%3.4',
            change: -1,
            src: Assets.MMImage
        }
    ],
    yearly: [
        {
            name: 'skincare',
            label: 'Shea Better Body Lotion- Vanilla Cashmere, 24-Hour Moisture Skin Care',
            volume: '$2,123.56',
            percent_change: '%2.4',
            change: -1,
            src: Assets.LotionImage
        },
        {
            name: 'marker',
            label: 'Sharpie Permanent Markers Set, Quick Drying And Fade Resistant Fine Tip Marker',
            volume: '$923.56',
            percent_change: '%3.7',
            change: 1,
            src: Assets.MarkerImage
        },
        {
            name: 'razor',
            label: 'Multi Groomer All-in-One Trimmer Series 3000-13 Piece Mens Grooming Kit',
            volume: '$978.56',
            percent_change: '%2.7',
            change: -1,
            src: Assets.RazorImage
        },

        {
            name: 'lock',
            label: 'WiFi Smart Door Lock with Camera, 5-in-1 Fingerprint Keyless Entry Door Lock',
            volume: '$1978.56',
            percent_change: '%4.2',
            change: 1,
            src: Assets.LockImage
        },
    ]
}

const Title = props => {
    return <Typography sx={{
        fontSize: '.85rem',
        fontWeight: 'bold'
    }}>{props.children}</Typography>
}
const Volume = props => {
    return <Typography sx={{

    }}>{props.children}</Typography>
}
const PercentChange = props => {
    const { text, direction } = props
    const DIcon = direction > 0 ? Icons.AddIcon : Icons.SubtractIcon
    // return <FBox><DIcon /></FBox>
    return <Chip 
        label={text}
        size="small"
        icon={<DIcon sx={{color: 'white', fill: 'white'}}/>}
        sx={{
            fontWeight: 'bold',
            color: 'white',
            fontFamily: t => t.typography.body,
            background: t => direction > 0 ? t.palette.success.main : t.palette.error.main
    }} />
}

const Card = props => {
    const {
        name, 
        label,
        volume,
        percent_change,
        change,
        src
    } = props
    return <Grid item size={{xs: 12, md: 6}} sx={{
        borderRadius: 4,
        width: '100%',
        p: 2,
        position: 'relative',
        boxShadow: t => t.shadows[1],
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%', 
            height: '100%', 
            borderRadius: 4,
            top: 0,
            left: 0,
            zIndex: 10,
            pointerEvents: 'none',
            background: 'rgba(0,0,0,.00)'
        }
    }}>
        <FBox sx={{gap: 1, justifyContent: 'start', alignItems: 'start', width: '100%', flexDirection: 'column'}}>
            <FBox sx={{gap: 1}}>
                <img src={src} alt={label}/>
                <FBox sx={{flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between', width: '100%'}}>
                    
                    <Title>{label}</Title>

                </FBox>

            </FBox>
            <FBox sx={{justifyContent: 'space-between', width: '100%', mt: 2}}>
                <Volume>{volume}</Volume>
                <PercentChange text={percent_change} direction={change}></PercentChange>

            </FBox>
        </FBox>
    </Grid>
}
export const DemoItems = props => {
    const { dataset } = props 
    const _products = products[dataset]
    return <FBox sx={{flexDirection: 'column', width: '100%'}}>
        <Typography sx={{
            fontWeight: 'bold',
            fontFamily: t => t.typography.header,
            color: t => t.palette.text.main
        }}>
            Top Selling Products
        </Typography>
        <Grid container sx={{ width: '100%', flex: 1, p: 2}} spacing={2}>
            {
                _products.map(pi => <Card key={pi.name} {...pi} />)
            }
        </Grid>
    </FBox>
}