import { ThemeProvider } from '@mui/material';
import { useState } from 'react'
import { BaseComponents } from './../../components'
import { ScrollContent } from './base'
import { Assets } from './assets'

import { useTheme } from './theme'
// import { SpecialtiesSection } from './specialties'
// import { PizzaBuilder } from './pizzabuilder'
import { demo_pages } from './demopages'
import { SubHeader } from './subheader'


const {
    Box,
    FBox,
    Typography,
    alpha
} = BaseComponents


const theme_options = [
    {name: 'light', label: 'Light'},
    {name: 'lorenzos', label: 'Lorenzos'},
    {name: 'bistro', label: 'Tommys Bistro'},
    {name: 'emptyplate', label: 'The Empty Plate'}
]
const page_options = [
    {
        name: 'bistro', 
        label: "Tommy's Asian Cuisine"
    },
    {
        name: 'emptyplate', 
        label: "The Empty Plate"
    },
    {
        name: 'lorenzos', 
        label: "Lorenzo's Pizzeria"
    },
    {
        name: 'checkout', 
        label: "Checkout"
    },
    {
        name: 'blog', 
        label: "Menu Psychology"
    },
    {
        name: 'admin', 
        label: "Admin/ Landing Pages"
    }
]


export const WebdemosPage = props => {
    const [mode, setMode] = useState('bistro')
    const [activePage, setActivePage] = useState('bistro')
    const setActivePageAnd = page => {
        setMode(page)
        setActivePage(page)
    }
    const theme = useTheme(mode)

    const MainComponent = demo_pages[activePage]
    return <ThemeProvider theme={theme}>
        <ScrollContent 
            SubHeader={<SubHeader options={page_options} activePage={activePage} setActivePage={setActivePageAnd}/>}
        >
        
        <MainComponent />


    </ScrollContent> 

    </ThemeProvider>
}