
import   ChefLogoBlack   from './logo/chefLogoBlack.png'
import   ChefLogoWhite   from './logo/chefLogoWhite.png'
import greenBorder from './green_border.png'
import redBorder from './red_border.png'



export const Assets = {
    logos: {
        ChefLogoBlack,
        ChefLogoWhite
    },
    borderImages: {
        greenBorder,
        redBorder
    },
    images: {

    }

}