import { Lorenzos } from './lorenzos'
import { CheckoutPage } from './checkout'
import { Tommys } from './bistro'
import { EmptyPlate } from './emptyplate'
import { BlogPage } from './blog'
import { AdminPage } from './admin'
const BlankPage = () => {
    return <div>"Blank"</div>
}
export const demo_pages = {
    lorenzos: Lorenzos,
    bistro: Tommys,
    landingpages: BlankPage,
    checkout: CheckoutPage,
    blog: BlogPage,
    pricing: BlankPage,
    admin: AdminPage,
    emptyplate: EmptyPlate

}